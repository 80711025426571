export const actionTypes = {
	GET_QUESTION_LIST: 'GET_QUESTION_LIST',
	GET_QUESTION_LIST__SUCCESS: 'GET_QUESTION_LIST_SUCCESS',
	GET_QUESTION_LIST_FAIL: 'GET_QUESTION_LIST_FAIL',
	RESET_QUESTION_LIST: 'RESET_QUESTION_LIST'
}

export const getQuestionList = (testId, type) => ({
	type: actionTypes.GET_QUESTION_LIST,
	payload: { testId, type }
});

export const getQuestionListSuccess = (questions) => ({
	type: actionTypes.GET_QUESTION_LIST__SUCCESS,
	payload: questions
});

export const resetQuestionList = () => ({
	type: actionTypes.RESET_QUESTION_LIST
});