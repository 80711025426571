import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import Notifications from 'react-notification-system-redux';
import MainRoutes from '../routes/MainRoutes';
import { logoutUser} from "../store/actions/auth.actions";

class Layout extends Component {    	

    logout = () => this.props.logoutUser();

	render() {         
    
        const APP_IDLE_TIME = 60000 * 30;

        return (
            <Fragment>
                <IdleTimer
                    ref={ref => {
                        this.idleTimer = ref
                    }}
                    onIdle={this.logout}
                    timeout={APP_IDLE_TIME}
                >
                    <div className="App">
                        <header className="App-header">
                            <Notifications
                                notifications={this.props.notifications}
                            />        
                            <MainRoutes />
                        </header>
                    </div>     
                </IdleTimer>             
            </Fragment>
        );

    }
}

const mapStateToProps = ({ notifications }) => {	
	return { notifications };
};

export default connect(
    mapStateToProps,
    { logoutUser }
)(Layout);