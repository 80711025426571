export const actionTypes = {
	GET_TEST_LIST: 'GET_TEST_LIST',
	GET_TEST_LIST__SUCCESS: 'GET_TEST_LIST_SUCCESS',
	GET_TEST_LIST_FAIL: 'GET_TEST_LIST_FAIL',
	SET_TEST_STATUS: 'SET_TEST_STATUS',
	SET_TEST_STATUS_SUCCESS: 'SET_TEST_STATUS_SUCCESS',
	SET_TIMESTAMP: 'SET_TIMESTAMP'
}

export const getTestList = userId => ({
	type: actionTypes.GET_TEST_LIST,
	payload: { userId }
});

export const getTestListSuccess = (tests) => ({
	type: actionTypes.GET_TEST_LIST__SUCCESS,
	payload: tests
});

export const setTestStatus = (testId, status, idunico, response) => ({
	type: actionTypes.SET_TEST_STATUS,
	payload: {testId, status, idunico, response}
});

export const setTimeStamp = (testId, idunico, key) => ({
	type: actionTypes.SET_TIMESTAMP,
	payload: {testId, idunico, key}
});
