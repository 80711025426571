import React, { Component } from 'react';
import { Row, Col, Card, CardBody, CardFooter, Button } from 'reactstrap';
import { isMobile } from "react-device-detect";
import AppHeader from '../../components/AppHeader/AppHeader';
import './general-instructions.css';

class GeneralInstructionsForm extends Component {

    onInstructionsButtonClicked = () => {
        const text = (this.props.type ===0) ? "Presiona [ACEPTAR] para iniciar la prueba" : "Recuerda que a partir de este momento debes terminar esta prueba, de lo contrario se considerará abandonada";
        if (this.props.actualTest != 0)
            alert(text);

        this.props.onInstructionsReaded();
    }

    render(){

        const { type, generalInstructionsReaded, actualTest } = this.props;

        return (
            <Row>
                <Col 
                    xs={{ size:12}} 
                    sm={{size:8}} 
                    md={{size:8}} 
                    lg={{size:8}} 
                >
                    <Card className={`${isMobile ? 'test-form-card-mobile' : 'test-form-card'}`}>
                        <AppHeader
                            onExit={this.onLogout} 
                        />
                        <CardBody 
                            className={`${isMobile ? 'test-list-card-body-mobile' : 'test-list-card-body'}`}
                        >
                            <div className="general-instructions-title">
                                Instrucciones:
                            </div>
                            <div>
                                <p>Este cuestionario consta de varias secciones de preguntas, cada una con sus propias instrucciones. Lee las instrucciones y comienza a responder cada grupo. Al terminar el tiempo disponible para cada serie, se presentaran las instrucciones de la siguiente serie y asi sucesivamente hasta terminar</p>
                                <br></br>
                                <p>Contestar este cuestionario requiere mucha concentración. Se recomienda en este momento cerrar cualquier programa que este activo y que pueda generar distracción por avisos instantáneos.</p>
                            </div>                                      
                            {/*{      */}
                            {/*    type === 1 &&*/}
                            {
                                !generalInstructionsReaded && actualTest != 0 &&
                                <div className="alert-section">
                                    <div className="alert-section-icon">
                                        <img
                                            src={require('../../assets/img/information.png')}
                                        />
                                    </div>
                                    <div className="alert-section-instructions">
                                        <div className="instructions-title">MUY IMPORTANTE</div>
                                        <ul>
                                            <li>
                                                Una vez que presiones el botón Comenzar, debes seguir hasta terminar por completo esta prueba, de otra forma se considerará como abandonada y no podrás volver a iniciarla.
                                            </li>
                                            <li>
                                                Durante la prueba no utilices los botones de comandos de tu navegador ni escribas nada en la barra de direcciones, cualquiera de estas acciones suspenderá la aplicación de la prueba.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            }
                            {/*}*/}
                        </CardBody>
                        <CardFooter>                            
                            <Button 
                                color="primary" 
                                className="float-right"
                                onClick={this.onInstructionsButtonClicked}
                            >
                                Comenzar
                            </Button>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        );
    }

}

export default GeneralInstructionsForm;
    