import React, { Component, Fragment } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { isMobile } from "react-device-detect";
import SortedQuestion from '../SortedQuestion/SortedQuestion';
import TwoChoiceQuestion from '../TwoChoiceQuestion/TwoChoiceQuestion';
import MultiChoiceQuestion from '../MultiChoiceQuestion/MultiChoiceQuestion';
import RavenQuestion from '../RavenQuestion/RavenQuestion';
import CleaverQuestion from '../CleaverQuestion/CleaverQuestion';
import InputQuestion from '../InputQuestion/InputQuestion';
import TwoInputQuestion from '../TwoInputQuestion/TwoInputQuestion';
import './question.css';

class Question extends Component {

    render() {

        const { question, answers, onAnswerSelect, questionType, id, isTask } = this.props;
        const questionTypes = { 
            1: CleaverQuestion,
            3: SortedQuestion,
            5: SortedQuestion,           
            6: MultiChoiceQuestion,
            8: RavenQuestion,
            10: CleaverQuestion,
            11: MultiChoiceQuestion,  
            12: MultiChoiceQuestion,
            13: MultiChoiceQuestion,
            16: MultiChoiceQuestion,
            17: SortedQuestion,
            20: TwoChoiceQuestion,
            21: InputQuestion,
            22: TwoInputQuestion    
        };
        const QuestionType = questionTypes[questionType]; 

		return (
            <Fragment>               
                <Card className='card-question'>
                    <CardHeader 
                        className={`card-question-header ${questionType == 8 ? 'center' : ''}`}                          
                    >                        
                        { questionType == 8 &&
                            <img 
                                src={require('../../assets/img/raven/'+ id +'.gif')}
                                className={`${isMobile ? 'question-image-mobile' : 'question-image'}`}
                            />
                        }
                        { this.props.title &&
                            <div className="question-title">
                                {this.props.title}
                            </div>
                        }
                        { questionType != 8 && 
                            <div 
                                dangerouslySetInnerHTML={{ __html: question }} 
                            />
                        }
                    </CardHeader>
                    <CardBody 
                        className={`card-question-body ${questionType == 3 ? 'no-padding' : ''}`}                        
                    >
                        <QuestionType 
                            answers={answers}
                            questionId={id}
                            isTask={isTask}
                            onAnswerSelect={onAnswerSelect}                        
                        />
                    </CardBody>
                </Card>                           
            </Fragment>
        );

    }

}

export default Question;