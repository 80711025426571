import {
	combineReducers
} from 'redux';
import { connectRouter } from 'connected-react-router';
import {reducer as notifications} from 'react-notification-system-redux';
import authUser from './auth.reducer';
import tests from './test.reducer';
import questions from './question.reducer';

const reducers = (history) => combineReducers({
	router: connectRouter(history),
	authUser,
	tests,
	questions,
	notifications,
});

export default reducers;