import React, { Component, Fragment } from 'react';
import { Row, Col, Card, CardBody, CardFooter, ListGroup, ListGroupItem } from 'reactstrap';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { BrowserView, isMobile } from "react-device-detect";
import { getTestList } from '../../store/actions/test.actions';
import { logoutUser} from "../../store/actions/auth.actions";
import AppHeader from '../../components/AppHeader/AppHeader';
import { testInstructions } from './test_list_constants';
import './test-list-form.css'

class TestListForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tests: this.props.list,
            actualTest: 0
        };
    }

    componentDidMount() {
        const {idunico} = this.props.loggedUser;
        this.props.getTestList(idunico);
        this.setupBeforeUnloadListener();
    }

    componentDidUpdate(prevProps, prevState){
		if(prevProps.list !== this.props.list){
			this.setState({ tests: this.props.list });
		}
	}

    onLogout = () => {
        this.props.logoutUser();
    }

    setupBeforeUnloadListener = () => {
        window.addEventListener("beforeunload", (ev) => {
            ev.preventDefault();
            return this.onLogout();
        });
    };

    render() {

        const testStatus = ['Por Practicar', 'Por Contestar', '', 'Abandonada', '','' ,'' ,'' ,'' ,'Contestada'];
        const styleStatus = ['for-practice', 'for-answer', '', 'exited', '','' ,'' ,'' ,'' ,'answered'];

        const testList = (
            <Fragment>
                { this.state.tests.map( (test, index) => {

                    const status = test['statusperm'];
                    const pathName = (parseInt(test['id_pruebas']) === 2 || parseInt(test['id_pruebas']) === 4) ? "/tarea" : "/test";

                    return <ListGroupItem
                        key={index}
                        id={index}
                        className={`${isMobile ? 'test-list-item-mobile' : 'test-list-item'}`}
                    >
                        <Row>
                            <Col xs={12} lg={7}>
                                <Row>
                                    <Col>{test['descrip_corta']}</Col>
                                    <Col>{`${test['minutos']} Minutos`}</Col>
                                    <Col
                                        className={`status-${styleStatus[parseInt(status)]}`}
                                    >
                                        {`${testStatus[parseInt(status)]}`}
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} lg={5}>
                                <Row>
                                    <Col>
                                        {
                                            status === '9' || status === '3' ?
                                            <p className='disabled-link' >Practicar</p> :
                                            <Link
                                                to={{
                                                    pathname: pathName,
                                                    testProps: {
                                                        type: 0,
                                                        id: test['id_pruebas'],
                                                        instructions: test['instrucciones'],
                                                        questionsPerPage: test['porpag'],
                                                        testType: parseInt(test['id_pruebas']),
                                                        actualTest: 0
                                                    }
                                                }}
                                                // onClick={ this.setTimeStamp(test['id_pruebas'], this.props.loggedUser, 'tsiniprac') }
                                            >
                                                <p>Practicar</p>
                                            </Link>
                                        }
                                    </Col>
                                    <Col>
                                        {
                                            status === '0' || status === '9' || status === '3' ?
                                            <p className='disabled-link' >Contestar Prueba</p> :
                                            <Link
                                                to={{
                                                    pathname: pathName,
                                                    testProps: {
                                                        type: 1,
                                                        id: test['id_pruebas'],
                                                        instructions: test['instrucciones'],
                                                        questionsPerPage: test['porpag'],
                                                        testType: parseInt(test['id_pruebas']),
                                                        actualTest: test['id_pruebas']
                                                    }
                                                }}
                                                // onClick={ this.setTimeStamp(test['id_pruebas'], this.props.loggedUser, 'tsinipru') }
                                            >
                                                <p>Contestar Prueba</p>
                                            </Link>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </ListGroupItem>
                })}
            </Fragment>
        );


		return (
            <Fragment>
                <Row>
                    <Col
                        xs={{ size:12}}
                        sm={{size:8, offset:2}}
                        md={{size:8, offset:2}}
                        lg={{size:8, offset:2}}
                    >
                        <Card className={`${isMobile ? 'test-card-mobile' : 'test-card'}`}>
                           <AppHeader onExit={this.props.logoutUser} />
                            <CardBody
                                className={`${isMobile ? 'test-list-card-body-mobile' : 'test-list-card-body'}`}
                            >
                                <BrowserView>
                                    {testInstructions}
                                </BrowserView>
                                <ListGroup>
                                    {testList}
                                </ListGroup>
                            </CardBody>
                            <CardFooter>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        );

    }

}

const mapStateToProps = ({ tests, authUser }) => {
    const { list } = tests;
    const { loggedUser } = authUser;
	return { list, loggedUser };
};

export default connect(
	mapStateToProps,
	{ getTestList, logoutUser }
)(TestListForm);
