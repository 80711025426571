
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { actionTypes, getQuestionListSuccess } from '../actions/question.actions';
//import {error as errorNotification } from 'react-notification-system-redux';
import { $http } from '../http/http';
import { raven_practice, raven } from '../../assets/js/raven';
import { kolv_practice, kolv } from '../../assets/js/kolv';
import { lifo_practice, lifo } from '../../assets/js/lifo';
import { zavic_practice, zavic } from '../../assets/js/zavic'
import { cleaver_practice, cleaver } from '../../assets/js/cleaver';
import { gordon_practice, gordon } from '../../assets/js/gordon'; 

const getQuestionListAsync = async (id, type) => {
    const result = await $http.get(`tests/${id}/questions?type=${type}`);    
    return result;    
}
 
function* getQuestionList({ payload }) {
    const { testId, type } = payload;
    let result = [];
    if(testId == 8){        
        result = type == 0 ? raven_practice : raven;
        yield put(getQuestionListSuccess(result));
    } else if(testId == 3){
        result = type == 0 ? kolv_practice: kolv;
        yield put(getQuestionListSuccess(result));    
    } else if(testId == 5){
        result = type == 0 ? lifo_practice: lifo;
        yield put(getQuestionListSuccess(result));
    } else if(testId == 1){        
        result = type == 0 ? cleaver_practice: cleaver;        
        yield put(getQuestionListSuccess(result));
    } else if(testId == 17){
        result = type == 0 ? zavic_practice: zavic;
        yield put(getQuestionListSuccess(result));
    } else if(testId == 10){
        result = type == 0 ? gordon_practice : gordon;        
        yield put(getQuestionListSuccess(result));
    } else {
        try {
            result = yield call(getQuestionListAsync, testId, type);
            if (result.data) {                   
                yield put(getQuestionListSuccess(result.data));
            } 
        } catch (error) {     
            //const title = messages["error-title.login"];
            //const message = messages[`error-${error.response.status}.login`] 
            //yield put(errorNotification({title, message}));        
        }
    }
}

export function* watchGetQuestionList() {
    yield takeEvery( actionTypes.GET_QUESTION_LIST, getQuestionList );
}


export default function* rootSaga() {
    yield all([
        fork(watchGetQuestionList),
    ]);
}