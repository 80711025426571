import React, { Component, Fragment } from 'react';
import { Row, Col, Card, CardBody, CardFooter, Button } from 'reactstrap';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import AppHeader from '../../components/AppHeader/AppHeader';
import { getTestList } from '../../store/actions/test.actions';
import { logoutUser} from "../../store/actions/auth.actions";
import './welcome.css'

class WelcomeForm extends Component {

    componentDidMount() {
        const {idunico} = this.props.loggedUser; 
        this.props.getTestList(idunico);
        this.setupBeforeUnloadListener();
    }

    onLogout = () => {
        this.props.logoutUser();
    }

    setupBeforeUnloadListener = () => {
        window.addEventListener("beforeunload", (ev) => {
            ev.preventDefault();
            return this.onLogout();
        });
    };

    render() {
    
        const { bienvenida, nombreusuario, datosperm } = this.props.loggedUser;
        let linkTo = isMobile ? '/instrucciones-pruebas' : '/pruebas';

        if(parseInt(datosperm) === 0){
            linkTo = '/usuario';
        }        

		return (
            <Fragment>               
                <Row>
                    <Col 
                        xs={{ size:12}} 
                        sm={{size:8}} 
                        md={{size:8}} 
                        lg={{size:8}} 
                    >
                        <Card 
                            className={`${isMobile ? "welcome-card-mobile" : "welcome-card"}`}
                        >
                            <AppHeader onExit={this.props.logoutUser} />
                            <CardBody>
                                <Row>
                                    <Col className="welcome-user">
                                        {nombreusuario} :
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div 
                                            dangerouslySetInnerHTML={{ __html: bienvenida }} 
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <Link 
                                    to={linkTo}              
                                >     
                                    <Button color="primary" className="float-right">
                                        Continuar
                                    </Button>
                                </Link>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>                    
            </Fragment>
        );

    }

}

const mapStateToProps = ({ authUser }) => {
    const { loggedUser } = authUser;
	return { loggedUser };
};

export default connect(
	mapStateToProps,
	{ getTestList, logoutUser }
)(WelcomeForm);