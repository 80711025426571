import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { actionTypes } from '../actions/notification.actions';
import { error as errorNotification, success as successNotification } from 'react-notification-system-redux';

function* showSuccessNotification({ payload }) {
    const { title, message} = payload;
    yield put(successNotification({title, message}));            
};

function* showErrorNotification({ payload }) {
    const { title, message} = payload;
    yield put(errorNotification({title, message}));            
};

export function* watchShowSuccessNotification() {
    yield takeEvery( actionTypes.SHOW_SUCCESS_NOTIFICATION, showSuccessNotification);
}

export function* watchShowErrorNotification() {
    yield takeEvery( actionTypes.SHOW_ERROR_NOTIFICATION, showErrorNotification );
} 

export default function* rootSaga() {
    yield all([        
        fork(watchShowSuccessNotification),
        fork(watchShowErrorNotification)
    ]);
}