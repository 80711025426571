import React, { Component } from 'react';
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import { Row, Col, Card, CardBody, CardFooter, Button } from 'reactstrap';
import { getQuestionList, resetQuestionList } from '../../store/actions/question.actions';
import { setTestStatus, setTimeStamp } from '../../store/actions/test.actions';
import { logoutUser} from "../../store/actions/auth.actions";
import Question from '../../components/Question/Question';
import AppHeader from '../../components/AppHeader/AppHeader';
import './test-form.css';
import {history} from "../../store/store";

class TestForm extends Component {

    constructor(props) {
        super(props);
        const { id, instructions, type, questionsPerPage, testType, actualTest } = props.location.testProps || {};
        this.state = {
            id: id,
            instructions: instructions,
            type: type,
            testType: testType,
            questions: [],
            instructionsReaded: false,
            currentQuestionIndex: 0,
            currentQuestions: [],
            questionsPerPage: isMobile ? 1 : questionsPerPage,
            isCompleted: false,
            response: '',
            nextButtonEnabled: false,
            answeredCount: 0,
            showNextbutton: [false, true, false, true, false, true, true, false, false, false, true, true, true, true, false, false, true, true, false, false, false, true, true],
            actualTest: actualTest
        };
        let relevant_ts = (type === 1) ? 'inipru' : 'iniprac';
        fetch('/restapi/v1/ts/' + props.loggedUser.idunico + '/'+ id +'/' + relevant_ts);
    }

    setupBeforeUnloadListener = () => {
        const { actualTest, instructionsReaded } = this.state;
        const { idunico } = this.props.loggedUser;
        window.addEventListener("beforeunload", (ev) => {
            ev.preventDefault();
            this.onLogout();
        });
    };

    componentWillMount() {
        const { id, actualTest } = this.state;
        if (!id)
            history.push('/pruebas');
    }

    componentDidMount() {
        const { id, type, actualTest, instructionsReaded } = this.state;
        this.props.getQuestionList(id, type);
        this.setupBeforeUnloadListener();
    }

    componentDidUpdate(prevProps, prevState){
		if(prevProps.list !== this.props.list){
            const { currentQuestionIndex, questionsPerPage, actualTest } = this.state;
            let currentQuestions = this.props.list.slice(currentQuestionIndex, questionsPerPage);
            currentQuestions.forEach(function(question){
                question.answered = false;
            })
            this.setState({
                questions: this.props.list,
                currentQuestions: currentQuestions,
                answeredCount: 0
            });
        }
	}

    onInstructionsButtonClicked = () => {
        this.setState({ instructionsReaded: true });
    }

    onAnswerSelect = (questionId, answerId, value) => {
        let {questions, currentQuestions, testType, showNextbutton, answeredCount } = this.state;
        const isNextButton = showNextbutton[testType];
        let nextButtonEnabledLocal = true;
        let selectedValue = value;
        let CleaverPattern = /^[1-9]{2}$/;
        questions.forEach(function(question){
            if(question.id_prp === questionId){
                question.answer_selected = selectedValue;
            }
        });
        currentQuestions.forEach(function(currentQuestion){
            if(currentQuestion.id_prp === questionId){
                currentQuestion.answered = true;
            }
            if(!currentQuestion.answered){
                nextButtonEnabledLocal = false;
            } else {
                if(parseInt(testType) === 10 || parseInt(testType) === 1) {
                    if(value.match(CleaverPattern)) {
                        answeredCount = 2;
                    } else {
                        answeredCount = 1;
                    }
                } else {
                    answeredCount += 1;
                }
                
            }
        });
        if(parseInt(testType) === 1 && parseInt(answeredCount) === 2){
            nextButtonEnabledLocal = true;
        } else if(parseInt(testType) === 10 && parseInt(answeredCount) === 2){
            nextButtonEnabledLocal = true;
        } else if(testType === 3 || testType === 5 || testType === 17) {
            nextButtonEnabledLocal = true;
        }
        if(!isNextButton){
            const { currentQuestionIndex, questionsPerPage, questions, type, id, isCompleted} = this.state;
            const start = parseInt(currentQuestionIndex) + parseInt(questionsPerPage);
            const end = parseInt(start) + parseInt(questionsPerPage);
            let currentQuestions = questions.slice(start, end);
            if(currentQuestions.length === 0){
                const {idunico} = this.props.loggedUser;
                if(type===0){
                    this.setState({actualTest: 0});
                    this.props.setTestStatus(id, 1, idunico, '');
                    this.props.resetQuestionList();
                    alert("HAS TERMINADO LA PRACTICA DE LA PRUEBA No. " + id + "\n\nPresiona [Aceptar] para regresar a tu menú de pruebas, \ndesde donde podrás iniciar la prueba real o bien volver a practicar.");
                    fetch('/restapi/v1/ts/' + idunico + '/'+ id +'/finprac');
                } else {
                    let response = '';
                    questions.forEach(function(question){
                        response = response + question.answer_selected;
                    });
                    this.setState({actualTest: 0});
                    this.props.setTestStatus(id, 9, idunico, response);
                    this.props.setTimeStamp(id, idunico, 'tsfinpru');
                    fetch('/restapi/v1/ts/' + idunico + '/'+ id +'/finpru');
                }
            } else {
                currentQuestions.forEach(function(question){
                    question.answered = false;
                })
                this.setState({
                    currentQuestions: currentQuestions,
                    currentQuestionIndex: start,
                    nextButtonEnabled: false,
                    questions: questions
                });
            }
        } else {

            if(parseInt(testType) === 1 && parseInt(answeredCount) < 2){
                nextButtonEnabledLocal = false;
            } else if(parseInt(testType) === 10 && parseInt(answeredCount) < 2){
                nextButtonEnabledLocal = false;
            }
            this.setState({
                questions: questions,
                currentQuestions: currentQuestions,
                nextButtonEnabled: nextButtonEnabledLocal,
                answeredCount: answeredCount
            });
        }
    }

    moveNextQuestions = () => {
        const { currentQuestionIndex, questionsPerPage, questions, type, id, nextButtonEnabled, testType, isCompleted } = this.state;
        if(!nextButtonEnabled && testType !== 3 && testType !== 5 && testType !== 17){
            alert("Debes contestar todas las preguntas antes de continuar");
        } else {
            const start = parseInt(currentQuestionIndex) + parseInt(questionsPerPage);
            const end = parseInt(start) + parseInt(questionsPerPage);
            let currentQuestions = questions.slice(start, end);
            if(currentQuestions.length === 0){
                const {idunico} = this.props.loggedUser;
                if(type===0){
                    this.setState({actualTest: 0});
                    this.props.setTestStatus(id, 1, idunico, '');
                    this.props.resetQuestionList();
                    alert("HAS TERMINADO LA PRACTICA DE LA PRUEBA No. " + id + "\n\nPresiona [Aceptar] para regresar a tu menú de pruebas, \ndesde donde podrás iniciar la prueba real o bien volver a practicar.");
                    fetch('/restapi/v1/ts/' + idunico + '/'+ id +'/finprac');
                } else {
                    let response = '';
                    questions.forEach(function(question){
                        if(testType === 3 || testType === 5 || testType === 17){
                            response = response + (question.answer_selected === undefined ? "4321" : question.answer_selected);
                        } else {
                            response = response + question.answer_selected;
                        }
                    });
                    this.setState({actualTest: 0});
                    this.props.setTestStatus(id, 9, idunico, response);
                    this.props.setTimeStamp(id, idunico, 'tsfinpru');
                    fetch('/restapi/v1/ts/' + idunico + '/'+ id +'/finpru');
                }
            } else {
                currentQuestions.forEach(function(question){
                    question.answered = false;
                })
                this.setState({
                    currentQuestions: currentQuestions,
                    currentQuestionIndex: start,
                    nextButtonEnabled: false,
                    answeredCount: 0
                });
            }
        }
    }

    onLogout = () => {
        const { type, id, actualTest, instructionsReaded} = this.state;
        if(type===0){
            this.props.logoutUser();
        } else {
            const {idunico} = this.props.loggedUser;
            if (instructionsReaded) {
                this.props.setTestStatus(actualTest, 3, idunico, '');
                this.props.setTimeStamp(id, idunico, 'tsfinpru');
            }
            this.props.logoutUser();
        }
    }

    render(){

        const { instructionsReaded, instructions, currentQuestions, testType, showNextbutton, id, actualTest } = this.state;

        const questionsToRender =  (
            <>
                { currentQuestions.map( (question, index) =>
                    <Question
                        key={index}
                        id={question['id_prp']}
                        question={question['texto']}
                        questionType={testType}
                        isTask={false}
                        answers={question['answers']}
                        onAnswerSelect={this.onAnswerSelect}
                    />
				)}
            </>
        );

        return (

            <Row>
                <Col
                    xs={{ size:12}}
                    sm={{size:8}}
                    md={{size:8}}
                    lg={{size:8}}
                >
                    <Card className={`${isMobile ? 'test-form-card-mobile' : 'test-form-card'}`}>
                        <AppHeader
                            //onExit={this.onLogout}
                        />
                        <CardBody
                            className={`${isMobile ? 'test-list-card-body-mobile' : 'test-list-card-body'}`}
                        >
                            {
                                instructionsReaded ?
                                questionsToRender :
                                <div
                                    dangerouslySetInnerHTML={{ __html: instructions }}
                                />
                            }
                            {
                                !instructionsReaded && actualTest != 0 &&
                                <div className="alert-section">
                                    <div className="alert-section-icon">
                                        <img
                                            src={require('../../assets/img/information.png')}
                                        />
                                    </div>
                                    <div className="alert-section-instructions">
                                        <div className="instructions-title">MUY IMPORTANTE</div>
                                        <ul>
                                            <li>
                                                Una vez que presiones el botón Comenzar, debes seguir hasta terminar por completo esta prueba, de otra forma se considerará como abandonada y no podrás volver a iniciarla.
                                            </li>
                                            <li>
                                                Durante la prueba no utilices los botones de comandos de tu navegador ni escribas nada en la barra de direcciones, cualquiera de estas acciones suspenderá la aplicación de la prueba.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            }
                        </CardBody>
                        <CardFooter>
                            {
                                !instructionsReaded &&
                                <Button
                                    color="primary"
                                    className="float-right"
                                    actualtest={ id }
                                    onClick={this.onInstructionsButtonClicked}
                                >
                                    Continuar
                                </Button>
                            }
                            {
                                showNextbutton[testType] && instructionsReaded &&
                                <Button
                                    color='primary'
                                    className='float-right'
                                    onClick={ this.moveNextQuestions }
                                >
                                    Siguiente
                                </Button>
                            }
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        );

    }
}

const mapStateToProps = ({ questions, authUser }) => {
    const { list } = questions;
    const { loggedUser } = authUser;
	return { list, loggedUser };
};

export default connect(
	mapStateToProps,
	{ getQuestionList, setTestStatus, logoutUser, resetQuestionList, setTimeStamp }
)(TestForm);
