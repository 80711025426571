import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Row, Col, Card, Form, Input, Label, Button } from "reactstrap";
import { showErrorNotification } from '../../store/actions/notification.actions';
import { loginUser } from "../../store/actions/auth.actions";

import './login.css'

class LoginForm extends Component {

    constructor(props) {
		super(props);		

		this.state = {		
			companyId: '',
            userId: '',
            userPassword: ''			
		};
    }
    
    handleChange = (event) => {
		const { name, value } = event.target;		
        this.setState({ [name]: value.toUpperCase() });
    };

    onUserLogin = event => {
        event.preventDefault();
        const { companyId, userId, userPassword } = this.state;
		if (companyId !== '' && userId !== '' && userPassword !== '') {
            const id = `${companyId}-${userId}-${userPassword}`;
			this.props.loginUser(id);
		} else {
            this.props.showErrorNotification('Codigo Invalido', 'Por favor ingrese un Codigo Valido')
        }
	}

    render() {

        const { companyId, userId, userPassword } = this.state;
        
		return (
            <Fragment>
                <Row className='login-form'>
                    <Col 
                        xs={{ size:12}} 
                        sm={{size:8, offset:2}} 
                        md={{size:6, offset:3}} 
                        lg={{size:4, offset:4}} 
                    >
                        <Card className='login-form-auth-card'>
                            <Form 
                                className='login-form-auth-card-inputs' 
                                autoComplete="off" 
                                onSubmit={this.onUserLogin}
                            >
                                <Row className='login-form-auth-card-inputs-row'>
                                    <Label>
                                        Clave de acceso:
                                    </Label>
                                </Row>
                                <Row className='login-form-auth-card-inputs-row'>
                                    <Col xs={4} className='card-access-input'>
                                        <Input
                                            autoFocus={true}
                                            type='text' 
                                            name='companyId'
                                            value={companyId} 
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                    <Col xs={4} className='card-access-input'>
                                        <Input
                                            type='number' 
                                            name='userId'
                                            value={userId} 
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                    <Col xs={4} className='card-access-input'>
                                        <Input 
                                            type='text' 
                                            name='userPassword'
                                            value={userPassword}
                                            type="password" 
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                </Row>
                                <Row className='login-form-auth-card-button-row'>
                                    <Col>
                                        <Button 
                                            color='primary'
                                            size='sm'
                                        >
                                            Entrar
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Col>
                </Row>                    
            </Fragment>
        );

    }

}

const mapStateToProps = ({ authUser }) => {
	const { loading } = authUser;	
	return { loading };
};

export default connect(
	mapStateToProps,
	{ loginUser, showErrorNotification }
)(LoginForm);