export const gordon_practice = [{
        "id_prp": "01",
        "id_pruebas": 10,
        "tipo": 0,
        "orden": "1",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "01",
                "valor": "A",
                "texto": "Prefiere levantarse temprano en las mañanas"
            },
            {
                "id_prr": "02",
                "id_prp": "01",
                "valor": "B",
                "texto": "No le interesa la musica polular"
            },
            {
                "id_prr": "03",
                "id_prp": "01",
                "valor": "C",
                "texto": "Tiene muy buenos conocimientos de inglés"
            },
            {
                "id_prr": "04",
                "id_prp": "01",
                "valor": "D",
                "texto": "Obtiene una muy mal balanceada dieta"
            }
        ]
    },
    {
        "id_prp": "02",
        "id_pruebas": 10,
        "tipo": 0,
        "orden": "2",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "02",
                "valor": "A",
                "texto": "Gusta leer revistas de modas"
            },
            {
                "id_prr": "02",
                "id_prp": "02",
                "valor": "B",
                "texto": "Los fines de semana los utiliza principalmente para descansar"
            },
            {
                "id_prr": "03",
                "id_prp": "02",
                "valor": "C",
                "texto": "Tiene muchas amistades"
            },
            {
                "id_prr": "04",
                "id_prp": "02",
                "valor": "D",
                "texto": "Le gusta que su área de trabajo esté en orden"
            }
        ]
    }
];

export const gordon = [{
        "id_prp": "01",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "1",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "01",
                "valor": "1",
                "texto": "Es bastante sociable"
            },
            {
                "id_prr": "02",
                "id_prp": "01",
                "valor": "2",
                "texto": "Le falta confianza en sí mismo"
            },
            {
                "id_prr": "03",
                "id_prp": "01",
                "valor": "3",
                "texto": "Cumplido en cualquier trabajo que realiza"
            },
            {
                "id_prr": "04",
                "id_prp": "01",
                "valor": "4",
                "texto": "Tendencia a ser algo emocional"
            }
        ]
    },
    {
        "id_prp": "02",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "2",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "02",
                "valor": "1",
                "texto": "No le interesa estar con otra gente"
            },
            {
                "id_prr": "02",
                "id_prp": "02",
                "valor": "2",
                "texto": "Libre de ansiedades y tensiones"
            },
            {
                "id_prr": "03",
                "id_prp": "02",
                "valor": "3",
                "texto": "Una persona bastante irresponsable"
            },
            {
                "id_prr": "04",
                "id_prp": "02",
                "valor": "4",
                "texto": "Toma el mando en las discusiones de grupo"
            }
        ]
    },
    {
        "id_prp": "03",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "3",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "03",
                "valor": "1",
                "texto": "Actúa nerviosa e inestablemente"
            },
            {
                "id_prr": "02",
                "id_prp": "03",
                "valor": "2",
                "texto": "Tiene una gran influencia sobre otros"
            },
            {
                "id_prr": "03",
                "id_prp": "03",
                "valor": "3",
                "texto": "No le gustan las reuniones sociales"
            },
            {
                "id_prr": "04",
                "id_prp": "03",
                "valor": "4",
                "texto": "Un trabajador concienzudo y persistente"
            }
        ]
    },
    {
        "id_prp": "04",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "4",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "04",
                "valor": "1",
                "texto": "Se le facilita hacer nuevas amistades"
            },
            {
                "id_prr": "02",
                "id_prp": "04",
                "valor": "2",
                "texto": "No soporta hacer la misma tarea por mucho tiempo"
            },
            {
                "id_prr": "03",
                "id_prp": "04",
                "valor": "3",
                "texto": "Fácilmente manejado por otros"
            },
            {
                "id_prr": "04",
                "id_prp": "04",
                "valor": "4",
                "texto": "Se mantiene controlado aún cuando se ha frustrado"
            }
        ]
    },
    {
        "id_prp": "05",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "5",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "05",
                "valor": "1",
                "texto": "Capaz de hacer decisiones importantes sin ayuda"
            },
            {
                "id_prr": "02",
                "id_prp": "05",
                "valor": "2",
                "texto": "No se relaciona fácilmente con gente desconocida"
            },
            {
                "id_prr": "03",
                "id_prp": "05",
                "valor": "3",
                "texto": "Tiende a estar en tensión"
            },
            {
                "id_prr": "04",
                "id_prp": "05",
                "valor": "4",
                "texto": "Realiza su trabajo a pesar de tener problemas"
            }
        ]
    },
    {
        "id_prp": "06",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "6",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "06",
                "valor": "1",
                "texto": "No tiene interés en ser sociable"
            },
            {
                "id_prr": "02",
                "id_prp": "06",
                "valor": "2",
                "texto": "No toma en serio sus responsabilidades"
            },
            {
                "id_prr": "03",
                "id_prp": "06",
                "valor": "3",
                "texto": "Seguro de sí mismo todo el tiempo"
            },
            {
                "id_prr": "04",
                "id_prp": "06",
                "valor": "4",
                "texto": "Toma el mando en trabajos de grupo"
            }
        ]
    },
    {
        "id_prp": "07",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "7",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "07",
                "valor": "1",
                "texto": "Una persona en quien se puede confiar"
            },
            {
                "id_prr": "02",
                "id_prp": "07",
                "valor": "2",
                "texto": "Se disgusta fácilmente cuando las cosas van mal"
            },
            {
                "id_prr": "03",
                "id_prp": "07",
                "valor": "3",
                "texto": "No esta muy seguro de sus propias opiniones"
            },
            {
                "id_prr": "04",
                "id_prp": "07",
                "valor": "4",
                "texto": "Prefiere estar cerca de la gente"
            }
        ]
    },
    {
        "id_prp": "08",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "8",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "08",
                "valor": "1",
                "texto": "Le parece fácil influir sobre otros"
            },
            {
                "id_prr": "02",
                "id_prp": "08",
                "valor": "2",
                "texto": "Realiza su trabajo ante cualquier obstáculo"
            },
            {
                "id_prr": "03",
                "id_prp": "08",
                "valor": "3",
                "texto": "Limita sus relaciones sociales a unos cuantos"
            },
            {
                "id_prr": "04",
                "id_prp": "08",
                "valor": "4",
                "texto": "Tiende a ser una persona más bien nerviosa"
            }
        ]
    },
    {
        "id_prp": "09",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "9",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "09",
                "valor": "1",
                "texto": "No hace amistades muy rápidamente"
            },
            {
                "id_prr": "02",
                "id_prp": "09",
                "valor": "2",
                "texto": "Toma una parte activa en trabajos de grupo"
            },
            {
                "id_prr": "03",
                "id_prp": "09",
                "valor": "3",
                "texto": "Persiste en trabajos rutinarios hasta terminarlos"
            },
            {
                "id_prr": "04",
                "id_prp": "09",
                "valor": "4",
                "texto": "No tiene un buen balance emocional"
            }
        ]
    },
    {
        "id_prp": "10",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "10",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "10",
                "valor": "1",
                "texto": "Seguro en sus relaciones con otras personas"
            },
            {
                "id_prr": "02",
                "id_prp": "10",
                "valor": "2",
                "texto": "Se siente fácilmente lastimado"
            },
            {
                "id_prr": "03",
                "id_prp": "10",
                "valor": "3",
                "texto": "Tiene hábitos de trabajo bien desarrollados"
            },
            {
                "id_prr": "04",
                "id_prp": "10",
                "valor": "4",
                "texto": "Preferiría conservar un grupo pequeño de amigos"
            }
        ]
    },
    {
        "id_prp": "11",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "11",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "11",
                "valor": "1",
                "texto": "Se irrita con facilidad"
            },
            {
                "id_prr": "02",
                "id_prp": "11",
                "valor": "2",
                "texto": "Capaz de manejar cualquier situación"
            },
            {
                "id_prr": "03",
                "id_prp": "11",
                "valor": "3",
                "texto": "No le gusta conversar con extraños"
            },
            {
                "id_prr": "04",
                "id_prp": "11",
                "valor": "4",
                "texto": "Cumplido en cualquier trabajo realizado"
            }
        ]
    },
    {
        "id_prp": "12",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "12",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "12",
                "valor": "1",
                "texto": "Prefiere no discutir con otras personas"
            },
            {
                "id_prr": "02",
                "id_prp": "12",
                "valor": "2",
                "texto": "Incapaz de mantener un horario fijo"
            },
            {
                "id_prr": "03",
                "id_prp": "12",
                "valor": "3",
                "texto": "Una persona tranquila y calmada"
            },
            {
                "id_prr": "04",
                "id_prp": "11",
                "valor": "4",
                "texto": "Se inclina a ser muy sociable"
            }
        ]
    },
    {
        "id_prp": "13",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "13",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "13",
                "valor": "1",
                "texto": "Libre de cuidados y preocupaciones"
            },
            {
                "id_prr": "02",
                "id_prp": "13",
                "valor": "2",
                "texto": "Falta de sentido de responsabilidad"
            },
            {
                "id_prr": "03",
                "id_prp": "13",
                "valor": "3",
                "texto": "No le interesa relacionarse con el sexo opuesto"
            },
            {
                "id_prr": "04",
                "id_prp": "13",
                "valor": "4",
                "texto": "Habilidad en el trato con otras personas"
            }
        ]
    },
    {
        "id_prp": "14",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "14",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "14",
                "valor": "1",
                "texto": "Encuentra fácil hacer amistad con otros"
            },
            {
                "id_prr": "02",
                "id_prp": "14",
                "valor": "2",
                "texto": "Prefiere que otros tomen la decisión estando en grupo"
            },
            {
                "id_prr": "03",
                "id_prp": "14",
                "valor": "3",
                "texto": "Tiene una naturaleza peligrosa"
            },
            {
                "id_prr": "04",
                "id_prp": "14",
                "valor": "4",
                "texto": "Mantiene el mismo nivel de trabajo a pesar de dificultades"
            }
        ]
    },
    {
        "id_prp": "15",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "15",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "15",
                "valor": "1",
                "texto": "Capaz de cambiar las opiniones de los demás"
            },
            {
                "id_prr": "02",
                "id_prp": "15",
                "valor": "2",
                "texto": "Falta de interés para llevar actividades de grupo"
            },
            {
                "id_prr": "03",
                "id_prp": "15",
                "valor": "3",
                "texto": "Una persona muy nerviosa"
            },
            {
                "id_prr": "04",
                "id_prp": "15",
                "valor": "4",
                "texto": "Muy persistente en cualquier trabajo desempeñado"
            }
        ]
    },
    {
        "id_prp": "16",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "16",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "16",
                "valor": "1",
                "texto": "Calmado y de buenas maneras"
            },
            {
                "id_prr": "02",
                "id_prp": "16",
                "valor": "2",
                "texto": "Incapacitado para realizar su trabajo"
            },
            {
                "id_prr": "03",
                "id_prp": "16",
                "valor": "3",
                "texto": "Goza de tener cantidad de amigos a su alrededor"
            },
            {
                "id_prr": "04",
                "id_prp": "16",
                "valor": "4",
                "texto": "No confía demasiado en sus propias habilidades"
            }
        ]
    },
    {
        "id_prp": "17",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "17",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "17",
                "valor": "1",
                "texto": "Se puede confiar plenamente en usted"
            },
            {
                "id_prr": "02",
                "id_prp": "17",
                "valor": "2",
                "texto": "Solamente le importa la compañía de ciertas personas"
            },
            {
                "id_prr": "03",
                "id_prp": "17",
                "valor": "3",
                "texto": "Encuentra dificultad para relajarse"
            },
            {
                "id_prr": "04",
                "id_prp": "17",
                "valor": "4",
                "texto": "Toma una parte activa en discusiones de grupo"
            }
        ]
    },
    {
        "id_prp": "18",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "18",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "18",
                "valor": "1",
                "texto": "No se da por vencido fácilmente ante un problema"
            },
            {
                "id_prr": "02",
                "id_prp": "18",
                "valor": "2",
                "texto": "Tiende a ser algo nervioso"
            },
            {
                "id_prr": "03",
                "id_prp": "18",
                "valor": "3",
                "texto": "Falta de seguridad en sí mismo"
            },
            {
                "id_prr": "04",
                "id_prp": "18",
                "valor": "4",
                "texto": "Prefiere pasar el tiempo en compañía de otros"
            }
        ]
    },
    {
        "id_prp": "19",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "19",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "19",
                "valor": "1",
                "texto": "Un pensador muy original"
            },
            {
                "id_prr": "02",
                "id_prp": "19",
                "valor": "2",
                "texto": "Una persona un poco lenta y pausada"
            },
            {
                "id_prr": "03",
                "id_prp": "19",
                "valor": "3",
                "texto": "Tiende a criticar a otros"
            },
            {
                "id_prr": "04",
                "id_prp": "19",
                "valor": "4",
                "texto": "Hace decisiones después de mucho pensar"
            }
        ]
    },
    {
        "id_prp": "20",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "20",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "20",
                "valor": "1",
                "texto": "Piensa que todos son esencialmente honestos"
            },
            {
                "id_prr": "02",
                "id_prp": "20",
                "valor": "2",
                "texto": "Le gusta tomar con calma el trabajo y el juego"
            },
            {
                "id_prr": "03",
                "id_prp": "20",
                "valor": "3",
                "texto": "Tiene una actitud inquisitiva"
            },
            {
                "id_prr": "04",
                "id_prp": "20",
                "valor": "4",
                "texto": "Tiende a actuar impulsivamente"
            }
        ]
    },
    {
        "id_prp": "21",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "21",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "21",
                "valor": "1",
                "texto": "Una persona muy activa"
            },
            {
                "id_prr": "02",
                "id_prp": "21",
                "valor": "2",
                "texto": "No se enoja con otras personas"
            },
            {
                "id_prr": "03",
                "id_prp": "21",
                "valor": "3",
                "texto": "Le gusta trabajar con problemas complejos y difíciles"
            },
            {
                "id_prr": "04",
                "id_prp": "21",
                "valor": "4",
                "texto": "Prefiere fiestas animadas a pláticas tranquilas"
            }
        ]
    },
    {
        "id_prp": "22",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "22",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "22",
                "valor": "1",
                "texto": "Disfruta de las discusiones filosóficas"
            },
            {
                "id_prr": "02",
                "id_prp": "22",
                "valor": "2",
                "texto": "Se cansa fácilmente"
            },
            {
                "id_prr": "03",
                "id_prp": "22",
                "valor": "3",
                "texto": "Piensa las cosas con más cuidado antes de actuar"
            },
            {
                "id_prr": "04",
                "id_prp": "22",
                "valor": "4",
                "texto": "No tiene una gran confianza en otras personas"
            }
        ]
    },
    {
        "id_prp": "23",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "23",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "23",
                "valor": "1",
                "texto": "Le gusta trabajar principalmente con ideas"
            },
            {
                "id_prr": "02",
                "id_prp": "23",
                "valor": "2",
                "texto": "Realiza sus acciones más bien lentamente"
            },
            {
                "id_prr": "03",
                "id_prp": "23",
                "valor": "3",
                "texto": "Es muy cuidadoso cuando toma una decisión"
            },
            {
                "id_prr": "04",
                "id_prp": "23",
                "valor": "4",
                "texto": "Encuentra algunas personas difíciles de sobrellevar"
            }
        ]
    },
    {
        "id_prp": "24",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "24",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "24",
                "valor": "1",
                "texto": "Una gran persona para aprovechar oportunidades"
            },
            {
                "id_prr": "02",
                "id_prp": "24",
                "valor": "2",
                "texto": "Se irrita rápidamente con otras personas"
            },
            {
                "id_prr": "03",
                "id_prp": "24",
                "valor": "3",
                "texto": "Puede hacer muchas cosas en poco tiempo"
            },
            {
                "id_prr": "04",
                "id_prp": "24",
                "valor": "4",
                "texto": "Piensa mucho tiempo en nuevas ideas"
            }
        ]
    },
    {
        "id_prp": "25",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "25",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "25",
                "valor": "1",
                "texto": "Una persona muy paciente"
            },
            {
                "id_prr": "02",
                "id_prp": "25",
                "valor": "2",
                "texto": "Busca hacer cosas excitantes"
            },
            {
                "id_prr": "03",
                "id_prp": "25",
                "valor": "3",
                "texto": "Capaz de trabajar por largos lapsos de tiempo"
            },
            {
                "id_prr": "04",
                "id_prp": "25",
                "valor": "4",
                "texto": "Prefiere poner algo en práctica en vez de planearlo"
            }
        ]
    },
    {
        "id_prp": "26",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "26",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "26",
                "valor": "1",
                "texto": "Se siente muy cansado y preocupado al final del día"
            },
            {
                "id_prr": "02",
                "id_prp": "26",
                "valor": "2",
                "texto": "Se inclina a hacer juicios apresurados"
            },
            {
                "id_prr": "03",
                "id_prp": "26",
                "valor": "3",
                "texto": "No muestra resentimiento hacia otras personas"
            },
            {
                "id_prr": "04",
                "id_prp": "26",
                "valor": "4",
                "texto": "Tiene un gran interés en aprender"
            }
        ]
    },
    {
        "id_prp": "27",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "27",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "27",
                "valor": "1",
                "texto": "No actúa al momento"
            },
            {
                "id_prr": "02",
                "id_prp": "27",
                "valor": "2",
                "texto": "Se irrita por las faltas de otros"
            },
            {
                "id_prr": "03",
                "id_prp": "27",
                "valor": "3",
                "texto": "Le falta interés en hacer pensamiento crítico"
            },
            {
                "id_prr": "04",
                "id_prp": "27",
                "valor": "4",
                "texto": "Prefiere trabajar rápidamente"
            }
        ]
    },
    {
        "id_prp": "28",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "28",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "28",
                "valor": "1",
                "texto": "Se inclina a volverse muy enojado con la gente"
            },
            {
                "id_prr": "02",
                "id_prp": "28",
                "valor": "2",
                "texto": "Le gusta estar activo todo el tiempo"
            },
            {
                "id_prr": "03",
                "id_prp": "28",
                "valor": "3",
                "texto": "Preferiría no correr riesgos"
            },
            {
                "id_prr": "04",
                "id_prp": "28",
                "valor": "4",
                "texto": "Prefiere trabajos que requieren pocas ideas originales o reflexión"
            }
        ]
    },
    {
        "id_prp": "29",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "29",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "29",
                "valor": "1",
                "texto": "Una persona muy cauta"
            },
            {
                "id_prr": "02",
                "id_prp": "29",
                "valor": "2",
                "texto": "Prefiere trabajar más bien despacio"
            },
            {
                "id_prr": "03",
                "id_prp": "29",
                "valor": "3",
                "texto": "Muy diplomático y cuidadoso"
            },
            {
                "id_prr": "04",
                "id_prp": "29",
                "valor": "4",
                "texto": "Preferiría no ocupar su mente en pensamientos profundos"
            }
        ]
    },
    {
        "id_prp": "30",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "30",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "30",
                "valor": "1",
                "texto": "Pierde la paciencia rápidamente con las personas"
            },
            {
                "id_prr": "02",
                "id_prp": "30",
                "valor": "2",
                "texto": "Tiene menor resistencia física que la mayoría de la gente"
            },
            {
                "id_prr": "03",
                "id_prp": "30",
                "valor": "3",
                "texto": "Tiende a ser creativo y original"
            },
            {
                "id_prr": "04",
                "id_prp": "30",
                "valor": "4",
                "texto": "No le importan mucho las cosas excitantes"
            }
        ]
    },
    {
        "id_prp": "31",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "31",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "31",
                "valor": "1",
                "texto": "Tiende a actuar precipitadamente"
            },
            {
                "id_prr": "02",
                "id_prp": "31",
                "valor": "2",
                "texto": "Tiene un gran vigor y dinamismo"
            },
            {
                "id_prr": "03",
                "id_prp": "31",
                "valor": "3",
                "texto": "No confía en la gente hasta que ellos se han probado"
            },
            {
                "id_prr": "04",
                "id_prp": "31",
                "valor": "4",
                "texto": "Le gustan los problemas que requieren mucha reflexión"
            }
        ]
    },
    {
        "id_prp": "32",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "32",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "32",
                "valor": "1",
                "texto": "No le gusta trabajar rápidamente"
            },
            {
                "id_prr": "02",
                "id_prp": "32",
                "valor": "2",
                "texto": "Tiene gran fe en la gente"
            },
            {
                "id_prr": "03",
                "id_prp": "32",
                "valor": "3",
                "texto": "Tendencia a entregarse al deseo del momento"
            },
            {
                "id_prr": "04",
                "id_prp": "32",
                "valor": "4",
                "texto": "Goza en llevar a cabo problemas complicados"
            }
        ]
    },
    {
        "id_prp": "33",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "33",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "33",
                "valor": "1",
                "texto": "Un trabajador muy activo"
            },
            {
                "id_prr": "02",
                "id_prp": "33",
                "valor": "2",
                "texto": "Acepta la crítica con buen humor"
            },
            {
                "id_prr": "03",
                "id_prp": "33",
                "valor": "3",
                "texto": "No acepta problemas que impliquen mucho razonamiento"
            },
            {
                "id_prr": "04",
                "id_prp": "33",
                "valor": "4",
                "texto": "Se inclina a actuar primero y a pensar después"
            }
        ]
    },
    {
        "id_prp": "34",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "34",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "34",
                "valor": "1",
                "texto": "No habla sino lo mejor de otras personas"
            },
            {
                "id_prr": "02",
                "id_prp": "34",
                "valor": "2",
                "texto": "Muy cauteloso antes de decidir"
            },
            {
                "id_prr": "03",
                "id_prp": "34",
                "valor": "3",
                "texto": "No le interesa provocar discusiones de pensamiento elevado"
            },
            {
                "id_prr": "04",
                "id_prp": "34",
                "valor": "4",
                "texto": "No se apresura en ir de un lugar a otro"
            }
        ]
    },
    {
        "id_prp": "35",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "35",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "35",
                "valor": "1",
                "texto": "No tiene una mente inquisitiva"
            },
            {
                "id_prr": "02",
                "id_prp": "35",
                "valor": "2",
                "texto": "No actúa impulsivamente"
            },
            {
                "id_prr": "03",
                "id_prp": "35",
                "valor": "3",
                "texto": "Generalmente procede con energía"
            },
            {
                "id_prr": "04",
                "id_prp": "35",
                "valor": "4",
                "texto": "Se irrita fácilmente por la debilidad de otra gente"
            }
        ]
    },
    {
        "id_prp": "36",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "36",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "36",
                "valor": "1",
                "texto": "Puede hacer más cosas que otras personas"
            },
            {
                "id_prr": "02",
                "id_prp": "36",
                "valor": "2",
                "texto": "Toma las oportunidades sólo por placer"
            },
            {
                "id_prr": "03",
                "id_prp": "36",
                "valor": "3",
                "texto": "Se ofende cuando es criticado"
            },
            {
                "id_prr": "04",
                "id_prp": "36",
                "valor": "4",
                "texto": "Preferiría trabajar con ideas que con cosas"
            }
        ]
    },
    {
        "id_prp": "37",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "37",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "37",
                "valor": "1",
                "texto": "Muy confiado en otras personas"
            },
            {
                "id_prr": "02",
                "id_prp": "37",
                "valor": "2",
                "texto": "Prefiere desempeñar trabajos simples o de rutina"
            },
            {
                "id_prr": "03",
                "id_prp": "37",
                "valor": "3",
                "texto": "Realiza todo al momento"
            },
            {
                "id_prr": "04",
                "id_prp": "37",
                "valor": "4",
                "texto": "Lleno de vigor y vitalidad"
            }
        ]
    },
    {
        "id_prp": "38",
        "id_pruebas": 10,
        "tipo": 1,
        "orden": "38",
        "texto": "Seleccione la frase que más y menos se asemeja a Ud...",
        "answers": [{
                "id_prr": "01",
                "id_prp": "38",
                "valor": "1",
                "texto": "Hace decisiones muy rápido"
            },
            {
                "id_prr": "02",
                "id_prp": "38",
                "valor": "2",
                "texto": "Le son simpáticos todos"
            },
            {
                "id_prr": "03",
                "id_prp": "38",
                "valor": "3",
                "texto": "Mantiene un ritmo rápido en el trabajo o juego"
            },
            {
                "id_prr": "04",
                "id_prp": "38",
                "valor": "4",
                "texto": "No tiene mucho interés en adquirir conocimientos"
            }
        ]
    }
];