import React, { Component, Fragment } from 'react';
import { Row, Col, Card, CardBody, CardFooter, Button } from 'reactstrap';
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import { testInstructions } from './test_list_constants';
import AppHeader from '../../components/AppHeader/AppHeader';
import './test-list-form.css'

class TestListForm extends Component {

    render() {
    
		return (
            <Fragment>              
                <Row>
                    <Col 
                        xs={{ size:12}} 
                        sm={{size:8, offset:2}} 
                        md={{size:8, offset:2}} 
                        lg={{size:8, offset:2}} 
                    >
                        <Card className={`${isMobile ? 'test-card-mobile' : 'test-card'}`}>
                            <AppHeader />
                            <CardBody 
                                className={`${isMobile ? 'test-list-card-body-mobile' : 'test-list-card-body'}`}
                            >                    
                                {testInstructions}                                
                            </CardBody>
                            <CardFooter>
                                <Link 
                                    to={`/pruebas`}              
                                >     
                                    <Button color="primary" className="float-right">
                                        Continuar
                                    </Button>
                                </Link>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>                    
            </Fragment>
        );

    }

}

export default TestListForm;
