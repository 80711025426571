export const kolv_practice = [
    {
        "id_prp": "01",
        "id_pruebas": 3,
        "tipo": 0,
        "orden": "1",
        "texto": "Cuando aprendo algo interesante me gusta...",
        "answers": [
            {   
                "id_prr": "01",
                "id_prp": "01",
                "orden": "01",
                "valor": "A",
                "texto": "Guardar los conocimientos para mi mismo"
            },
            {
                "id_prr": "02",
                "id_prp": "01",
                "orden": "02",
                "valor": "B",
                "texto": "Compartir lo aprendido"
            },
            {
                "id_prr": "03",
                "id_prp": "01",
                "orden": "03",
                "valor": "C",
                "texto": "Ponerlo en práctica de inmediato"
            },
            {
                "id_prr": "04",
                "id_prp": "01",
                "orden": "04",
                "valor": "D",
                "texto": "Pensar mucho en los conceptos aprendidos"
            }
        ]
    },
    {
        "id_prp": "02",
        "id_pruebas": 3,
        "tipo": 0,
        "orden": "2",
        "texto": "Si alguien ha aprendido más que yo...",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "02",
                "orden": "01",
                "valor": "A",
                "texto": "Me pongo alegre"
            },
            {
                "id_prr": "02",
                "id_prp": "02",
                "orden": "02",
                "valor": "B",
                "texto": "Me pongo triste"
            },
            {
                "id_prr": "03",
                "id_prp": "02",
                "orden": "03",
                "valor": "C",
                "texto": "Le pido apoyo cuando sé que me puede ayudar"
            },
            {
                "id_prr": "04",
                "id_prp": "02",
                "orden": "04",
                "valor": "D",
                "texto": "Trato de aprender más para estar a su nivel"
            }
        ]
    }
]

export const kolv = [
    {
        "id_prp": "01",
        "id_pruebas": 3,
        "tipo": 1,
        "orden": "1",
        "texto": "Aprendo mejor...",
        "answers": [
            {   
                "id_prr": "01",
                "id_prp": "01",
                "orden": "01",
                "valor": "A",
                "texto": "Diferenciando"
            },
            {
                "id_prr": "02",
                "id_prp": "01",
                "orden": "02",
                "valor": "B",
                "texto": "Siendo tentativo - ensayando"
            },
            {
                "id_prr": "03",
                "id_prp": "01",
                "orden": "03",
                "valor": "C",
                "texto": "Involucrándome"
            },
            {
                "id_prr": "04",
                "id_prp": "01",
                "orden": "04",
                "valuor": "D",
                "texto": "De manera práctica"
            }
        ]
    },
    {
        "id_prp": "02",
        "id_pruebas": 3,
        "tipo": 1,
        "orden": "2",
        "texto": "Aprendo mejor cuando soy...",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "02",
                "orden": "01",
                "valor": "A",
                "texto": "Receptivo"
            },
            {
                "id_prr": "02",
                "id_prp": "02",
                "orden": "02",
                "valor": "B",
                "texto": "Relevante"
            },
            {
                "id_prr": "03",
                "id_prp": "02",
                "orden": "03",
                "valor": "C",
                "texto": "Analítico"
            },
            {
                "id_prr": "04",
                "id_prp": "02",
                "orden": "04",
                "valor": "D",
                "texto": "Imparcial"
            }
        ]
    },
    {
        "id_prp": "03",
        "id_pruebas": 3,
        "tipo": 1,
        "orden": "3",
        "texto": "Aprendo mejor cuando...",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "03",
                "orden": "01",
                "valor": "A",
                "texto": "Me baso en mis sentimientos (Sintiendo)"
            },
            {
                "id_prr": "02",
                "id_prp": "03",
                "orden": "02",
                "valor": "B",
                "texto": "Me baso en mis observaciones (Observando)"
            },
            {
                "id_prr": "03",
                "id_prp": "03",
                "orden": "03",
                "valor": "C",
                "texto": "Me baso en mis ideas (Pensando)"
            },
            {
                "id_prr": "04",
                "id_prp": "03",
                "orden": "04",
                "valor": "D",
                "texto": "Puedo intentar algo por mí mismo (Haciendo)"
            }
        ]
    },
    {
        "id_prp": "04",
        "id_pruebas": 3,
        "tipo": 1,
        "orden": "4",
        "texto": "Aprendo mejor...",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "04",
                "orden": "01",
                "valor": "A",
                "texto": "Aceptando"
            },
            {
                "id_prr": "02",
                "id_prp": "04",
                "orden": "02",
                "valor": "B",
                "texto": "Tomando riesgo"
            },
            {
                "id_prr": "03",
                "id_prp": "04",
                "orden": "03",
                "valor": "C",
                "texto": "Evaluando"
            },
            {
                "id_prr": "04",
                "id_prp": "04",
                "orden": "04",
                "valor": "D",
                "texto": "Tomando conciencia"
            }
        ]
    },
    {
        "id_prp": "05",
        "id_pruebas": 3,
        "tipo": 1,
        "orden": "5",                   
        "texto": "Cuando estoy aprendiendo soy...",                
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "05",
                "orden": "01",
                "valor": "A",
                "texto": "Una persona intuitiva (Intuitivo)"
            },
            {
                "id_prr": "02",
                "id_prp": "05",
                "orden": "02",
                "valor": "B",
                "texto": "Una persona productiva (Productivo)"
            },
            {
                "id_prr": "03",
                "id_prp": "05",
                "orden": "03",
                "valor": "C",
                "texto": "Una persona lógica (Lógico)"
            },
            {
                "id_prr": "04",
                "id_prp": "05",
                "orden": "04",
                "valor": "D",
                "texto": "Una persona cuestionadora (Cuestionando)"
            }
        ]
    },
    {
        "id_prp": "06",
        "id_pruebas": 3,
        "tipo": 1,
        "orden": "6",                                      
        "texto": "Aprendo siendo...",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "06",
                "orden": "01",    
                "valor": "A",
                "texto": "Abstracto"
            },
            {
                "id_prr": "02",
                "id_prp": "06",
                "orden": "02",    
                "valor": "B",
                "texto": "Observador"
            },
            {
                "id_prr": "03",
                "id_prp": "06",
                "orden": "03",    
                "valor": "C",
                "texto": "Concreto"
            },
            {
                "id_prr": "04",
                "id_prp": "06",
                "orden": "04",    
                "valor": "D",
                "texto": "Activo"
            }
        ]
    },
    {
        "id_prp": "07",
        "id_pruebas": 3,
        "tipo": 1,
        "orden": "7",             
        "texto": "Aprendo mejor cuando...",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "07",
                "orden": "01",    
                "valor": "A",
                "texto": "Me oriento al presente"
            },
            {
                "id_prr": "02",
                "id_prp": "07",
                "orden": "02",    
                "valor": "B",
                "texto": "Soy reflexivo"
            },
            {
                "id_prr": "03",
                "id_prp": "07",
                "orden": "03",    
                "valor": "C",
                "texto": "Me oriento al futuro"
            },
            {
                "id_prr": "04",
                "id_prp": "07",
                "orden": "04",    
                "valor": "D",
                "texto": "Soy pragmático"
            }
        ]
    },
    {
        "id_prp": "08",
        "id_pruebas": 3,
        "tipo": 1,
        "orden": "8",             
        "texto": "Aprendo mejor de...",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "08",
                "orden": "01",    
                "valor": "A",
                "texto": "Experiencias vividas"
            },
            {
                "id_prr": "02",
                "id_prp": "08",
                "orden": "02",    
                "valor": "B",
                "texto": "Observación"
            },
            {
                "id_prr": "03",
                "id_prp": "08",
                "orden": "03",    
                "valor": "C",
                "texto": "Conceptualización"
            },
            {
                "id_prr": "04",
                "id_prp": "08",
                "orden": "04",    
                "valor": "D",
                "texto": "Experimentación"
            }
        ]
    },
    {
        "id_prp": "09",
        "id_pruebas": 3,
        "tipo": 1,
        "orden": "9",             
        "texto": "Al aprender me siento...",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "09",
                "orden": "01",    
                "valor": "A",
                "texto": "Intenso"
            },
            {
                "id_prr": "02",
                "id_prp": "09",
                "orden": "02",    
                "valor": "B",
                "texto": "Reservado"
            },
            {
                "id_prr": "03",
                "id_prp": "09",
                "orden": "03",    
                "valor": "C",
                "texto": "Racional"
            },
            {
                "id_prr": "04",
                "id_prp": "09",
                "orden": "04",    
                "valor": "D",
                "texto": "Responsable"
            }
        ]
    }
]
