import React, { Component, Fragment } from "react";
import { Col, ListGroup, ListGroupItem, UncontrolledTooltip } from 'reactstrap';
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import './cleaver-question.css';
 
class CleaverQuestion extends Component {

    constructor(props) {
        super(props);        
		this.state = { 
            answers: props.answers,
            minusSelected: [false, false, false, false],
            plusSelected: [false, false, false, false],
            selectedAnswer: [0, 0]
		};		
	}
    
    componentDidUpdate(prevProps, prevState){
		if(prevProps.answers !== this.props.answers){                      
			this.setState({ 
                answers: this.props.answers,
                minusSelected: [false, false, false, false],
                plusSelected: [false, false, false, false],
                selectedAnswer: [0, 0]
            });
		} 
    }

    checkSelected = (action, index, selected, answerId, value) => {
        let minus = this.state.minusSelected;
        let plus = this.state.plusSelected;                        
        let selectedArray = this.state.selectedAnswer;
        if(action === 'minus'){
            if(!plus[index]){
                minus = [false, false, false, false];
                minus[index] = true;
                selectedArray[1] = index + 1;
            }
        } else {
            if(!minus[index]){
                plus = [false, false, false, false];
                plus[index] = true;
                selectedArray[0] = index + 1;
            }
        }               
        this.setState({
            minusSelected: minus,
            plusSelected: plus,
            selectedAnswer: selectedArray
        })
        const stringResponse = `${selectedArray[0]}${selectedArray[1]}`;
        this.props.onAnswerSelect(this.props.questionId, answerId, stringResponse );
    }
        
    render(){        
        const { minusSelected, plusSelected } = this.state;

        const answers = (
            <Fragment>                        
                { this.state.answers.map( (answer, index) => {     
                    const plusDisabled = minusSelected[index] ? 'cleaver-disabled-notselectable' : 'cleaver-disabled';
                    const minusDisabled = plusSelected[index] ? 'cleaver-disabled-notselectable' : 'cleaver-disabled';
                    return <ListGroupItem className="justify-content-between">
                        <div 
                            className={`cleaver-badge ${plusSelected[index] ? 'cleaver-plus' : plusDisabled}`}                                                                        
                            onClick={() => this.checkSelected('plus', index, minusSelected[index], answer['id_prr'], answer['valor'])}
                        >   
                            <FontAwesomeIcon icon={faPlus} />                          
                        </div>
                        <div
                            onClick={() => this.checkSelected('minus', index, plusSelected[index], answer['id_prr'], answer['valor'])}
                            className={`cleaver-badge ${minusSelected[index] ? 'cleaver-minus' : minusDisabled}`}                                                                                                                     
                        >                                          
                            <FontAwesomeIcon icon={faMinus} />                            
                        </div>
                        <div
                            className={`${isMobile ? 'cleaver-text-mobile' : 'cleaver-text'}`}                            
                        >                                        
                            <span id={`p${answer['id_prr']}`}>{answer['texto']}</span>
                            {   answer['tip'] ?
                                (isMobile ?
                                <UncontrolledTooltip placement="top" target={`p${answer['id_prr']}`} trigger="click">
                                    {answer['tip']}
                                </UncontrolledTooltip> :
                                <UncontrolledTooltip placement="top" target={`p${answer['id_prr']}`}>
                                    {answer['tip']}
                                </UncontrolledTooltip> ):
                                null
                            }
                        </div>
                    </ListGroupItem>                    
                })}
            </Fragment>
        ); 

        return(
            <Col xs={12} md={12} lg={12} 
                className='cleaver-question-box'
            >                 
                <ListGroup>
                    {answers}
                </ListGroup>              
            </Col>
        );

    }
}

export default CleaverQuestion;