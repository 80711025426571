import React, { Component, Fragment } from "react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Col, ListGroup, ListGroupItem } from "reactstrap";
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import './sorted-question.css';
 
class SortedQuestion extends Component {

    constructor(props) {
		super(props);
		this.state = { 
            answers: props.answers,
            originalAnswers: [ ...props.answers ]
        };		
	}
    
    componentDidUpdate(prevProps, prevState){
		if(prevProps.answers !== this.props.answers){            
			this.setState({ 
                answers: this.props.answers, 
                originalAnswers: [ ...this.props.answers ]
            });
		}		
    }
    
    onDragEnd = result => {        
        const { destination, source } = result;
        if (destination) {            
            if(source.index !== destination.index){
                const reorderedQuestions = this.reorderAnswers( source.index, destination.index );                  
                this.setState({ answers: reorderedQuestions });
                const response = this.setResponse(reorderedQuestions);
                this.props.onAnswerSelect(this.props.questionId, 1, response);
            }           
        }
    }

    moveUp = id => {        
        if(id != 0){
            let reorderedQuestions = this.reorderAnswers( id, id-1 );         
            const response = this.setResponse(reorderedQuestions);
            this.props.onAnswerSelect(this.props.questionId, 1, response); 
            this.setState({ answers: reorderedQuestions });
        }
    }

    moveDown = id => {        
        if( id < this.props.answers.length-1){
            let reorderedQuestions = this.reorderAnswers( id, id+1 );          
            const response = this.setResponse(reorderedQuestions);
            this.props.onAnswerSelect(this.props.questionId, 1, response);
            this.setState({ answers: reorderedQuestions });
        }
    }

    setResponse = (answers) => {
        const { originalAnswers } = this.state;       
        let stringResponse = "";        
        originalAnswers.forEach( originalAnswer => {           
            const position = answers.findIndex( function(answer){                
                return answer.texto === originalAnswer.texto;
            });            
            stringResponse = stringResponse + (5 - (position + 1));
        });        
        return stringResponse;
    }

    reorderAnswers = (source, target) => {
        let reorderedQuestions = this.state.answers;               
        const questionMoved = reorderedQuestions[source];
        reorderedQuestions.splice(source, 1);
        reorderedQuestions.splice(target, 0, questionMoved);             
        return reorderedQuestions;
    }

    render(){
    
        const answers = (
            <Fragment>                        
                { this.state.answers.map( (answer, index) =>      
                    <Draggable 
                        draggableId={`${index}`}
                        key={index}
                        index={index}                    
                    >
                        {(provided, snapshot) => (        
                            <span 
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                            >
                                <div 
                                    key={index}
                                    id={index}
                                >
                                    <ListGroupItem className="justify-content-between sorted-item">
                                        <div 
                                            className={`answer-badge ${index == 0 ? 'disabled' : 'plus'}`}                                            
                                            onClick={() => { this.moveUp(index) }}
                                        >                                            
                                            <FontAwesomeIcon icon={faPlus} />                                                                                            
                                        </div>
                                        <div
                                            className={`answer-badge ${index == (this.props.answers.length-1) ? 'disabled' : 'minus'}`}                                                                                         
                                            onClick={() => { this.moveDown(index) }}
                                        >                                               
                                            <FontAwesomeIcon icon={faMinus} />                                            
                                        </div>
                                        <div
                                            className={`${isMobile ? 'answer-text-mobile' : 'answer-text'}`}                                                
                                        >                                        
                                            {answer['texto']}
                                        </div>
                                    </ListGroupItem>
                                </div>       
                            </span>                    
                        )}             
                    </Draggable>
                    
                )}
            </Fragment>
        ); 

        return(
            <Col xs={12} md={12} lg={12} 
                className='sorted-question-box'
            >         
                <DragDropContext
                    onDragEnd={this.onDragEnd}
                >       
                    <Droppable droppableId="answers-list">
                        {(provided, snapshot) => (
                            <div 
                                className={`answer-list ${ snapshot.isDraggingOver ? 'highlighted' : ''}`}
                                ref={provided.innerRef}
                            >              
                                <ListGroup>
                                    {answers}
                                </ListGroup>
                            </div>                                   
                        )}                    
                    </Droppable>
                </DragDropContext>                                                    
            </Col>
        );

    }
}

export default SortedQuestion;