export const allport_instructions_title = "Instrucciones Generales";
export const allport_instructions_practice_title = "Instrucciones Generales (Práctica)";
export const allport_instructions = "Este cuestionario consta de dos series de preguntas, cada una con sus propias instrucciones. Lee las instrucciones y comienza a responder cada grupo. En las siguientes pantallas se mostrarán las instrucciones específicas de cada una de las series."
export const allport_practice_instructions = "Este cuestionario consta de dos series de preguntas, cada una con sus propias instrucciones. Lee las instrucciones y comienza a responder cada grupo. En las siguientes pantallas se mostrarán las instrucciones específicas de cada una de las series.";


export const allport_practice = [
    {   
        title: "Práctica: Sección 1",
        testType: 6,
        time: 0,
        questionsPerPage: 1,
        instructions: "<p>Se presentan en esta sección varias preguntas o afirmaciones, cada una seguida de dos respuestas alternativas.</p><p>En cada pregunta debe seleccionar sólo una de las cuatro opciones presentadas de acuerdo a sus preferencias personales.</p>",        
        questions: 
        [
            {
                "id_prp": "01",
                "id_pruebas": 3,
                "tipo": 0,
                "orden": "1",
                "texto": "<b>¿Cuál destino vacacional le gustaría para el próximo invierno?<br><br>a) Ancorage, Alaska<br><br>b) Cancún, México</b>",
                "answers": [
                    {   
                        "id_prr": "01",
                        "id_prp": "01",
                        "orden": "01",
                        "valor": "30",
                        "texto": 'Estoy de acuerdo con la alternativa "a" y en desacuerdo con la "b"'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "01",
                        "orden": "02",
                        "valor": "21",
                        "texto": 'Tengo una ligera preferencia por la alternativa "a" sobre la "b"'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "01",
                        "orden": "03",
                        "valor": "12",
                        "texto": 'Tengo una ligera preferencia por la alternativa "b" sobre la "a"'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "01",
                        "orden": "04",
                        "valor": "03",
                        "texto": 'En desacuerdo con la "a" y de acuerdo con la "b"'
                    }
                ]
            },
            {
                "id_prp": "02",
                "id_pruebas": 3,
                "tipo": 0,
                "orden": "1",
                "texto": "<b>Cuando voy al campo prefiero:<br><br>a) Capturar insectos extraños<br><br>b) Recolectar flores de colores</b>",
                "answers": [
                    {   
                        "id_prr": "01",
                        "id_prp": "02",
                        "orden": "01",
                        "valor": "30",
                        "texto": 'Estoy de acuerdo con la alternativa "a" y en desacuerdo con la "b"'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "02",
                        "orden": "02",
                        "valor": "21",
                        "texto": 'Tengo una ligera preferencia por la alternativa "a" sobre la "b"'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "02",
                        "orden": "03",
                        "valor": "12",
                        "texto": 'Tengo una ligera preferencia por la alternativa "b" sobre la "a"'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "02",
                        "orden": "04",
                        "valor": "03",
                        "texto": 'En desacuerdo con la "a" y de acuerdo con la "b"'
                    }
                ]
            },
            {
                "id_prp": "03",
                "id_pruebas": 3,
                "tipo": 0,
                "orden": "1",
                "texto": "<b>¿Al hospedarme en un hotel muy alto yo preferiría?<br><br>a) El piso No. 30<br><br>b) El primer piso</b>",               
                "answers": [
                    {   
                        "id_prr": "01",
                        "id_prp": "03",
                        "orden": "01",
                        "valor": "30",
                        "texto": 'Estoy de acuerdo con la alternativa "a" y en desacuerdo con la "b"'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "03",
                        "orden": "02",
                        "valor": "21",
                        "texto": 'Tengo una ligera preferencia por la alternativa "a" sobre la "b"'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "03",
                        "orden": "03",
                        "valor": "12",
                        "texto": 'Tengo una ligera preferencia por la alternativa "b" sobre la "a"'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "03",
                        "orden": "04",
                        "valor": "03",
                        "texto": 'En desacuerdo con la "a" y de acuerdo con la "b"'
                    }
                ]
            }
        ]
    },
    {
        title: "Práctica: Sección 2",
        testType: "3",
        time: 0,
        questionsPerPage: 1,
        instructions: "<p>En esta segunda sección se presentarán varias situaciones, cada una de ellas seguida de cuatro actitudes o contestaciones posibles.</p><p>Usted debe acomodar las respuestas en el orden de su preferencia personal de tal manera que ARRIBA quede la opción con la que usted MAS se identifica y así subsecuentemente hasta que ABAJO quede la opción que MENOS le parece.</p><p>Para ordenar las respuestas presione sobre el botón '+' para subir una de las opciones, para bajarla presione el botón '-'.</p><p>Al tener las respuestas ya ordenadas de acuerdo a su preferencia, presione el botón [Aceptar] para continuar con la siguente pregunta.",
        questions: 
        [
            {
                "id_prp": "01",
                "id_pruebas": 5,
                "tipo": 0,        
                "orden": "1",
                "texto": "¿Al estar usted limpiando una lámpara maravillosa se le aparece un genio y le ofrece unos regalos. ¿En qué orden los seleccionaría?",
                "answers": [
                    {
                        "id_prr": "01",
                        "id_prp": "01",
                        "orden": "01",
                        "valor": "A",
                        "texto": "Fama"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "01",
                        "orden": "02",
                        "valor": "B",
                        "texto": "Dinero"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "01",
                        "orden": "03",
                        "valor": "C",
                        "texto": "Amor"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "01",
                        "orden": "04",
                        "valor": "D",
                        "texto": "Salud"
                    }
                ]
            },
            {
                
                "id_prp": "02",
                "id_pruebas": 5,
                "tipo": 0,        
                "orden": "2",
                "texto": "Usted se encuentra un bolso con dinero equivalente a un mes de su sueldo, pero también contiene papelería importante en la que se indica el nombre, domicilio y teléfono del propietario del bolso, ¿Que haría con el bolso?",
                "answers": [
                    {
                        "id_prr": "01",
                        "id_prp": "02",
                        "orden": "01",
                        "valor": "A",
                        "texto": "Regreso el bolso sin dinero"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "02",
                        "orden": "02",
                        "valor": "B",
                        "texto": "Me quedo con el dinero y descarto el bolso"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "02",
                        "orden": "03",
                        "valor": "C",
                        "texto": "Regreso el bolso con una parte del dinero y me quedo con otra parte como recompenza"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "02",
                        "orden": "04",
                        "valor": "D",
                        "texto": "Regreso el bolso tal cual como me lo encontre"
                    }
                ]
            },
            {
                "id_prp": "03",
                "id_pruebas": 5,
                "tipo": 0,        
                "orden": "3",
                "texto": "Un amigo le da la opción de regalarle 2 boletos para usted y su pareja, ¿Que tipo de espectáculo seleccionaría?",
                "answers": [
                    {
                        "id_prr": "01",
                        "id_prp": "03",
                        "orden": "01",
                        "valor": "A",
                        "texto": "Concierto de rock pesado"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "03",
                        "orden": "02",
                        "valor": "B",
                        "texto": "Opera clásica"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "03",
                        "orden": "03",
                        "valor": "C",
                        "texto": "Un festival de baile folklórico"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "03",
                        "orden": "04",
                        "valor": "D",
                        "texto": "Danza contemporánea"
                    }
                ]
            } 
        ]
    }
]

export const allport = [
    {   
        title: "Serie I",
        testType: 6,
        time: 0,
        questionsPerPage: 1,
        instructions: "<p>Se presentan en esta sección varias preguntas o afirmaciones, cada una seguida de dos respuestas alternativas.</p><p>En cada pregunta debe seleccionar sólo una de las cuatro opciones presentadas de acuerdo a sus preferencias personales.</p>",        
        questions: 
        [
            {
                "id_prp": "01",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "1",
                "texto": "<b>¿El objetivo de las investigaciones científicas debe ser la búsqueda de la verdad, más que su aplicación práctica?<br><br>a) Si<br><br>b) No</b>",
                "answers": [
                    {   
                        "id_prr": "01",
                        "id_prp": "01",
                        "orden": "01",
                        "valor": "30",
                        "texto": 'Estoy de acuerdo con la alternativa "a" y en desacuerdo con la "b"'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "01",
                        "orden": "02",
                        "valor": "21",
                        "texto": 'Tengo una ligera preferencia por la alternativa "a" sobre la "b"'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "01",
                        "orden": "03",
                        "valor": "12",
                        "texto": 'Tengo una ligera preferencia por la alternativa "b" sobre la "a"'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "01",
                        "orden": "04",
                        "valor": "03",
                        "texto": 'En desacuerdo con la "a" y de acuerdo con la "b"'
                    }
                ]
            },
            {
                "id_prp": "02",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "1",
                "texto": "<b>¿Uno debería juzgar la Biblia desde el punto de vista de su hermosa mitología y estilo literario más bien que por sus revelaciones espirituales?<br><br>a) Si<br><br>b) No</b>",
                "answers": [
                    {   
                        "id_prr": "01",
                        "id_prp": "02",
                        "orden": "01",
                        "valor": "30",
                        "texto": 'Estoy de acuerdo con la alternativa "a" y en desacuerdo con la "b"'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "02",
                        "orden": "02",
                        "valor": "21",
                        "texto": 'Tengo una ligera preferencia por la alternativa "a" sobre la "b"'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "02",
                        "orden": "03",
                        "valor": "12",
                        "texto": 'Tengo una ligera preferencia por la alternativa "b" sobre la "a"'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "02",
                        "orden": "04",
                        "valor": "03",
                        "texto": 'En desacuerdo con la "a" y de acuerdo con la "b"'
                    }
                ]
            },
            {
                "id_prp": "03",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "1",
                "texto": "<b>¿Cuál de los siguientes hombres cree usted que ha contribuido más al progreso de la humanidad?<br><br>a) Aristóteles<br><br>b) Abraham Lincoln</b>",               
                "answers": [
                    {   
                        "id_prr": "01",
                        "id_prp": "03",
                        "orden": "01",
                        "valor": "30",
                        "texto": 'Estoy de acuerdo con la alternativa "a" y en desacuerdo con la "b"'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "03",
                        "orden": "02",
                        "valor": "21",
                        "texto": 'Tengo una ligera preferencia por la alternativa "a" sobre la "b"'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "03",
                        "orden": "03",
                        "valor": "12",
                        "texto": 'Tengo una ligera preferencia por la alternativa "b" sobre la "a"'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "03",
                        "orden": "04",
                        "valor": "03",
                        "texto": 'En desacuerdo con la "a" y de acuerdo con la "b"'
                    }
                ]
            },
            {
                "id_prp": "04",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "1",
                "texto": "<b>Si tuviera bastante capacidad, ¿Qué preferiría ser?<br><br>a) Banquero<br><br>b) Político</b>",               
                "answers": [
                    {   
                        "id_prr": "01",
                        "id_prp": "04",
                        "orden": "01",
                        "valor": "30",
                        "texto": 'Estoy de acuerdo con la alternativa "a" y en desacuerdo con la "b"'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "04",
                        "orden": "02",
                        "valor": "21",
                        "texto": 'Tengo una ligera preferencia por la alternativa "a" sobre la "b"'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "04",
                        "orden": "04",
                        "valor": "12",
                        "texto": 'Tengo una ligera preferencia por la alternativa "b" sobre la "a"'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "04",
                        "orden": "04",
                        "valor": "03",
                        "texto": 'En desacuerdo con la "a" y de acuerdo con la "b"'
                    }
                ]
            },
            {
                "id_prp": "05",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "1",
                "texto": "<b>¿Cree justo que grandes artistas como Beethoven, Wagner y Byron sean egoístas y no les  importen los sentimientos de los demás?<br><br>a) Si<br><br>b) No</b>",               
                "answers": [
                    {   
                        "id_prr": "01",
                        "id_prp": "05",
                        "orden": "01",
                        "valor": "30",
                        "texto": 'Estoy de acuerdo con la alternativa "a" y en desacuerdo con la "b"'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "05",
                        "orden": "02",
                        "valor": "21",
                        "texto": 'Tengo una ligera preferencia por la alternativa "a" sobre la "b"'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "05",
                        "orden": "04",
                        "valor": "12",
                        "texto": 'Tengo una ligera preferencia por la alternativa "b" sobre la "a"'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "05",
                        "orden": "04",
                        "valor": "03",
                        "texto": 'En desacuerdo con la "a" y de acuerdo con la "b"'
                    }
                ]
            },
            {
                "id_prp": "06",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "1",
                "texto": "<b>¿Cuál de las siguientes disciplinas probará con el tiempo ser más importante a la humanidad?<br><br>a) Matemáticas<br><br>b) Teología</b>",               
                "answers": [
                    {   
                        "id_prr": "01",
                        "id_prp": "06",
                        "orden": "01",
                        "valor": "30",
                        "texto": 'Estoy de acuerdo con la alternativa "a" y en desacuerdo con la "b"'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "06",
                        "orden": "02",
                        "valor": "21",
                        "texto": 'Tengo una ligera preferencia por la alternativa "a" sobre la "b"'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "06",
                        "orden": "04",
                        "valor": "12",
                        "texto": 'Tengo una ligera preferencia por la alternativa "b" sobre la "a"'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "06",
                        "orden": "04",
                        "valor": "03",
                        "texto": 'En desacuerdo con la "a" y de acuerdo con la "b"'
                    }
                ]
            },
            {
                "id_prp": "07",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "1",
                "texto": "<b>¿Qué función considera más importante en los dirigentes modernos?<br><br>a) Consumar fines prácticos<br><br>b) Fomentar en sus colaboradores mayor interés en los derechos del hombre</b>",               
                "answers": [
                    {   
                        "id_prr": "01",
                        "id_prp": "07",
                        "orden": "01",
                        "valor": "30",
                        "texto": 'Estoy de acuerdo con la alternativa "a" y en desacuerdo con la "b"'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "07",
                        "orden": "02",
                        "valor": "21",
                        "texto": 'Tengo una ligera preferencia por la alternativa "a" sobre la "b"'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "07",
                        "orden": "04",
                        "valor": "12",
                        "texto": 'Tengo una ligera preferencia por la alternativa "b" sobre la "a"'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "07",
                        "orden": "04",
                        "valor": "03",
                        "texto": 'En desacuerdo con la "a" y de acuerdo con la "b"'
                    }
                ]
            },
            {
                "id_prp": "08",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "8",
                "texto": "<b>Cuando usted asiste a una ceremonia solemne (religiosa, académica o toma de posesión), ¿Qué le impresiona más?<br><br>a) El colorido y la pompa de la ocasión misma<br><br>b) La fuerza e influencia del grupo</b>",               
                "answers": [
                    {   
                        "id_prr": "01",
                        "id_prp": "08",
                        "orden": "01",
                        "valor": "30",
                        "texto": 'Estoy de acuerdo con la alternativa "a" y en desacuerdo con la "b"'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "08",
                        "orden": "02",
                        "valor": "21",
                        "texto": 'Tengo una ligera preferencia por la alternativa "a" sobre la "b"'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "08",
                        "orden": "04",
                        "valor": "12",
                        "texto": 'Tengo una ligera preferencia por la alternativa "b" sobre la "a"'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "08",
                        "orden": "04",
                        "valor": "03",
                        "texto": 'En desacuerdo con la "a" y de acuerdo con la "b"'
                    }
                ]
            },
            {
                "id_prp": "09",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "9",
                "texto": "<b>¿Cuál de estos rasgos de carácter considera usted más deseable?<br><br>a) Compañerismo y comprensión<br><br>b) Caridad y altos ideales</b>",               
                "answers": [
                    {   
                        "id_prr": "01",
                        "id_prp": "09",
                        "orden": "01",
                        "valor": "30",
                        "texto": 'Estoy de acuerdo con la alternativa "a" y en desacuerdo con la "b"'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "09",
                        "orden": "02",
                        "valor": "21",
                        "texto": 'Tengo una ligera preferencia por la alternativa "a" sobre la "b"'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "09",
                        "orden": "04",
                        "valor": "12",
                        "texto": 'Tengo una ligera preferencia por la alternativa "b" sobre la "a"'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "09",
                        "orden": "04",
                        "valor": "03",
                        "texto": 'En desacuerdo con la "a" y de acuerdo con la "b"'
                    }
                ]
            },
            {
                "id_prp": "10",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "9",
                "texto": "<b>Si fuera profesor y tuviera los conocimientos necesarios, ¿Qué preferiría ensañar?<br><br>a) Física y química<br><br>b) Poesía</b>",               
                "answers": [
                    {   
                        "id_prr": "01",
                        "id_prp": "10",
                        "orden": "01",
                        "valor": "30",
                        "texto": 'Estoy de acuerdo con la alternativa "a" y en desacuerdo con la "b"'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "10",
                        "orden": "02",
                        "valor": "21",
                        "texto": 'Tengo una ligera preferencia por la alternativa "a" sobre la "b"'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "10",
                        "orden": "04",
                        "valor": "12",
                        "texto": 'Tengo una ligera preferencia por la alternativa "b" sobre la "a"'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "10",
                        "orden": "04",
                        "valor": "03",
                        "texto": 'En desacuerdo con la "a" y de acuerdo con la "b"'
                    }
                ]
            },
            {
                "id_prp": "11",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "9",
                "texto": "<b>Si viera las siguientes noticias con encabezados de igual tamaño en el periódico de la mañana, ¿Cuál leería con mayor atención?<br><br>a) Grandes mejoras en las condiciones mercantiles<br><br>b) Los dirigentes religiosos estudiarán la reconciliación</b>",               
                "answers": [
                    {   
                        "id_prr": "01",
                        "id_prp": "11",
                        "orden": "01",
                        "valor": "30",
                        "texto": 'Estoy de acuerdo con la alternativa "a" y en desacuerdo con la "b"'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "11",
                        "orden": "02",
                        "valor": "21",
                        "texto": 'Tengo una ligera preferencia por la alternativa "a" sobre la "b"'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "11",
                        "orden": "04",
                        "valor": "12",
                        "texto": 'Tengo una ligera preferencia por la alternativa "b" sobre la "a"'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "11",
                        "orden": "04",
                        "valor": "03",
                        "texto": 'En desacuerdo con la "a" y de acuerdo con la "b"'
                    }
                ]
            },
            {
                "id_prp": "12",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "9",
                "texto": "<b>En situaciones semejantes de la pregunta anterior, ¿Cuál le interesaría más?<br><br>a) Se anuncia una nueva teoría científica<br><br>b) Se alaban los beneficios de las dictaduras</b>",               
                "answers": [
                    {   
                        "id_prr": "01",
                        "id_prp": "12",
                        "orden": "01",
                        "valor": "30",
                        "texto": 'Estoy de acuerdo con la alternativa "a" y en desacuerdo con la "b"'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "12",
                        "orden": "02",
                        "valor": "21",
                        "texto": 'Tengo una ligera preferencia por la alternativa "a" sobre la "b"'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "12",
                        "orden": "04",
                        "valor": "12",
                        "texto": 'Tengo una ligera preferencia por la alternativa "b" sobre la "a"'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "12",
                        "orden": "04",
                        "valor": "03",
                        "texto": 'En desacuerdo con la "a" y de acuerdo con la "b"'
                    }
                ]
            },
            {
                "id_prp": "13",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "9",
                "texto": "<b>¿Qué le interesa más cuando visita una catedral por primera vez?<br><br>a) Los detalles de la arquitectura<br><br>b) El sentido penetrante de reverencia y culto</b>",               
                "answers": [
                    {   
                        "id_prr": "01",
                        "id_prp": "13",
                        "orden": "01",
                        "valor": "30",
                        "texto": 'Estoy de acuerdo con la alternativa "a" y en desacuerdo con la "b"'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "13",
                        "orden": "02",
                        "valor": "21",
                        "texto": 'Tengo una ligera preferencia por la alternativa "a" sobre la "b"'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "13",
                        "orden": "04",
                        "valor": "12",
                        "texto": 'Tengo una ligera preferencia por la alternativa "b" sobre la "a"'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "13",
                        "orden": "04",
                        "valor": "03",
                        "texto": 'En desacuerdo con la "a" y de acuerdo con la "b"'
                    }
                ]
            },
            {
                "id_prp": "14",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "9",
                "texto": "<b>Si tuviera suficiente tiempo libre, ¿Cómo lo emplearía?<br><br>a) Trabajando como voluntario en beneficio de la sociedad<br><br>b) Cultivando su capacidad para dirigir a los demás</b>",               
                "answers": [
                    {   
                        "id_prr": "01",
                        "id_prp": "14",
                        "orden": "01",
                        "valor": "30",
                        "texto": 'Estoy de acuerdo con la alternativa "a" y en desacuerdo con la "b"'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "14",
                        "orden": "02",
                        "valor": "21",
                        "texto": 'Tengo una ligera preferencia por la alternativa "a" sobre la "b"'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "14",
                        "orden": "04",
                        "valor": "12",
                        "texto": 'Tengo una ligera preferencia por la alternativa "b" sobre la "a"'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "14",
                        "orden": "04",
                        "valor": "03",
                        "texto": 'En desacuerdo con la "a" y de acuerdo con la "b"'
                    }
                ]
            },
            {
                "id_prp": "15",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "9",
                "texto": "<b>En una exposición usted preferiría ver:<br><br>a) Aparatos científicos<br><br>b) Nuevos productos manufacturados</b>",               
                "answers": [
                    {   
                        "id_prr": "01",
                        "id_prp": "15",
                        "orden": "01",
                        "valor": "30",
                        "texto": 'Estoy de acuerdo con la alternativa "a" y en desacuerdo con la "b"'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "15",
                        "orden": "02",
                        "valor": "21",
                        "texto": 'Tengo una ligera preferencia por la alternativa "a" sobre la "b"'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "15",
                        "orden": "04",
                        "valor": "12",
                        "texto": 'Tengo una ligera preferencia por la alternativa "b" sobre la "a"'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "15",
                        "orden": "04",
                        "valor": "03",
                        "texto": 'En desacuerdo con la "a" y de acuerdo con la "b"'
                    }
                ]
            },
            {
                "id_prp": "16",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "9",
                "texto": "<b>Si tuviera la oportunidad, y si nada por el estilo existiera en la comunidad que usted vive, ¿Qué preferiría fundar?<br><br>a) Una orquesta clásica<br><br>b) Una sociedad de debates, foro o tribuna</b>",               
                "answers": [
                    {   
                        "id_prr": "01",
                        "id_prp": "16",
                        "orden": "01",
                        "valor": "30",
                        "texto": 'Estoy de acuerdo con la alternativa "a" y en desacuerdo con la "b"'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "16",
                        "orden": "02",
                        "valor": "21",
                        "texto": 'Tengo una ligera preferencia por la alternativa "a" sobre la "b"'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "16",
                        "orden": "04",
                        "valor": "12",
                        "texto": 'Tengo una ligera preferencia por la alternativa "b" sobre la "a"'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "16",
                        "orden": "04",
                        "valor": "03",
                        "texto": 'En desacuerdo con la "a" y de acuerdo con la "b"'
                    }
                ]
            },
            {
                "id_prp": "17",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "9",
                "texto": "<b>El fin de las iglesias en los tiempos presentes debería ser:<br><br>a) Preparar a los feligreses para desarrollar actividades de ayuda social<br><br>b) Alentar el culto espiritual y un sentido de comunión con Dios</b>",               
                "answers": [
                    {   
                        "id_prr": "01",
                        "id_prp": "17",
                        "orden": "01",
                        "valor": "30",
                        "texto": 'Estoy de acuerdo con la alternativa "a" y en desacuerdo con la "b"'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "17",
                        "orden": "02",
                        "valor": "21",
                        "texto": 'Tengo una ligera preferencia por la alternativa "a" sobre la "b"'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "17",
                        "orden": "04",
                        "valor": "12",
                        "texto": 'Tengo una ligera preferencia por la alternativa "b" sobre la "a"'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "17",
                        "orden": "04",
                        "valor": "03",
                        "texto": 'En desacuerdo con la "a" y de acuerdo con la "b"'
                    }
                ]
            },
            {
                "id_prp": "18",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "9",
                "texto": "<b>Si tuviera que permanecer en una sala de espera en la que sólo hubiera dos revistas, ¿Cuál preferiría?<br><br>a) La era científica<br><br>b) Arte moderno</b>",               
                "answers": [
                    {   
                        "id_prr": "01",
                        "id_prp": "18",
                        "orden": "01",
                        "valor": "30",
                        "texto": 'Estoy de acuerdo con la alternativa "a" y en desacuerdo con la "b"'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "18",
                        "orden": "02",
                        "valor": "21",
                        "texto": 'Tengo una ligera preferencia por la alternativa "a" sobre la "b"'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "18",
                        "orden": "04",
                        "valor": "12",
                        "texto": 'Tengo una ligera preferencia por la alternativa "b" sobre la "a"'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "18",
                        "orden": "04",
                        "valor": "03",
                        "texto": 'En desacuerdo con la "a" y de acuerdo con la "b"'
                    }
                ]
            },
            {
                "id_prp": "19",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "9",
                "texto": "<b>¿Sobre qué preferiría escuchar una serie de conferencias?<br><br>a) Comparación entre las formas de gobierno de la Unión Soviética y de los Estados Unidos<br><br>b) La historia comparada en las grandes religiones</b>",               
                "answers": [
                    {   
                        "id_prr": "01",
                        "id_prp": "19",
                        "orden": "01",
                        "valor": "30",
                        "texto": 'Estoy de acuerdo con la alternativa "a" y en desacuerdo con la "b"'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "19",
                        "orden": "02",
                        "valor": "21",
                        "texto": 'Tengo una ligera preferencia por la alternativa "a" sobre la "b"'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "19",
                        "orden": "04",
                        "valor": "12",
                        "texto": 'Tengo una ligera preferencia por la alternativa "b" sobre la "a"'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "19",
                        "orden": "04",
                        "valor": "03",
                        "texto": 'En desacuerdo con la "a" y de acuerdo con la "b"'
                    }
                ]
            },
            {
                "id_prp": "20",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "9",
                "texto": "<b>¿Qué considera más importante en la educación?<br><br>a) La preparación para una actividad práctica y para ocupaciones remuneradas<br><br>b) La preparación para actividades cívicas y de bienestar social</b>",               
                "answers": [
                    {   
                        "id_prr": "01",
                        "id_prp": "20",
                        "orden": "01",
                        "valor": "30",
                        "texto": 'Estoy de acuerdo con la alternativa "a" y en desacuerdo con la "b"'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "20",
                        "orden": "02",
                        "valor": "21",
                        "texto": 'Tengo una ligera preferencia por la alternativa "a" sobre la "b"'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "20",
                        "orden": "04",
                        "valor": "12",
                        "texto": 'Tengo una ligera preferencia por la alternativa "b" sobre la "a"'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "20",
                        "orden": "04",
                        "valor": "03",
                        "texto": 'En desacuerdo con la "a" y de acuerdo con la "b"'
                    }
                ]
            },
            {
                "id_prp": "21",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "9",
                "texto": "<b>Si fuera a leer una biografía, ¿Qué personajes preferiría?<br><br>a) Aristóteles, Socrates, Kant<br><br>b) Julio César, Napoleón, Hitler</b>",               
                "answers": [
                    {   
                        "id_prr": "01",
                        "id_prp": "21",
                        "orden": "01",
                        "valor": "30",
                        "texto": 'Estoy de acuerdo con la alternativa "a" y en desacuerdo con la "b"'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "21",
                        "orden": "02",
                        "valor": "21",
                        "texto": 'Tengo una ligera preferencia por la alternativa "a" sobre la "b"'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "21",
                        "orden": "04",
                        "valor": "12",
                        "texto": 'Tengo una ligera preferencia por la alternativa "b" sobre la "a"'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "21",
                        "orden": "04",
                        "valor": "03",
                        "texto": 'En desacuerdo con la "a" y de acuerdo con la "b"'
                    }
                ]
            },
            {
                "id_prp": "22",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "9",
                "texto": "<b>Si fueran a regalarle un libro y tuviera la oportunidad de elegir, ¿Cuál escogería?<br><br>a) El problema económico de México<br><br>b) El arte popular mexicano</b>",               
                "answers": [
                    {   
                        "id_prr": "01",
                        "id_prp": "22",
                        "orden": "01",
                        "valor": "30",
                        "texto": 'Estoy de acuerdo con la alternativa "a" y en desacuerdo con la "b"'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "22",
                        "orden": "02",
                        "valor": "21",
                        "texto": 'Tengo una ligera preferencia por la alternativa "a" sobre la "b"'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "22",
                        "orden": "04",
                        "valor": "12",
                        "texto": 'Tengo una ligera preferencia por la alternativa "b" sobre la "a"'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "22",
                        "orden": "04",
                        "valor": "03",
                        "texto": 'En desacuerdo con la "a" y de acuerdo con la "b"'
                    }
                ]
            },
            {
                "id_prp": "23",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "9",
                "texto": "<b>Si trabajara en una organización industrial y los sueldos fueran iguales, ¿Qué preferiría?<br><br>a) Ser consejero de empleados<br><br>b) Ocupar un puesto administrativo</b>",               
                "answers": [
                    {   
                        "id_prr": "01",
                        "id_prp": "23",
                        "orden": "01",
                        "valor": "30",
                        "texto": 'Estoy de acuerdo con la alternativa "a" y en desacuerdo con la "b"'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "23",
                        "orden": "02",
                        "valor": "21",
                        "texto": 'Tengo una ligera preferencia por la alternativa "a" sobre la "b"'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "23",
                        "orden": "04",
                        "valor": "12",
                        "texto": 'Tengo una ligera preferencia por la alternativa "b" sobre la "a"'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "23",
                        "orden": "04",
                        "valor": "03",
                        "texto": 'En desacuerdo con la "a" y de acuerdo con la "b"'
                    }
                ]
            },
            {
                "id_prp": "24",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "9",
                "texto": "<b>¿Qué libro preferiría leer?<br><br>a) La historia de la industria en América<br><br>b) La historia de la religión en América</b>",               
                "answers": [
                    {   
                        "id_prr": "01",
                        "id_prp": "24",
                        "orden": "01",
                        "valor": "30",
                        "texto": 'Estoy de acuerdo con la alternativa "a" y en desacuerdo con la "b"'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "24",
                        "orden": "02",
                        "valor": "21",
                        "texto": 'Tengo una ligera preferencia por la alternativa "a" sobre la "b"'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "24",
                        "orden": "04",
                        "valor": "12",
                        "texto": 'Tengo una ligera preferencia por la alternativa "b" sobre la "a"'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "24",
                        "orden": "04",
                        "valor": "03",
                        "texto": 'En desacuerdo con la "a" y de acuerdo con la "b"'
                    }
                ]
            },
            {
                "id_prp": "25",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "9",
                "texto": "<b>¿Que convendría más a la sociedad moderna?<br><br>a) Conocer las leyes fundamentales de la conducta humana<br><br>b) Respetar los derechos y bienestar de los ciudadanos</b>",               
                "answers": [
                    {   
                        "id_prr": "01",
                        "id_prp": "25",
                        "orden": "01",
                        "valor": "30",
                        "texto": 'Estoy de acuerdo con la alternativa "a" y en desacuerdo con la "b"'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "25",
                        "orden": "02",
                        "valor": "21",
                        "texto": 'Tengo una ligera preferencia por la alternativa "a" sobre la "b"'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "25",
                        "orden": "04",
                        "valor": "12",
                        "texto": 'Tengo una ligera preferencia por la alternativa "b" sobre la "a"'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "25",
                        "orden": "04",
                        "valor": "03",
                        "texto": 'En desacuerdo con la "a" y de acuerdo con la "b"'
                    }
                ]
            },
            {
                "id_prp": "26",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "9",
                "texto": "<b>Si estuviera en una posición adecuada, ¿Qué preferiría hacer?<br><br>a) Mejorar el nivel económico de la comunidad<br><br>b) Encausar la opinión pública</b>",               
                "answers": [
                    {   
                        "id_prr": "01",
                        "id_prp": "26",
                        "orden": "01",
                        "valor": "30",
                        "texto": 'Estoy de acuerdo con la alternativa "a" y en desacuerdo con la "b"'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "26",
                        "orden": "02",
                        "valor": "21",
                        "texto": 'Tengo una ligera preferencia por la alternativa "a" sobre la "b"'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "26",
                        "orden": "04",
                        "valor": "12",
                        "texto": 'Tengo una ligera preferencia por la alternativa "b" sobre la "a"'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "26",
                        "orden": "04",
                        "valor": "03",
                        "texto": 'En desacuerdo con la "a" y de acuerdo con la "b"'
                    }
                ]
            },
            {
                "id_prp": "27",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "9",
                "texto": "<b>¿Qué conferencia le interesaría más?<br><br>a) Pintores contemporáneos<br><br>b) El progreso del servicio social de la comunidad donde usted vive</b>",               
                "answers": [
                    {   
                        "id_prr": "01",
                        "id_prp": "27",
                        "orden": "01",
                        "valor": "30",
                        "texto": 'Estoy de acuerdo con la alternativa "a" y en desacuerdo con la "b"'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "27",
                        "orden": "02",
                        "valor": "21",
                        "texto": 'Tengo una ligera preferencia por la alternativa "a" sobre la "b"'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "27",
                        "orden": "04",
                        "valor": "12",
                        "texto": 'Tengo una ligera preferencia por la alternativa "b" sobre la "a"'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "27",
                        "orden": "04",
                        "valor": "03",
                        "texto": 'En desacuerdo con la "a" y de acuerdo con la "b"'
                    }
                ]
            },
            {
                "id_prp": "28",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "9",
                "texto": "<b>En una comunidad de civilización inferior, ¿Qué considera que sería más necesario?<br><br>a) La formación cultural<br><br>b) La formación religiosa</b>",               
                "answers": [
                    {   
                        "id_prr": "01",
                        "id_prp": "28",
                        "orden": "01",
                        "valor": "30",
                        "texto": 'Estoy de acuerdo con la alternativa "a" y en desacuerdo con la "b"'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "28",
                        "orden": "02",
                        "valor": "28",
                        "texto": 'Tengo una ligera preferencia por la alternativa "a" sobre la "b"'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "28",
                        "orden": "04",
                        "valor": "12",
                        "texto": 'Tengo una ligera preferencia por la alternativa "b" sobre la "a"'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "28",
                        "orden": "04",
                        "valor": "03",
                        "texto": 'En desacuerdo con la "a" y de acuerdo con la "b"'
                    }
                ]
            },
            {
                "id_prp": "29",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "9",
                "texto": "<b>¿Qué preferiría leer en un periódico?<br><br>a) La sección financiera<br><br>b) La crónica sobre exposiciones de arte</b>",               
                "answers": [
                    {   
                        "id_prr": "01",
                        "id_prp": "29",
                        "orden": "01",
                        "valor": "30",
                        "texto": 'Estoy de acuerdo con la alternativa "a" y en desacuerdo con la "b"'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "29",
                        "orden": "02",
                        "valor": "28",
                        "texto": 'Tengo una ligera preferencia por la alternativa "a" sobre la "b"'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "29",
                        "orden": "04",
                        "valor": "12",
                        "texto": 'Tengo una ligera preferencia por la alternativa "b" sobre la "a"'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "29",
                        "orden": "04",
                        "valor": "03",
                        "texto": 'En desacuerdo con la "a" y de acuerdo con la "b"'
                    }
                ]
            },
            {
                "id_prp": "30",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "9",
                "texto": "<b>¿Qué consideraría más importante en la educación de su hijo?<br><br>a) El atletismo<br><br>b) La religión</b>",               
                "answers": [
                    {   
                        "id_prr": "01",
                        "id_prp": "30",
                        "orden": "01",
                        "valor": "30",
                        "texto": 'Estoy de acuerdo con la alternativa "a" y en desacuerdo con la "b"'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "30",
                        "orden": "02",
                        "valor": "28",
                        "texto": 'Tengo una ligera preferencia por la alternativa "a" sobre la "b"'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "30",
                        "orden": "04",
                        "valor": "12",
                        "texto": 'Tengo una ligera preferencia por la alternativa "b" sobre la "a"'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "30",
                        "orden": "04",
                        "valor": "03",
                        "texto": 'En desacuerdo con la "a" y de acuerdo con la "b"'
                    }
                ]
            },
        ]
    },
    {
        title: "Serie II",
        testType: 3,
        time: 0,
        questionsPerPage: 1,
        instructions: "<p>En esta segunda sección se presentarán varias situaciones, cada una de ellas seguida de cuatro actitudes o contestaciones posibles.</p><p>Usted debe acomodar las respuestas en el orden de su preferencia personal de tal manera que ARRIBA quede la opción con la que usted MAS se identifica y así subsecuentemente hasta que ABAJO quede la opción que MENOS le parece.</p><p>Para ordenar las respuestas presione sobre el botón '+' para subir una de las opciones, para bajarla presione el botón '-'.</p><p>Al tener las respuestas ya ordenadas de acuerdo a su preferencia, presione el botón [Aceptar] para continuar con la siguente pregunta.",
        questions: 
        [            
            {
                "id_prp": "01",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "1",
                "texto": "¿Cuál cree usted que deba ser la meta de un buen gobierno?",  
                "answers": [
                    {
                        "id_prr": "01",
                        "id_prp": "01",
                        "orden": "01",
                        "valor": "A",
                        "texto": "Desarrollar la industria y el comercio"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "01",
                        "orden": "02",
                        "valor": "B",
                        "texto": "Ayudar más a los pobres, los enfermos y los ancianos"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "01",
                        "orden": "03",
                        "valor": "C",
                        "texto": "Establecer una posición de prestigio y respeto entre las naciones"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "01",
                        "orden": "04",
                        "valor": "D",
                        "texto": "Introducir los más altos principios de ética en su organización administrativa"
                    }
                ]
            },
            {
                "id_prp": "02",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "2",
                "texto": "En su opinión, ¿Cuál sería la ocupación dominical para una persona que trabaja toda la semana?",            
                "answers": [
                    {
                        "id_prr": "01",
                        "id_prp": "02",
                        "orden": "01",
                        "valor": "A",
                        "texto": "Educarse a sí mismo leyendo libros serios"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "02",
                        "orden": "02",
                        "valor": "B",
                        "texto": "Ir a un concierto de la sinfónica"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "02",
                        "orden": "03",
                        "valor": "C",
                        "texto": "Tratar de ganar en el golf o en algún encuentro deportivo"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "02",
                        "orden": "04",
                        "valor": "D",
                        "texto": "Cumplir con sus primordiales obligaciones religiosas"
                    }
                ]
            },
            {
                "id_prp": "03",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "3",
                "texto": "Si usted pudiera influenciar el programa educativo de las escuelas, ¿Qué se propondría hacer?",
                "answers": [
                    {
                        "id_prr": "01",
                        "id_prp": "03",
                        "orden": "01",
                        "valor": "A",
                        "texto": "Fomentar el estudio de la filosofía y de las matemáticas"
                    },       
                    {
                        "id_prr": "02",
                        "id_prp": "03",
                        "orden": "02",
                        "valor": "B",
                        "texto": "Aumentar el valor práctico de los cursos"
                    },
                    {    
                        "id_prr": "03",
                        "id_prp": "03",
                        "orden": "03",
                        "valor": "C",
                        "texto": "Fomentar el estudio de la música y de las bellas artes"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "03",
                        "orden": "04",
                        "valor": "D",
                        "texto": "Estimular el estudio de los problemas sociales"
                    }
                ]      
            },
            {                
                "id_prp": "04",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "4",
                "texto": "Prefiere usted una amistad de su propio sexo que",
                "answers": [
                    {
                        "id_prr": "01",
                        "id_prp": "04",
                        "orden": "01",
                        "valor": "A",
                        "texto": "Sea activa, industriosa y tenga una mente práctica"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "04",
                        "orden": "02",
                        "valor": "B", 
                        "texto": "Muestre sensibilidad artística y emotiva"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "04",
                        "orden": "03",
                        "valor": "C",
                        "texto": "Posea cualidades de dirección y que tenga habilidades para organizar"       
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "04",
                        "orden": "04",
                        "valor": "D",
                        "texto": "Se interese seriamente por los problemas éticos y de bienestar espiritual"      
                    }
                ]
            },
            {
                "id_prp": "05",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "5",
                "texto": "Si usted viviera en un pueblo pequeño y tuviera más entradas de las que necesita, ¿Qué preferiría hacer con el dinero?",
                "answers": [
                    {
                        "id_prr": "01",
                        "id_prp": "05",
                        "orden": "01",
                        "valor": "A",
                        "texto": "Darlo para el desarrollo de la investigación de su localidad"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "05",
                        "orden": "02",
                        "valor": "B",
                        "texto": "Hacer producir para asistir o ayudar al desarrollo comercial e industrial"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "05",
                        "orden": "03",
                        "valor": "C",
                        "texto": "Darlo a la sociedad para el bienestar de la familia"
                    },
                    {   
                        "id_prr": "04",
                        "id_prp": "05",
                        "orden": "04",     
                        "valor": "D",
                        "texto": "Ayudar al avance de las actividades de los grupos religiosos de la comunidad"
                    }
                ]
            },
            {
                "id_prp": "06",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "6",
                "texto": "Cuando usted va al teatro, por lo general goza más:",
                "answers": [
                    {
                        "id_prr": "01",
                        "id_prp": "06",
                        "orden": "01",
                        "valor": "A",
                        "texto": "Obras que presentan una tesis a favor de un punto de vista"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "06",
                        "orden": "02",
                        "valor": "B",
                        "texto": "Ballet u otras obras de mucha imaginación"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "06",
                        "orden": "03",
                        "valor": "C",
                        "texto": "Obras que tienen el tema del sufrimiento humano o del amor"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "06",
                        "orden": "04",
                        "valor": "D",
                        "texto": "Con obras que tratan de la vida de los grandes hombres"
                    }
                ]
            },
            {
                "id_prp": "07",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "7",
                "texto": "Suponiendo que usted tuviera la habilidad requerida para cada una de las siguientes ocupaciones (y que con ellas ganara el mismo sueldo), ¿Qué preferiría ser?",
                "answers": [
                    {
                        "id_prr": "01",
                        "id_prp": "07",
                        "orden": "01",
                        "valor": "A",
                        "texto": "Matemático"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "07",
                        "orden": "02",
                        "valor": "B",
                        "texto": "Promotor de ventas"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "07",
                        "orden": "03",
                        "valor": "C",
                        "texto": "Político"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "07",
                        "orden": "04",
                        "valor": "D",
                        "texto": "Sacerdote"
                    }
                ]
            },
            {
                "id_prp": "08",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "8",
                "texto": "Si las condiciones económicas se lo permitieran, usted preferiría:",
                "answers": [
                    {
                        "id_prr": "01",
                        "id_prp": "08",
                        "orden": "01",
                        "valor": "A",
                        "texto": "Establecer un negocio propio"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "08",
                        "orden": "02",
                        "valor": "B",
                        "texto": "Formar una colección de buenas esculturas o pinturas"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "08",
                        "orden": "03",
                        "valor": "C",
                        "texto": "Establecer un centro para el cuidado y educación de los débiles mentales"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "08",
                        "orden": "04",
                        "valor": "D",
                        "texto": "Tratar de llegar a ser un dirigente de su comunidad"
                    }
                ]
            },
            {
                "id_prp": "09",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "9",
                "texto": "En una discusión con amistades íntimas de su propio sexo,  ¿De qué le gustaría hablar?",
                "answers": [
                    {
                        "id_prr": "01",
                        "id_prp": "09",
                        "orden": "01",
                        "valor": "A", 
                        "texto": "Del desarrollo de la ciencia"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "09",
                        "orden": "02",
                        "valor": "B",
                        "texto": "Literatura"
                    },       
                    {
                        "id_prr": "03",
                        "id_prp": "09",
                        "orden": "03",
                        "valor": "C",
                        "texto": "El mejoramiento social"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "09",
                        "orden": "04",
                        "valor": "D",
                        "texto": "El sentido de la vida"
                    }
                ]
            },
            {
                "id_prp": "10",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "10",
                "texto": "¿Cuál de las siguientes actividades preferiría dedicarse durante parte de sus vacaciones (si tuviera la habilidad y las condiciones requeridas)?",
                "answers": [
                    {
                        "id_prr": "01",
                        "id_prp": "10",
                        "orden": "01",
                        "valor": "A",
                        "texto": "Escribir un ensayo o artículo sobre un tema científico"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "10",
                        "orden": "02",
                        "valor": "B",
                        "texto": "Adquirir conocimientos"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "10",
                        "orden": "03",
                        "valor": "C",
                        "texto": "Estarse en un lugar apartado en el campo, donde pueda apreciar las bellezas naturales"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "10",
                        "orden": "04",
                        "valor": "D",
                        "texto": "Obtener experiencia en la dirección de grupos"
                    }
                ]
            },
            {
                "id_prp": "11",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "11",
                "texto": "Le parecen a usted las grandes hazañas y aventuras de descubrimientos tales como los de Colón, Magallanes, etc.  son de gran significado porque:",
                "answers": [
                    {
                        "id_prr": "01",
                        "id_prp": "11",
                        "orden": "01",
                        "valor": "A",
                        "texto": "Aumentan nuestros conocimientos de geografía, meteorología, oceanografía, etc."
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "11",
                        "orden": "02",
                        "valor": "B",
                        "texto": "Unen los intereses humanos y fomentan el espíritu internacional"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "11",
                        "orden": "03",
                        "valor": "C",
                        "texto": "Representan las conquistas del hombre sobre las fuerzas de la naturaleza"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "11",
                        "orden": "04",
                        "valor": "D",
                        "texto": "Demuestran que todos los pueblos, aún los incivilizados son peligrosos"
                    }
                ]
            },
            {
                "id_prp": "12",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "12",
                "texto": "Debería uno guiar su conducta según:",
                "answers": [
                    {
                        "id_prr": "01",
                        "id_prp": "12",
                        "orden": "01",
                        "valor": "A",
                        "texto": "Los provechos personales que uno pueda obtener"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "12",
                        "orden": "02",
                        "valor": "B",
                        "texto": "Los ideales de belleza"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "12",
                        "orden": "03",
                        "valor": "C",
                        "texto": "Los ideales de mutua ayuda"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "12",
                        "orden": "04",
                        "valor": "D",
                        "texto": "Su fe religiosa"
                    }
                ]
            },
            {
                "id_prp": "13",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "13",
                "texto": "¿En qué orden le interesan a usted estos famosos personajes?",
                "answers": [
                    {
                        "id_prr": "01",
                        "id_prp": "13",
                        "orden": "01",
                        "valor": "A",
                        "texto": "Socrates"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "13",
                        "orden": "02",
                        "valor": "B",
                        "texto": "Henry Ford"     
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "13",
                        "orden": "03",
                        "valor": "C",
                        "texto": "Florence Nightingale"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "13",
                        "orden": "04",
                        "valor": "D",
                        "texto": "Napoleón"
                    }
                ]   
            },
            {
                "id_prp": "14",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "14",
                "texto": "Al seleccionar su cónyuge, usted preferiría alguien:",
                "answers": [
                    {
                        "id_prr": "01",
                        "id_prp": "14",
                        "orden": "01",
                        "valor": "A",
                        "texto": "Con dotes artísticos"
                    },
                    {    
                        "id_prr": "02",
                        "id_prp": "14",
                        "orden": "02",
                        "valor": "B",
                        "texto": "Con deseos de ayudar al prójimo"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "14",
                        "orden": "03",
                        "valor": "C",
                        "texto": "Con prestigio social"
                    },
                    {        
                        "id_prr": "04",
                        "id_prp": "14",
                        "orden": "04",
                        "valor": "D",
                        "texto": "Que fuera fundamentalmente espiritual en su actitud frente a la vida"
                    }
                ]
            },
            {
                "id_prp": "15",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "15",
                "texto": "¿Qué considera usted que debe fomentar un país para superarse?",
                "answers": [
                    {
                        "id_prr": "01",
                        "id_prp": "15",
                        "orden": "01",
                        "valor": "A",
                        "texto": "La creación de mayor número de centros de educación superior"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "15",
                        "orden": "02",
                        "valor": "B",
                        "texto": "La explotación de sus riquezas naturales"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "15",
                        "orden": "03",
                        "valor": "C",
                        "texto": "El conocimiento y la comprensión de todas las manifestaciones artísticas"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "15",
                        "orden": "04",
                        "valor": "D",
                        "texto": "La tradición religiosa"
                    }    
                ]
            }
        ]
    }
]