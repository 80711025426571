export const actionTypes = {
	LOGIN_USER : 'LOGIN_USER',
  LOGIN_USER_SUCCESS : 'LOGIN_USER_SUCCESS',
  LOGIN_USER_FAIL : 'LOGIN_USER_FAIL',
  LOGOUT_USER : 'LOGOUT_USER',
  UPDATE_USER: 'UPDATE_USER'
}

export const loginUser = id => ({
  type: actionTypes.LOGIN_USER,
  payload: { id }
});

export const loginUserSuccess = (user) => ({
  type: actionTypes.LOGIN_USER_SUCCESS,
  payload: user
});

export const logoutUser = () => ({
  type: actionTypes.LOGOUT_USER  
});

export const updateUser = user => ({
  type: actionTypes.UPDATE_USER,
  payload: { user }
});