export const cleaver_practice = [
    {
        "id_prp": "01",
        "id_pruebas": 1,
        "tipo": 0,
        "orden": "1",
        "texto": "Grupo 1",
        "answers": [
            {   
                "id_prr": "01",
                "id_prp": "01",
                "orden": "01",
                "valor": "A",
                "texto": "Agradable",
                "tip": "Agradable"
            },
            {
                "id_prr": "02",
                "id_prp": "01",
                "orden": "02",
                "valor": "B",
                "texto": "Determinado",
                "tip": "Decidido para actuar"
            },
            {
                "id_prr": "03",
                "id_prp": "01",
                "orden": "03",
                "valor": "C",
                "texto": "Leal",
                "tip": "Leal, fiel a alguien"
            },
            {
                "id_prr": "04",
                "id_prp": "01",
                "orden": "04",
                "valor": "D",
                "texto": "Entusiasta",
                "tip": "Entusiasta, con emoción"
            }
        ]
    },
    {
        "id_prp": "02",
        "id_pruebas": 1,
        "tipo": 0,
        "orden": "2",
        "texto": "Grupo 2",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "02",
                "orden": "01",
                "valor": "A",
                "texto": "Ecuánime",
                "tip": "Sereno y equilibrado"
            },
            {
                "id_prr": "02",
                "id_prp": "02",
                "orden": "02",
                "valor": "B",
                "texto": "Generoso",
                "tip": "Generoso, da cosas de buena manera"
            },
            {
                "id_prr": "03",
                "id_prp": "02",
                "orden": "03",
                "valor": "C",
                "texto": "Considerado",
                "tip": "Considerado con la gente"
            },
            {
                "id_prr": "04",
                "id_prp": "02",
                "orden": "04",
                "valor": "D",
                "texto": "Carácter firme",
                "tip": "Carácter firme"
            }
        ]
    },
    {
        "id_prp": "03",
        "id_pruebas": 1,
        "tipo": 0,
        "orden": "3",
        "texto": "Grupo 3",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "03",
                "orden": "01",
                "valor": "A",
                "texto": "Valiente",
                "tip": "Valiente"
            },
            {
                "id_prr": "02",
                "id_prp": "03",
                "orden": "02",
                "valor": "B",
                "texto": "Disputador",
                "tip": "Disputador, le gusta discutir"
            },
            {
                "id_prr": "03",
                "id_prp": "03",
                "orden": "03",
                "valor": "C",
                "texto": "Confianza en si mismo",
                "tip": "Seguro de si mismo"
            },
            {
                "id_prr": "04",
                "id_prp": "03",
                "orden": "04",
                "valor": "D",
                "texto": "Positivo",
                "tip": "Positivo, ve el lado bueno de las cosas"
            }
        ]
    },
    {
        "id_prp": "04",
        "id_pruebas": 1,
        "tipo": 0,
        "orden": "4",
        "texto": "Grupo 4",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "04",
                "orden": "01",
                "valor": "A",
                "texto": "Parlanchin",
                "tip": "Platicador"
            },
            {
                "id_prr": "02",
                "id_prp": "04",
                "orden": "02",
                "valor": "B",
                "texto": "Exacto",
                "tip": "Exacto, preciso, puntual"
            },
            {
                "id_prr": "03",
                "id_prp": "04",
                "orden": "03",
                "valor": "C",
                "texto": "Refinado",
                "tip": "Refinado, fino, distinguido"
            },
            {
                "id_prr": "04",
                "id_prp": "04",
                "orden": "04",
                "valor": "D",
                "texto": "Devoto",
                "tip": "Devoto, fervoroso, religioso"
            }
        ]
    }
]

export const cleaver = [
    {
        "id_prp": "01",
        "id_pruebas": 1,
        "tipo": 0,
        "orden": "1",
        "texto": "Grupo 1",
        "answers": [
            {   
                "id_prr": "01",
                "id_prp": "01",
                "orden": "01",
                "valor": "A",
                "texto": "Persuasivo",
                "tip": "Convence a la gente con sus ideas"
            },
            {
                "id_prr": "02",
                "id_prp": "01",
                "orden": "02",
                "valor": "B",
                "texto": "Gentil",
                "tip": "Gentil, amable, gracioso"
            },
            {
                "id_prr": "03",
                "id_prp": "01",
                "orden": "03",
                "valor": "C",
                "texto": "Humilde",
                "tip": "Modesto, sencillo"
            },
            {
                "id_prr": "04",
                "id_prp": "01",
                "orden": "04",
                "valor": "D",
                "texto": "Original",
                "tip": "Diferente a los demás, no imita"
            }
        ]
    },
    {
        "id_prp": "02",
        "id_pruebas": 1,
        "tipo": 0,
        "orden": "2",
        "texto": "Grupo 2",
        "answers": [
            {   
                "id_prr": "01",
                "id_prp": "02",
                "orden": "01",
                "valor": "A",
                "texto": "Agresivo",
                "tip": "Agresivo, que provoca, que empuja"
            },
            {
                "id_prr": "02",
                "id_prp": "02",
                "orden": "02",
                "valor": "B",
                "texto": "Alma de la fiesta",
                "tip": "Alma de la fiesta, que anima"
            },
            {
                "id_prr": "03",
                "id_prp": "02",
                "orden": "03",
                "valor": "C",
                "texto": "Comodino",
                "tip": "Comodino, se acopla en donde sea"
            },
            {
                "id_prr": "04",
                "id_prp": "02",
                "orden": "04",
                "valor": "D",
                "texto": "Temeroso",
                "tip": "Temeroso, con miedo"
            }
        ]
    },
    {
        "id_prp": "03",
        "id_pruebas": 1,
        "tipo": 0,
        "orden": "3",
        "texto": "Grupo 3",
        "answers": [
            {   
                "id_prr": "01",
                "id_prp": "03",
                "orden": "01",
                "valor": "A",
                "texto": "Agradable",
                "tip": "Agradable"
            },
            {
                "id_prr": "02",
                "id_prp": "03",
                "orden": "02",
                "valor": "B",
                "texto": "Temeroso de Dios",
                "tip": "Respetuoso de la cosas de Dios"
            },
            {
                "id_prr": "03",
                "id_prp": "03",
                "orden": "03",
                "valor": "C",
                "texto": "Tenaz",
                "tip": "Tenaz, insistente en conseguir algo"
            },
            {
                "id_prr": "04",
                "id_prp": "03",
                "orden": "04",
                "valor": "D",
                "texto": "Atractivo",
                "tip": "Atractivo"
            }
        ] 
    },
    {
        "id_prp": "04",
        "id_pruebas": 1,
        "tipo": 0,
        "orden": "4",
        "texto": "Grupo 4",
        "answers": [
            {   
                "id_prr": "01",
                "id_prp": "04",
                "orden": "01",
                "valor": "A",
                "texto": "Cauteloso",
                "tip": "Cuidadoso en todo lo que hace"
            },
            {
                "id_prr": "02",
                "id_prp": "04",
                "orden": "02",
                "valor": "B",
                "texto": "Determinado",
                "tip": "Decidido para actuar"
            },
            {
                "id_prr": "03",
                "id_prp": "04",
                "orden": "03",
                "valor": "C",
                "texto": "Convincente",
                "tip": "Capaz de hacer que se acepte algo"
            },
            {
                "id_prr": "04",
                "id_prp": "04",
                "orden": "04",
                "valor": "D",
                "texto": "Bonachón",
                "tip": "Buena gente"
            }
        ]
    },
    {
        "id_prp": "05",
        "id_pruebas": 1,
        "tipo": 0,
        "orden": "5",
        "texto": "Grupo 5",
        "answers": [
            {   
                "id_prr": "01",
                "id_prp": "05",
                "orden": "01",
                "valor": "A",
                "texto": "Dócil",
                "tip": "Fácilemente se deja guiar"
            },
            {
                "id_prr": "02",
                "id_prp": "05",
                "orden": "02",
                "valor": "B",
                "texto": "Atrevido",
                "tip": "Atrevido, hace algo peligroso"
            },
            {
                "id_prr": "03",
                "id_prp": "05",
                "orden": "03",
                "valor": "C",
                "texto": "Leal",
                "tip": "Leal, fiel a alguien o algo"
            },
            {
                "id_prr": "04",
                "id_prp": "05",
                "orden": "04",
                "valor": "D",
                "texto": "Encantador",
                "tip": "Encantador, atrae a la gente"
            }
        ]
    },
    {
        "id_prp": "06",
        "id_pruebas": 1,
        "tipo": 0,
        "orden": "6",
        "texto": "Grupo 6",
        "answers": [
            {   
                "id_prr": "01",
                "id_prp": "06",
                "orden": "01",
                "valor": "A",
                "texto": "Dispuesto",
                "tip": "Dispuesto a hacer algo"
            },
            {
                "id_prr": "02",
                "id_prp": "06",
                "orden": "02",
                "valor": "B",
                "texto": "Deseoso",
                "tip": "Deseoso, con ambición"
            },
            {
                "id_prr": "03",
                "id_prp": "06",
                "orden": "03",
                "valor": "C",
                "texto": "Consecuente",
                "tip": "Razonable y justo con la gente"
            },
            {
                "id_prr": "04",
                "id_prp": "06",
                "orden": "04",
                "valor": "D",
                "texto": "Entusiasta",
                "tip": "Entusiasta, con emoción"
            }
        ]
    },
    {
        "id_prp": "07",
        "id_pruebas": 1,
        "tipo": 0,
        "orden": "7",
        "texto": "Grupo 7",
        "answers": [
            {   
                "id_prr": "01",
                "id_prp": "07",
                "orden": "01",
                "valor": "A",
                "texto": "Fuerza de voluntad",
                "tip": "Fuerza de voluntad, firme en sus propósitos"
            },
            {
                "id_prr": "02",
                "id_prp": "07",
                "orden": "02",
                "valor": "B",
                "texto": "Mente abierta",
                "tip": "Mente abierta, acepta opiniones"
            },
            {
                "id_prr": "03",
                "id_prp": "07",
                "orden": "03",
                "valor": "C",
                "texto": "Complaciente",
                "tip": "Complaciente, da gusto a las personas"
            },
            {
                "id_prr": "04",
                "id_prp": "07",
                "orden": "04",
                "valor": "D",
                "texto": "Animoso",
                "tip": "Animoso"
            }
        ]
    },
    {
        "id_prp": "08",
        "id_pruebas": 1,
        "tipo": 0,
        "orden": "8",
        "texto": "Grupo 8",
        "answers": [
            {   
                "id_prr": "01",
                "id_prp": "08",
                "orden": "01",
                "valor": "A",
                "texto": "Confiado",
                "tip": "Confía en la gente"
            },
            {
                "id_prr": "02",
                "id_prp": "08",
                "orden": "02",
                "valor": "B",
                "texto": "Simpatizador",
                "tip": "Siente simpatía con la mayoría de la gente"
            },
            {
                "id_prr": "03",
                "id_prp": "08",
                "orden": "03",
                "valor": "C",
                "texto": "Tolerante",
                "tip": "Tolera y respeta la forma de actuar de otras personas"
            },
            {
                "id_prr": "04",
                "id_prp": "08",
                "orden": "04",
                "valor": "D",
                "texto": "Afirmativo",
                "tip": "Afirmativo, seguro de sí"
            }
        ]
    },
    {
        "id_prp": "09",
        "id_pruebas": 1,
        "tipo": 0,
        "orden": "9",
        "texto": "Grupo 9",
        "answers": [
            {   
                "id_prr": "01",
                "id_prp": "02",
                "orden": "09",
                "valor": "A",
                "texto": "Ecuánime",
                "tip": "Sereno y equilibrado en todo momento"
            },
            {
                "id_prr": "02",
                "id_prp": "09",
                "orden": "02",
                "valor": "B",
                "texto": "Preciso",
                "tip": "Preciso, exacto"
            },
            {
                "id_prr": "03",
                "id_prp": "09",
                "orden": "03",
                "valor": "C",
                "texto": "Nervioso",
                "tip": "Nervioso, preocupado"
            },
            {
                "id_prr": "04",
                "id_prp": "09",
                "orden": "04",
                "valor": "D",
                "texto": "Jovial",
                "tip": "Jovial, se siente alegre"
            }
        ]
    },
    {
        "id_prp": "10",
        "id_pruebas": 1,
        "tipo": 0,
        "orden": "10",
        "texto": "Grupo 10",
        "answers": [
            {   
                "id_prr": "01",
                "id_prp": "02",
                "orden": "10",
                "valor": "A",
                "texto": "Disciplinado",
                "tip": "Disciplinado, atiende órdenes"
            },
            {
                "id_prr": "02",
                "id_prp": "10",
                "orden": "02",
                "valor": "B",
                "texto": "Generoso",
                "tip": "Generoso, da cosas de buena manera"
            },
            {
                "id_prr": "03",
                "id_prp": "10",
                "orden": "03",
                "valor": "C",
                "texto": "Animoso",
                "tip": "Animoso"
            },
            {
                "id_prr": "04",
                "id_prp": "10",
                "orden": "04",
                "valor": "D",
                "texto": "Persistente",
                "tip": "Peristente, insistente"
            }
        ]
    },
    {
        "id_prp": "11",
        "id_pruebas": 1,
        "tipo": 0,
        "orden": "11",
        "texto": "Grupo 11",
        "answers": [
            {   
                "id_prr": "01",
                "id_prp": "02",
                "orden": "11",
                "valor": "A",
                "texto": "Competitivo",
                "tip": "Competitivo"
            },
            {
                "id_prr": "02",
                "id_prp": "11",
                "orden": "02",
                "valor": "B",
                "texto": "Alegre",
                "tip": "Alegre"
            },
            {
                "id_prr": "03",
                "id_prp": "11",
                "orden": "03",
                "valor": "C",
                "texto": "Considerado",
                "tip": "Considerado con la gente"
            },
            {
                "id_prr": "04",
                "id_prp": "11",
                "orden": "04",
                "valor": "D",
                "texto": "Armonioso",
                "tip": "Tiene buenas relaciones con las personas y es correspondido"
            }
        ]
    },
    {
        "id_prp": "12",
        "id_pruebas": 1,
        "tipo": 0,
        "orden": "12",
        "texto": "Grupo 12",
        "answers": [
            {   
                "id_prr": "01",
                "id_prp": "02",
                "orden": "12",
                "valor": "A",
                "texto": "Admirable",
                "tip": "Admirable"
            },
            {
                "id_prr": "02",
                "id_prp": "12",
                "orden": "02",
                "valor": "B",
                "texto": "Bondadoso",
                "tip": "Bondadoso, se inclina a hacer el bien"
            },
            {
                "id_prr": "03",
                "id_prp": "12",
                "orden": "03",
                "valor": "C",
                "texto": "Resignado",
                "tip": "Resignado, renuncia a una cosa a favor de otro, se sacrifica"
            },
            {
                "id_prr": "04",
                "id_prp": "12",
                "orden": "04",
                "valor": "D",
                "texto": "Carácter firme",
                "tip": "Carácter firme"
            }
        ]
    },
    {
        "id_prp": "13",
        "id_pruebas": 1,
        "tipo": 0,
        "orden": "13",
        "texto": "Grupo 13",
        "answers": [
            {   
                "id_prr": "01",
                "id_prp": "02",
                "orden": "13",
                "valor": "A",
                "texto": "Obediente",
                "tip": "Obediente"
            },
            {
                "id_prr": "02",
                "id_prp": "13",
                "orden": "02",
                "valor": "B",
                "texto": "Quisquilloso",
                "tip": "Quisquilloso, exigente, muy delicado"
            },
            {
                "id_prr": "03",
                "id_prp": "13",
                "orden": "03",
                "valor": "C",
                "texto": "Inconquistable",
                "tip": "Inconquistable, difícil de ganar su voluntad"
            },
            {
                "id_prr": "04",
                "id_prp": "13",
                "orden": "04",
                "valor": "D",
                "texto": "Juguetón",
                "tip": "Juguetón"
            }
        ]
    },
    {
        "id_prp": "14",
        "id_pruebas": 1,
        "tipo": 0,
        "orden": "14",
        "texto": "Grupo 14",
        "answers": [
            {   
                "id_prr": "01",
                "id_prp": "02",
                "orden": "14",
                "valor": "A",
                "texto": "Respetuoso",
                "tip": "Respetuoso"
            },
            {
                "id_prr": "02",
                "id_prp": "14",
                "orden": "02",
                "valor": "B",
                "texto": "Emprendedor",
                "tip": "Emprendedor, inicia algo, busca el logro"
            },
            {
                "id_prr": "03",
                "id_prp": "14",
                "orden": "03",
                "valor": "C",
                "texto": "Optimista",
                "tip": "Optimista, ve el lado bueno de las cosas"
            },
            {
                "id_prr": "04",
                "id_prp": "14",
                "orden": "04",
                "valor": "D",
                "texto": "Servicial",
                "tip": "Servicial, le gusta ayudar a los demas"
            }
        ]
    },
    {
        "id_prp": "15",
        "id_pruebas": 1,
        "tipo": 0,
        "orden": "15",
        "texto": "Grupo 15",
        "answers": [
            {   
                "id_prr": "01",
                "id_prp": "02",
                "orden": "15",
                "valor": "A",
                "texto": "Valiente",
                "tip": "Valiente"
            },
            {
                "id_prr": "02",
                "id_prp": "15",
                "orden": "02",
                "valor": "B",
                "texto": "Inspirador",
                "tip": "Inspirador, con entusiasmo para crear"
            },
            {
                "id_prr": "03",
                "id_prp": "15",
                "orden": "03",
                "valor": "C",
                "texto": "Sumiso",
                "tip": "Sumiso, obediente"
            },
            {
                "id_prr": "04",
                "id_prp": "15",
                "orden": "04",
                "valor": "D",
                "texto": "Tímido",
                "tip": "Tímido, retraido"
            }
        ]
    },
    {
        "id_prp": "16",
        "id_pruebas": 1,
        "tipo": 0,
        "orden": "16",
        "texto": "Grupo 16",
        "answers": [
            {   
                "id_prr": "01",
                "id_prp": "02",
                "orden": "16",
                "valor": "A",
                "texto": "Adaptable",
                "tip": "Adaptable, capza de ajustarse a las cosas"
            },
            {
                "id_prr": "02",
                "id_prp": "16",
                "orden": "02",
                "valor": "B",
                "texto": "Disputador",
                "tip": "Disputador, discute mucho"
            },
            {
                "id_prr": "03",
                "id_prp": "16",
                "orden": "03",
                "valor": "C",
                "texto": "Indiferente",
                "tip": "Indiferente, no tiene intereses por muchas cosas"
            },
            {
                "id_prr": "04",
                "id_prp": "16",
                "orden": "04",
                "valor": "D",
                "texto": "Sangre liviana",
                "tip": "Cae bien a todos fácilmente"
            }
        ]
    },
    {
        "id_prp": "17",
        "id_pruebas": 1,
        "tipo": 0,
        "orden": "17",
        "texto": "Grupo 17",
        "answers": [
            {   
                "id_prr": "01",
                "id_prp": "02",
                "orden": "17",
                "valor": "A",
                "texto": "Amiguero",
                "tip": "Amistoso"
            },
            {
                "id_prr": "02",
                "id_prp": "17",
                "orden": "02",
                "valor": "B",
                "texto": "Paciente",
                "tip": "Paciente, controlado"
            },
            {
                "id_prr": "03",
                "id_prp": "17",
                "orden": "03",
                "valor": "C",
                "texto": "Confíanza en sí mismo",
                "tip": "Confíanza en sí mismo"
            },
            {
                "id_prr": "04",
                "id_prp": "17",
                "orden": "04",
                "valor": "D",
                "texto": "Mesurado para hablar",
                "tip": "Medido y prudente para hablar"
            }
        ]
    },
    {
        "id_prp": "18",
        "id_pruebas": 1,
        "tipo": 0,
        "orden": "18",
        "texto": "Grupo 18",
        "answers": [
            {   
                "id_prr": "01",
                "id_prp": "02",
                "orden": "18",
                "valor": "A",
                "texto": "Conforme",
                "tip": "Conforme, acepta las cosas con paciencia"
            },
            {
                "id_prr": "02",
                "id_prp": "18",
                "orden": "02",
                "valor": "B",
                "texto": "Confiable",
                "tip": "La gente puede confiar en usted"
            },
            {
                "id_prr": "03",
                "id_prp": "18",
                "orden": "03",
                "valor": "C",
                "texto": "Pacífico",
                "tip": "Pacífico"
            },
            {
                "id_prr": "04",
                "id_prp": "18",
                "orden": "04",
                "valor": "D",
                "texto": "Positivo",
                "tip": "Positivo, ve el lado bueno de las cosas"
            }
        ]
    },
    {
        "id_prp": "19",
        "id_pruebas": 1,
        "tipo": 0,
        "orden": "19",
        "texto": "Grupo 19",
        "answers": [
            {   
                "id_prr": "01",
                "id_prp": "02",
                "orden": "19",
                "valor": "A",
                "texto": "Aventurero",
                "tip": "Aventurero, se arriesga antes hechos desconocidos"
            },
            {
                "id_prr": "02",
                "id_prp": "19",
                "orden": "02",
                "valor": "B",
                "texto": "Receptivo",
                "tip": "Receptivo, abierto a escuchar"
            },
            {
                "id_prr": "03",
                "id_prp": "19",
                "orden": "03",
                "valor": "C",
                "texto": "Cordial",
                "tip": "Cordial, y afectuoso en el trato"
            },
            {
                "id_prr": "04",
                "id_prp": "19",
                "orden": "04",
                "valor": "D",
                "texto": "Moderado",
                "tip": "Actúa moderadamente, calmado"
            }
        ]
    },
    {
        "id_prp": "20",
        "id_pruebas": 1,
        "tipo": 0,
        "orden": "20",
        "texto": "Grupo 20",
        "answers": [
            {   
                "id_prr": "01",
                "id_prp": "02",
                "orden": "20",
                "valor": "A",
                "texto": "Indulgente",
                "tip": "Sabe perdonar la culpa de otros"
            },
            {
                "id_prr": "02",
                "id_prp": "20",
                "orden": "02",
                "valor": "B",
                "texto": "Esteta",
                "tip": "Gusto por el orden, por lo bien presentado"
            },
            {
                "id_prr": "03",
                "id_prp": "20",
                "orden": "03",
                "valor": "C",
                "texto": "Vigoroso",
                "tip": "Vigoroso"
            },
            {
                "id_prr": "04",
                "id_prp": "20",
                "orden": "04",
                "valor": "D",
                "texto": "Sociable",
                "tip": "Sociable"
            }
        ]
    },
    {
        "id_prp": "21",
        "id_pruebas": 1,
        "tipo": 0,
        "orden": "21",
        "texto": "Grupo 21",
        "answers": [
            {   
                "id_prr": "01",
                "id_prp": "02",
                "orden": "21",
                "valor": "A",
                "texto": "Parlanchín",
                "tip": "Platicador"
            },
            {
                "id_prr": "02",
                "id_prp": "21",
                "orden": "02",
                "valor": "B",
                "texto": "Controlado",
                "tip": "Fácilmente controla sus acciones"
            },
            {
                "id_prr": "03",
                "id_prp": "21",
                "orden": "03",
                "valor": "C",
                "texto": "Convencional",
                "tip": "Actúa de acuerdo a sus costumbres"
            },
            {
                "id_prr": "04",
                "id_prp": "21",
                "orden": "04",
                "valor": "D",
                "texto": "Decisivo",
                "tip": "Decisivo"
            }
        ]
    },
    {
        "id_prp": "22",
        "id_pruebas": 1,
        "tipo": 0,
        "orden": "22",
        "texto": "Grupo 22",
        "answers": [
            {   
                "id_prr": "01",
                "id_prp": "02",
                "orden": "22",
                "valor": "A",
                "texto": "Cohibido",
                "tip": "Cohinido, se siente limitado para actuar"
            },
            {
                "id_prr": "02",
                "id_prp": "22",
                "orden": "02",
                "valor": "B",
                "texto": "Exacto",
                "tip": "Exacto, preciso, puntual"
            },
            {
                "id_prr": "03",
                "id_prp": "22",
                "orden": "03",
                "valor": "C",
                "texto": "Franco",
                "tip": "Franco, abierto, sincero"
            },
            {
                "id_prr": "04",
                "id_prp": "22",
                "orden": "04",
                "valor": "D",
                "texto": "Buen Compañero",
                "tip": "Buen compañero"
            }
        ]
    },
    {
        "id_prp": "23",
        "id_pruebas": 1,
        "tipo": 0,
        "orden": "23",
        "texto": "Grupo 23",
        "answers": [
            {   
                "id_prr": "01",
                "id_prp": "02",
                "orden": "23",
                "valor": "A",
                "texto": "Diplomático",
                "tip": "Se expresa con cuidado y mucho tacto"
            },
            {
                "id_prr": "02",
                "id_prp": "23",
                "orden": "02",
                "valor": "B",
                "texto": "Audaz",
                "tip": "Gusta hacer cosas atrevidas"
            },
            {
                "id_prr": "03",
                "id_prp": "23",
                "orden": "03",
                "valor": "C",
                "texto": "Refinado",
                "tip": "Refinado, fino, distinguido"
            },
            {
                "id_prr": "04",
                "id_prp": "23",
                "orden": "04",
                "valor": "D",
                "texto": "Satisfecho",
                "tip": "Satisfecho, contento"
            }
        ]
    },
    {
        "id_prp": "24",
        "id_pruebas": 1,
        "tipo": 0,
        "orden": "24",
        "texto": "Grupo 24",
        "answers": [
            {   
                "id_prr": "01",
                "id_prp": "02",
                "orden": "24",
                "valor": "A",
                "texto": "Inquieto",
                "tip": "Inquieto, no está tranquilo"
            },
            {
                "id_prr": "02",
                "id_prp": "24",
                "orden": "02",
                "valor": "B",
                "texto": "Popular",
                "tip": "Popular, conocido por mucha gente"
            },
            {
                "id_prr": "03",
                "id_prp": "24",
                "orden": "03",
                "valor": "C",
                "texto": "Buen Vecino",
                "tip": "Buen vecino"
            },
            {
                "id_prr": "04",
                "id_prp": "24",
                "orden": "04",
                "valor": "D",
                "texto": "Devoto",
                "tip": "Devoto, fervoroso, religioso"
            }
        ]
    }
]