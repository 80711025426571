
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { actionTypes, getTestListSuccess } from '../actions/test.actions';
//import {error as errorNotification } from 'react-notification-system-redux';
import { $http } from '../http/http';
import { history } from '../store';

const getTestListAsync = async (id) => {
    const result = await $http.get(`candidates/${id}/tests`);
    return result;
}

const setTestStatusAsync = async (id, status, idunico, response) => {
    const result = await $http.post(`/tests/${id}`, {status, idunico, response});
    return result;
}

function* getTestList({ payload }) {
    const { userId } = payload;
    try {
        const result = yield call(getTestListAsync, userId);
        if (result.data) {
            yield put(getTestListSuccess(result.data));
        }
    } catch (error) {
        //const title = messages["error-title.login"];
        //const message = messages[`error-${error.response.status}.login`]
        //yield put(errorNotification({title, message}));
    }
}

function* setTestStatus({ payload }) {
    const { testId, status, idunico, response } = payload;
    try {
        if (testId != 0) {
            //hack to remove pipes from test 2
            let resp = (testId == 2) ? response.replaceAll('|', '') : response;
            yield call(setTestStatusAsync, testId, status, idunico, resp);
        }
        history.push('/pruebas');
    } catch (error) {
        //const title = messages["error-title.login"];
        //const message = messages[`error-${error.response.status}.login`]
        //yield put(errorNotification({title, message}));
    }
}

function* setTimeStamp({ payload }) {
    const { testId, idunico, key } = payload;
    try {
        if (testId != 0) {
            yield call(setTimeStamp, testId, idunico, key);
        }
    } catch (error) {
        //const title = messages["error-title.login"];
        //const message = messages[`error-${error.response.status}.login`]
        //yield put(errorNotification({title, message}));
    }
}

export function* watchGetTestList() {
    yield takeEvery( actionTypes.GET_TEST_LIST, getTestList );
}

export function* watchSetTestStatus() {
    yield takeEvery( actionTypes.SET_TEST_STATUS, setTestStatus );
}

export default function* rootSaga() {
    yield all([
        fork(watchGetTestList),
        fork(watchSetTestStatus)
    ]);
}
