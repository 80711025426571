import React, { Component } from 'react';
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import { Row, Col, Card, CardBody, CardFooter, Button } from 'reactstrap';
import { getQuestionList } from '../../store/actions/question.actions';
import { setTestStatus } from '../../store/actions/test.actions';
import { logoutUser} from "../../store/actions/auth.actions";
import Question from '../Question/Question';
import AppHeader from '../AppHeader/AppHeader';
import './timed-test-form.css';

class TimedTestForm extends Component {    

    constructor(props) {
        super(props);
        const { id, title, instructions, type, questionsPerPage, testType, questions, timeout, taskId, showNextButton, actualTest } = props;
        const currentDate = new Date();
        let currentQuestions = questions.slice(0, questionsPerPage);            
        currentQuestions.forEach(function(question){
            question.answered = false;
        })              
        this.state = {
            initialTime: currentDate.getTime(),
            id: id,            
            title: title,
            instructions: instructions,
            type: type,
            testType: testType,
            questions: questions,
            questionsToSplit: questions,
            questionsLenght: questions.length,
            instructionsReaded: false,
            currentQuestionIndex: 0,
            currentQuestions: currentQuestions,
            questionsPerPage: isMobile ? 1 : questionsPerPage,
            isCompleted: false,
            response: '',
            nextButtonEnabled: false,
            showNextButton: showNextButton,
            notAnsweredCountCycle: 0,
            hasNotAnswered: false,
            notAnsweredQuestions: [],
            taskId: taskId,
            actualTest: actualTest
        };
    }
  
    componentDidUpdate(prevProps, prevState){        
		if(prevProps.questions !== this.props.questions){                 
            const { id, instructions, title, type, questionsPerPage, testType, questions, timeout, taskId, showNextButton, actualTest } = this.props;
            const currentDate = new Date();
            let currentQuestions = questions.slice(0, questionsPerPage);                  
            currentQuestions.forEach(function(question){
                question.answered = false;
            });                                  
            this.setState({
                initialTime: currentDate.getTime(),
                id: id,
                instructions: instructions,
                title: title,
                type: type,
                testType: testType,
                questions: questions,
                questionsToSplit: questions,
                questionsLenght: questions.length,
                instructionsReaded: false,
                currentQuestionIndex: 0,
                currentQuestions: currentQuestions,
                questionsPerPage: isMobile ? 1 : questionsPerPage,
                isCompleted: false,
                response: '',
                nextButtonEnabled: false,
                showNextButton: showNextButton,
                notAnsweredCountCycle: 0,
                hasNotAnswered: false,                
                notAnsweredQuestions: [],
                taskId: taskId,
                actualTest: actualTest
            });
        }
    }

    exitToNextTask = () => {
        let {questions,  testType } = this.state;  
        let response = '';       
        questions.forEach(function(question){
            const localResponse = question.answer_selected === undefined ? '' : question.answer_selected;            
            response = response + localResponse + "|";            
        });
        this.setState({actualTest: 0});
        this.props.onCompleteTest(response);
    }
      
    onInstructionsButtonClicked = () => {
        const currentDate = new Date();
        this.setState({ 
            instructionsReaded: true,
            initialTime: currentDate.getTime()
        });
    }

    onAnswerSelect = (questionId, answerId, value) => {
        let {
            questions, 
            questionsToSplit,
            questionsLenght,
            currentQuestions, 
            testType, 
            showNextButton, 
            notAnsweredQuestions, 
            hasNotAnswered, 
            currentQuestionIndex,
            notAnsweredCountCycle
        } = this.state;        
        const questionTime = new Date().getTime();
        const timeout = parseInt(this.props.timeout) * 1000;
        const transcurredTime = questionTime - this.state.initialTime;        
        const completedTime = (this.state.type === 0 ) ? false : (timeout > 0 ? (transcurredTime >= timeout) : false);
        const isNextButton = showNextButton[testType];        
        let nextButtonEnabledLocal = true;     
        if(!completedTime){
            questions.forEach(function(question){            
                if(question.id_prp === questionId){               
                    question.answer_selected = value;     
                    question.answered = (value === '') ? false : true;
                    if(value === ''){
                        const found = notAnsweredQuestions.find(function(question){
                            return question.id_prp === questionId
                        });                    
                        if(!found){
                            notAnsweredQuestions.push(question);
                        }
                        hasNotAnswered = true;                    
                    } else {                        
                        notAnsweredQuestions = notAnsweredQuestions.filter(function(question){
                            return question.id_prp !== questionId
                        });                        
                    }                    
                }                
            });                
        }             
        if(!isNextButton){
            const { questionsPerPage, type, id, actualTest} = this.state;
            let start = parseInt(currentQuestionIndex) + parseInt(questionsPerPage);          
            if(start === questionsLenght && hasNotAnswered){                   
                currentQuestionIndex = 0;
                start = 0;
                questionsToSplit = notAnsweredQuestions;
                questionsLenght = notAnsweredQuestions.length;               
                notAnsweredCountCycle++;                   
            }            
            const end = parseInt(start) + parseInt(questionsPerPage);                
            let currentQuestions = [];          
            currentQuestions = questionsToSplit.slice(start, end);                   
            if(currentQuestions.length === 0 || notAnsweredCountCycle >= 3 || completedTime ){                
                const {idunico} = this.props.loggedUser;                 
                if(type===0){
                    this.props.onCompleteTest('');                                             
                } else {
                    let response = '';
                    let localResponse = '';
                    questions.forEach(function(question){
                        localResponse = question.answer_selected === undefined ? '' : question.answer_selected;
                        response = response + localResponse + "|";                        
                    });
                    this.setState({actualTest: 0});
                    this.props.onCompleteTest(response);                                
                }
            } else {                      
                this.setState({ 
                    currentQuestions: currentQuestions,
                    currentQuestionIndex: start,
                    nextButtonEnabled: false,
                    questions: questions,
                    questionsLenght: questionsLenght,
                    questionsToSplit: questionsToSplit,
                    notAnsweredQuestions: notAnsweredQuestions,
                    hasNotAnswered: hasNotAnswered,
                    notAnsweredCountCycle: notAnsweredCountCycle
                });
            }       
        } else {
            this.setState({
                questions: questions,
                currentQuestions: currentQuestions,
                nextButtonEnabled: nextButtonEnabledLocal,  
                //agregado
                notAnsweredQuestions: notAnsweredQuestions,
                hasNotAnswered: hasNotAnswered,
                notAnsweredCountCycle: notAnsweredCountCycle              
            });
        }           
    }

    moveNextQuestions = () => {
        const { currentQuestionIndex, questionsPerPage, questions, type, id, nextButtonEnabled, testType } = this.state;       
        if(!nextButtonEnabled && testType !== 3 && testType !== 5 && testType !== 17){
            alert("Debes contestar todas las preguntas antes de continuar");
        } else {
            const start = parseInt(currentQuestionIndex) + parseInt(questionsPerPage);
            const end = parseInt(start) + parseInt(questionsPerPage);       
            let currentQuestions = questions.slice(start, end);        
            if(currentQuestions.length === 0){
                const {idunico} = this.props.loggedUser; 
                if(type===0){
                    this.props.onCompleteTest('');                    
                } else {                
                    let response = '';
                    let localResponse = '';
                    questions.forEach(function(question){
                        if(testType === 3 || testType === 5 || testType === 17){                            
                            localResponse = (question.answer_selected === undefined ? "4321" : question.answer_selected);
                            response = response + localResponse + "|";
                        } else {
                            localResponse = question.answer_selected === undefined ? '' : question.answer_selected;
                            response = response + localResponse + "|";
                        }
                    });
                    this.setState({actualTest: 0});
                    this.props.onCompleteTest(response);
                    //this.props.setTestStatus(id, 9, idunico, response);
                }
            } else {              
                currentQuestions.forEach(function(question){
                    question.answered = false;
                })
                this.setState({ 
                    currentQuestions: currentQuestions,
                    currentQuestionIndex: start,
                    nextButtonEnabled: false
                });
            }       
        }
    }
    
    render(){
        
        const { 
            instructionsReaded, 
            instructions, 
            currentQuestions, 
            testType, 
            showNextButton, 
            title 
        } = this.state;
        
        const questionsToRender =  (
            <>
                { currentQuestions.map( (question, index) =>
                    <Question 
                        key={index}
                        id={question['id_prp']} 
                        question={question['texto']}
                        questionType={testType}
                        title={title}
                        isTask={true}
                        answers={question['answers']}
                        onAnswerSelect={this.onAnswerSelect}
                    />                    
				)}
            </>
        );      

        return (
            
            <Row>
                <Col 
                    xs={{ size:12}} 
                    sm={{size:8}} 
                    md={{size:8}} 
                    lg={{size:8}} 
                >
                    <Card className={`${isMobile ? 'test-form-card-mobile' : 'test-form-card'}`}>
                        <AppHeader
                            //onExit={this.onLogout}
                        />
                        <CardBody 
                            className={`${isMobile ? 'test-list-card-body-mobile' : 'test-list-card-body'}`}
                        >
                            { 
                                instructionsReaded ?
                                questionsToRender :
                                <>
                                    <div className="test-instructions-title">
                                        {title}
                                    </div>
                                    <div 
                                        dangerouslySetInnerHTML={{ __html: instructions }} 
                                    />
                                </>
                            }
                        </CardBody>
                        <CardFooter>
                            { 
                                !instructionsReaded &&                                
                                <Button 
                                    color="primary" 
                                    className="float-right"
                                    onClick={this.onInstructionsButtonClicked}
                                >
                                    Continuar
                                </Button>
                            }
                            {    
                                showNextButton[testType] && instructionsReaded &&
                                <Button 
                                    color='primary' 
                                    className='float-right'
                                    onClick={ this.moveNextQuestions }                                  
                                >
                                    Siguiente
                                </Button>
                            }                                                                                     
                        </CardFooter>
                    </Card>
                </Col>
            </Row>           
        );

    }
}

const mapStateToProps = ({ questions, authUser }) => {    
    const { list } = questions;    
    const { loggedUser } = authUser;
	return { list, loggedUser };
};

export default connect(
	mapStateToProps,
	{ getQuestionList, setTestStatus }
)(TimedTestForm);