export const lifo_practice =  [
    {
        "id_prp": "01",
        "id_pruebas": 5,
        "tipo": 0,        
        "orden": "1",
        "texto": "¿Al estar usted limpiando una lámpara maravillosa se le aparece un genio y le ofrece unos regalos. ¿En qué orden los seleccionaría?",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "01",
                "orden": "01",
                "valor": "A",
                "texto": "Fama"
            },
            {
                "id_prr": "02",
                "id_prp": "01",
                "orden": "02",
                "valor": "B",
                "texto": "Dinero"
            },
            {
                "id_prr": "03",
                "id_prp": "01",
                "orden": "03",
                "valor": "C",
                "texto": "Amor"
            },
            {
                "id_prr": "04",
                "id_prp": "01",
                "orden": "04",
                "valor": "D",
                "texto": "Salud"
            }
        ]
    },
    {
        
        "id_prp": "02",
        "id_pruebas": 5,
        "tipo": 0,        
        "orden": "2",
        "texto": "Usted se encuentra un bolso con dinero equivalente a un mes de su sueldo, pero también contiene papelería importante en la que se indica el nombre, domicilio y teléfono del propietario del bolso, ¿Que haría con el bolso?",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "02",
                "orden": "01",
                "valor": "A",
                "texto": "Regreso el bolso sin dinero"
            },
            {
                "id_prr": "02",
                "id_prp": "02",
                "orden": "02",
                "valor": "B",
                "texto": "Me quedo con el dinero y descarto el bolso"
            },
            {
                "id_prr": "03",
                "id_prp": "02",
                "orden": "03",
                "valor": "C",
                "texto": "Regreso el bolso con una parte del dinero y me quedo con otra parte como recompenza"
            },
            {
                "id_prr": "04",
                "id_prp": "02",
                "orden": "04",
                "valor": "D",
                "texto": "Regreso el bolso tal cual como me lo encontre"
            }
        ]
    },
    {
        "id_prp": "03",
        "id_pruebas": 5,
        "tipo": 0,        
        "orden": "3",
        "texto": "Un amigo le da la opción de regalarle 2 boletos para usted y su pareja, ¿Que tipo de espectáculo seleccionaría?",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "03",
                "orden": "01",
                "valor": "A",
                "texto": "Concierto de rock pesado"
            },
            {
                "id_prr": "02",
                "id_prp": "03",
                "orden": "02",
                "valor": "B",
                "texto": "Opera clásica"
            },
            {
                "id_prr": "03",
                "id_prp": "03",
                "orden": "03",
                "valor": "C",
                "texto": "Un festival de baile folklórico"
            },
            {
                "id_prr": "04",
                "id_prp": "03",
                "orden": "04",
                "valor": "D",
                "texto": "Danza contemporánea"
            }
        ]
    }
]

export const lifo = [
    {
        "id_prp": "01",
        "id_pruebas": 5,
        "tipo": 0,        
        "orden": "1",
        "texto": "Me siento mas contento conmigo mismo cuando:",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "01",
                "orden": "01",
                "valor": "A",
                "texto": "Veo la oportunidad de liderazgo y voy tras ella"
            },
            {
                "id_prr": "02",
                "id_prp": "01",
                "orden": "02",
                "valor": "B",
                "texto": "Actúo con idealismo y optimismo"
            },
            {
                "id_prr": "03",
                "id_prp": "01",
                "orden": "03",
                "valor": "C",
                "texto": "Busco mi propio interés y dejo a los demás buscar el suyo"
            },
            {
                "id_prr": "04",
                "id_prp": "01",
                "orden": "04",
                "valor": "D",
                "texto": "Me adapto al grupo en el cual me encuentro"
            }
        ]
    },
    {       
        "id_prp": "02",
        "id_pruebas": 5,
        "tipo": 0,        
        "orden": "2",
        "texto": "Soy sumamente apto para tratar a otros de una manera:",
        "answers": [
           {
                "id_prr": "01",
                "id_prp": "02",
                "orden": "01",
                "valor": "A",
                "texto": "Respetuosa, cortes y con admiración"
           },
           {
                "id_prr": "02",
                "id_prp": "02",
                "orden": "02",
                "valor": "B",
                "texto": "Activa, enérgicamente y con seguridad en mí mismo"
           },
           {
                "id_prr": "03",
                "id_prp": "02",
                "orden": "03",
                "valor": "C",
                "texto": "Cuidadosa, reservada y con tranquilidad"
           },
           {
                "id_prr": "04",
                "id_prp": "02",
                "orden": "04",
                "valor": "D",
                "texto": "Con simpatía, social y amistosamente"
           }
        ]
    },
    {
        "id_prp": "03",
        "id_pruebas": 5,
        "tipo": 0,        
        "orden": "3",
        "texto": "Hago sentir a otros:",    
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "03",
                "orden": "01",
                "valor": "A",
                "texto": "Bien considerados, capaces y dignos de que les pida consejos"
            },
            {
                "id_prr": "02",
                "id_prp": "03",
                "orden": "02",
                "valor": "B",
                "texto": "Interesados y entusiasmados por asociarse conmigo en lo que deseo hacer"
            },
            {
                "id_prr": "03",
                "id_prp": "03",
                "orden": "03",
                "valor": "C",
                "texto": "Tratados con justicia, respetado y agradeciendo la atención que les profeso"
            },
            {
                "id_prr": "04",
                "id_prp": "03",
                "orden": "04",
                "valor": "D",
                "texto": "Complacidos, impresionados conmigo y deseosos de tenerme cerca"
            }
        ]
    },
    {        
        "id_prp": "04",
        "id_pruebas": 5,
        "tipo": 0,    
        "orden": "4",
        "texto": "En un desacuerdo con otra persona me va mejor si:",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "04",
                "orden": "01",
                "valor": "A",
                "texto": "Me fío del sentido de la justicia de la otra persona"
            },
            {
                "id_prr": "02",
                "id_prp": "04",
                "orden": "02",
                "valor": "B",
                "texto": "Trato de manejar por medio de mi astucia y superioridad táctica"
            },
            {
                "id_prr": "03",
                "id_prp": "04",
                "orden": "03",
                "valor": "C",
                "texto": "No pierdo la compostura, soy metódico e impasible"
            },
            {
                "id_prr": "04",
                "id_prp": "04",
                "orden": "04",
                "valor": "D",
                "texto": "Soy flexible y me adapto a la otra persona"
            }
        ]
    },
    {
        "id_prp": "05",
        "id_pruebas": 5,
        "tipo": 0,        
        "orden": "5",
        "texto": "En mis relaciones con los demas puedo:",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "05",
                "orden": "01",
                "valor": "A",
                "texto": "Volverme muy confiado y depositar mi confianza aun en aquellos que parecen no buscarla"
            },
            {
                "id_prr": "02",
                "id_prp": "05",
                "orden": "02",
                "valor": "B",
                "texto": "Volverme agresivo y aprovecharme de otros antes de que se den cuenta que no he sido muy considerados con ellos"
            },
            {
                "id_prr": "03",
                "id_prp": "05",
                "orden": "03",
                "valor": "C",
                "texto": "Volverme suspicaz y prudente y tratarlos con demasiada reserva"
            },
            {
                "id_prr": "04",
                "id_prp": "05",
                "orden": "04",
                "valor": "D",
                "texto": "Volverme demasiado amistoso y hallarme en medio de la gente, aún cuando no he sido especialmente invitado"
            }
        ]
    },
    {
        "id_prp": "06",
        "id_pruebas": 5,
        "tipo": 0,        
        "orden": "6",
        "texto": "Impresiono a los demás como:",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "06",
                "orden": "01",
                "valor": "A",
                "texto": "Una persona ingenua de poca iniciativa y confianza en sí mismo"
            },
            {
                "id_prr": "02",
                "id_prp": "06",
                "orden": "02",
                "valor": "B",
                "texto": "Un agudo agente de bolsa que siempre trata de sacar el mejor partido de las gangas"
            },
            {
                "id_prr": "03",
                "id_prp": "06",
                "orden": "03",
                "valor": "C",
                "texto": "Un individuo obstinado que es frío hacia los demás"
            },
            {
                "id_prr": "04",
                "id_prp": "06",
                "orden": "04",
                "valor": "D",
                "texto": "Una persona inconsistente que nunca toma una verdadera posición personal"
            }
        ]
    },
    {
        "id_prp": "07",
        "id_pruebas": 5,
        "tipo": 0,        
        "orden": "7",
        "texto": "Siento que puedo persuadir a la gente siendo:",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "07",
                "orden": "01",
                "valor": "A",
                "texto": "Modesto e idealista"
            },
            {
                "id_prr": "02",
                "id_prp": "07",
                "orden": "02",
                "valor": "B",
                "texto": "Convincente y seguro de sí mismo"
            },
            {
                "id_prr": "03",
                "id_prp": "07",
                "orden": "03",
                "valor": "C",
                "texto": "Paciente y práctico"
            },
            {
                "id_prr": "04",
                "id_prp": "07",
                "orden": "04",
                "valor": "D",
                "texto": "Entretenido y animado"
            }
        ]
    },
    {
        "id_prp": "08",
        "id_pruebas": 5,
        "tipo": 0,        
        "orden": "8",
        "texto": "En mis relaciones con los demás soy sumamente apto para ser:",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "08",
                "orden": "01",
                "valor": "A",
                "texto": "Confiable y de apoyo a otras personas"
            },
            {
                "id_prr": "02",
                "id_prp": "08",
                "orden": "02",
                "valor": "B",
                "texto": "Rápido para desarrollar ideas útiles y organizar a los demás para que las lleven a cabo"
            },
            {
                "id_prr": "03",
                "id_prp": "08",
                "orden": "03",
                "valor": "C",
                "texto": "Práctico, lógico y cuidadoso de saber a quien estoy tratando"
            },
            {
                "id_prr": "04",
                "id_prp": "08",
                "orden": "04",
                "valor": "D",
                "texto": "Interesado en saber todo de ellos y ansioso por ajustarme a lo que esperan de mí"
            }
        ]
    },
    {
        "id_prp": "09",
        "id_pruebas": 5,
        "tipo": 0,        
        "orden": "9",
        "texto": "Siento suma satisfacción cuando los demás me ven como:",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "09",
                "orden": "01",
                "valor": "A",
                "texto": "Un amigo leal y de confianza"
            },
            {
                "id_prr": "02",
                "id_prp": "09",
                "orden": "02",
                "valor": "B",
                "texto": "Una persona que puede tomar ideas y ponerlas en práctica"
            },
            {
                "id_prr": "03",
                "id_prp": "09",
                "orden": "03",
                "valor": "C",
                "texto": "Una persona práctica y que piensa por sí misma"
            },
            {
                "id_prr": "04",
                "id_prp": "09",
                "orden": "04",
                "valor": "D",
                "texto": "Una persona digna de atención y significativa"
            }
        ]
    },
    {
        "id_prp": "10",
        "id_pruebas": 5,
        "tipo": 0,        
        "orden": "10",
        "texto": "Si no obtengo lo que quiero de una persona tiendo a:",                    
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "10",
                "orden": "01",
                "valor": "A",
                "texto": "Rendirme de buena gana y justificar la inhabilidad de la otra persona para hacer lo mismo"
            },
            {
                "id_prr": "02",
                "id_prp": "10",
                "orden": "02",
                "valor": "B",
                "texto": "Reclamar mis derechos y tratar de persuadirla, para que lo haga de otra manera"
            },
            {
                "id_prr": "03",
                "id_prp": "10",
                "orden": "03",
                "valor": "C",
                "texto": "Sentirme indiferente y encontrar otra manera de conseguir lo que quiero"
            },
            {
                "id_prr": "04",
                "id_prp": "10",
                "orden": "04",
                "valor": "D",
                "texto": "Tomarlo en broma y ser flexible acerca del problema"
            }
        ]
    },
    {
        "id_prp": "11",
        "id_pruebas": 5,
        "tipo": 0,        
        "orden": "11",
        "texto": "Ante los fracasos siento que lo mejor es:",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "11",
                "orden": "01",
                "valor": "A",
                "texto": "Acudir a otros y confiar en su ayuda"
            },
            {
                "id_prr": "02",
                "id_prp": "11",
                "orden": "02",
                "valor": "B",
                "texto": "Luchar por mis derechos y tomar lo que realmente me merezco"
            },
            {
                "id_prr": "03",
                "id_prp": "11",
                "orden": "03",
                "valor": "C",
                "texto": "Mantener lo que ya tengo y desatenderme de los demás"
            },
            {
                "id_prr": "04",
                "id_prp": "11",
                "orden": "04",
                "valor": "D",
                "texto": "Conservar la fachada y tratar de venderme al mejor precio posible"
            }
        ]
    },
    {        
        "id_prp": "12",
        "id_pruebas": 5,
        "tipo": 0,        
        "orden": "12",
        "texto": "Temo que a veces los demás pueden verme como:",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "12",
                "orden": "01",
                "valor": "A",
                "texto": "Sometido e impresionable"
            },
            {
                "id_prr": "02",
                "id_prp": "12",
                "orden": "02",
                "valor": "B",
                "texto": "Agresivo y arrogante"
            },
            {
                "id_prr": "03",
                "id_prp": "12",
                "orden": "03",
                "valor": "C",
                "texto": "Frío y obstinado"
            },
            {
                "id_prr": "04",
                "id_prp": "12",
                "orden": "04",
                "valor": "D",
                "texto": "Superficial y en busca de atención"
            }
        ]
    },
    {
        "id_prp": "13",
        "id_pruebas": 5,
        "tipo": 0,        
        "orden": "13",
        "texto": "Siento que el mejor método para triunfar en la vida es:",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "13",
                "orden": "01",
                "valor": "A",
                "texto": "Ser una persona digna de reconocimiento y confianza en quienes tienen autoridad para reconocer mi valor"
            },
            {
                "id_prr": "02",
                "id_prp": "13",
                "orden": "02",
                "valor": "B",
                "texto": "Trabajar para establecer un derecho a avanzar, y luego reclamarlo"
            },
            {
                "id_prr": "03",
                "id_prp": "13",
                "orden": "03",
                "valor": "C",
                "texto": "Preservar lo que ya tengo y construir sobre ello"
            },
            {
                "id_prr": "04",
                "id_prp": "13",
                "orden": "04",
                "valor": "D",
                "texto": "Desarrollar una personalidad exitosa que llame la atención de los demás"
            }
        ]
    },
    {
        "id_prp": "14",
        "id_pruebas": 5,
        "tipo": 0,        
        "orden": "14",
        "texto": "Resolviendo el problema de trabajo con una persona difícil:",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "14",
                "orden": "01",
                "valor": "A",
                "texto": "Averiguo con otros como han resuelto el problema y sigo con sus consejos"
            },
            {
                "id_prr": "02",
                "id_prp": "14",
                "orden": "02",
                "valor": "B",
                "texto": "Llego a un acuerdo con la persona y sigo junto a ella del mejor modo posible"
            },
            {
                "id_prr": "03",
                "id_prp": "14",
                "orden": "03",
                "valor": "C",
                "texto": "Decido por mí mismo lo que es correcto y mantengo mis propias convicciones"
            },
            {
                "id_prr": "04",
                "id_prp": "14",
                "orden": "04",
                "valor": "D",
                "texto": "Me modifico, de manera de adaptarme a la otra persona y hacer la relación más armónica"
            }
        ]
    },
    {
        "id_prp": "15",
        "id_pruebas": 5,
        "tipo": 0,        
        "orden": "15",
        "texto": "Impresiono a los demás como:",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "15",
                "orden": "01",
                "valor": "A",
                "texto": "Una persona confiada que aprecia su ayuda y consejo"
            },
            {
                "id_prr": "02",
                "id_prp": "15",
                "orden": "02",
                "valor": "B",
                "texto": "Una persona que confía en si misma, que toma la iniciativa y hace actuar a la gente"
            },
            {
                "id_prr": "03",
                "id_prp": "15",
                "orden": "03",
                "valor": "C",
                "texto": "Una persona estable que trata con los demás de manera conservadora"
            },
            {
                "id_prr": "04",
                "id_prp": "15",
                "orden": "04",
                "valor": "D",
                "texto": "Una persona entusiasta, que puede congeniar con casi todo el mundo"
            }
        ]
    },
    {
        "id_prp": "16",
        "id_pruebas": 5,
        "tipo": 0,        
        "orden": "16",
        "texto": "Siento que en el último análisis es mejor:",
            "answers": [
            {
                "id_prr": "01",
                "id_prp": "16",
                "orden": "01",
                "valor": "A",
                "texto": "Simplemente aceptar la derrota, y buscar lo que deseo en alguna otra parte"
            },
            {
                "id_prr": "02",
                "id_prp": "16",
                "orden": "02",
                "valor": "B",
                "texto": "Empeñarme en una lucha de estrategias, antes de perder y no obtener nada"
            },
            {
                "id_prr": "03",
                "id_prp": "16",
                "orden": "03",
                "valor": "C",
                "texto": "Ser suspicaz y posesivo antes de renunciar a lo que ya tengo"
            },
            {
                "id_prr": "04",
                "id_prp": "16",
                "orden": "04",
                "valor": "D",
                "texto": "Transigir y continuar por el momento"
            }
        ]
    },
    {
        "id_prp": "17",
        "id_pruebas": 5,
        "tipo": 0,        
        "orden": "17",
        "texto": "A veces puedo ser:",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "17",
                "orden": "01",
                "valor": "A",
                "texto": "Fácilmente influenciado e inseguro"
            },
            {
                "id_prr": "02",
                "id_prp": "17",
                "orden": "02",
                "valor": "B",
                "texto": "Agresivo, ambicioso y arrogante"
            },
            {
                "id_prr": "03",
                "id_prp": "17",
                "orden": "03",
                "valor": "C",
                "texto": "Desconfiado, frío y crítico"
            },
            {
                "id_prr": "04",
                "id_prp": "17",
                "orden": "04",
                "valor": "D",
                "texto": "Pueril, queriendo ser la estrella del espectáculo"
            }
        ]
    },
    {
        "id_prp": "18",
        "id_pruebas": 5,
        "tipo": 0,        
        "orden": "18",
        "texto": "A veces puedo hacer que los demás se sientan:",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "18",
                "orden": "01",
                "valor": "A",
                "texto": "Superiores y condescendientes conmigo"
            },
            {
                "id_prr": "02",
                "id_prp": "18",
                "orden": "02",
                "valor": "B",
                "texto": "Utilizados por mí y enojados conmigo"
            },
            {
                "id_prr": "03",
                "id_prp": "18",
                "orden": "03",
                "valor": "C",
                "texto": "Injustamente tratados y fríos hacia mí"
            },
            {
                "id_prr": "04",
                "id_prp": "18",
                "orden": "04",
                "valor": "D",
                "texto": "Impacientes e indiferentes hacia mí"
            }
        ]
    }
]