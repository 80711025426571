
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { actionTypes } from '../actions/auth.actions';
import { loginUserSuccess } from '../actions/auth.actions';
import {error as errorNotification } from 'react-notification-system-redux';
import { isMobile } from "react-device-detect";
import { $http } from '../http/http';
import {history} from '../store';

const loginUserAsync = async (id) => {  
    const result = await $http.post(`/login`, {id});       
    return result;
}

const updateUserAsync = async (user) => {  
    const { idunico, nombreusuario, apaterno, amaterno, email, sexo, fechanac, datostemp, datosperm } = user;
    const result = await $http.post(`/candidates/${idunico}`, { nombreusuario, apaterno, amaterno, email, sexo, fechanac, datostemp, datosperm } );       
    return result;
}

function* loginUser({ payload }) {
    const { id } = payload;
    try {
        const result = yield call(loginUserAsync, id);
        if (result.data) {       
            // $http.defaults.headers.common['X-Auth-Token'] = result.data.access_token;
            yield put(loginUserSuccess(result.data));
            history.push('/');
        } 
    } catch (error) {     
        const title = "Error de autenticación"
        const message = "Clave incorrecta, escribir de nuevo" 
        yield put(errorNotification({title, message}));        
    }
}

function* updateUser({ payload }) {
    const { user } = payload;    
    try {
        const result = yield call(updateUserAsync, user);
        if (result.data) {       
            // $http.defaults.headers.common['X-Auth-Token'] = result.data.access_token;
            yield put(loginUserSuccess(result.data));
            let linkTo = isMobile ? '/instrucciones-pruebas' : '/pruebas';
            history.push(linkTo);
        } 
    } catch (error) {     
        const title = "Error de actualizacion"
        const message = "No fue posible actualizar la informacion" 
        yield put(errorNotification({title, message}));        
    }
}

function* logoutUser() {        
    history.push('/');    
}

export function* watchLoginUser() {
    yield takeEvery( actionTypes.LOGIN_USER, loginUser);
}

export function* watchLogoutUser() {
    yield takeEvery( actionTypes.LOGOUT_USER, logoutUser);
}

export function* watchUpdateUser() {
    yield takeEvery( actionTypes.UPDATE_USER, updateUser);
}

export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchUpdateUser)
    ]);
}