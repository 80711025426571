import {
	actionTypes
} from "../actions/test.actions";

const INIT_STATE = {
	list: [],
	loading: false, 
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case actionTypes.GET_TEST_LIST:
			return {
				...state, loading: true
			};
		case actionTypes.GET_TEST_LIST__SUCCESS:
			return {
				...state, loading: false, list: action.payload
			};
		default:
			return state || INIT_STATE;
	}
};