import React, { Component } from 'react';
import {
    allport_practice,
    allport,
    allport_instructions,
    allport_practice_instructions,
    allport_instructions_title,
    allport_instructions_practice_title
} from '../../assets/js/allport';
import {
    terman_practice,
    terman,
    terman_instructions,
    terman_practice_instructions,
    terman_instructions_title,
    terman_instructions_practice_title
} from '../../assets/js/terman';
import { connect } from "react-redux";
import TimedTestForm from '../../components/TimedTestForm/TimedTestForm';
import GeneralInstructionsForm from '../../components/GeneralInstructionsForm/GeneralInstructionsForm';
import TaskInstructionsForm from '../../components/TaskInstructionsForm/TaskInstructionsForm';
import { setTestStatus, setTimeStamp } from '../../store/actions/test.actions';
import {logoutUser} from "../../store/actions/auth.actions";
import {history} from "../../store/store";

class TaskForm extends Component {

    constructor(props) {
        super(props);
        const { id, instructions, type, questionsPerPage, testType, actualTest } = props.location.testProps || {};
        let task = [];
        let taskInstructions = ""
        let taskInstructionsTitle
        let taskShowNextButton = [];
        if(parseInt(id) === 2) {
            task = (type === 0) ? allport_practice : allport;
            taskInstructions = (type === 0) ? allport_practice_instructions : allport_instructions;
            taskInstructionsTitle = (type === 0) ? allport_instructions_practice_title : allport_instructions_title;
            taskShowNextButton = [false, false, false, true, false, false, false];
        } else {
            task = (type === 0) ? terman_practice : terman;
            taskInstructions = (type === 0) ? terman_practice_instructions : terman_instructions;
            taskInstructionsTitle = (type === 0) ? terman_instructions_practice_title : terman_instructions_title;
            taskShowNextButton = [false, true, false, true, false, true, false, false, false, false, false, true, true, true, false, false, true, true, false, false, false, false, false];
        }
        this.state = {
            id: id,
            instructions: instructions,
            type: type,
            testType: testType,
            instructionsReaded: false,
            generalInstructionsReaded: false,
            taskInstructionsReaded: false,
            taskInstructions: taskInstructions,
            taskInstructionsTitle: taskInstructionsTitle,
            currentTestIndex: 0,
            currentTest: task[0],
            task: task,
            taskAnswer: '',
            taskShowNextButton: taskShowNextButton,
            actualTest: actualTest
        };
        let relevant_ts = (type === 1) ? 'inipru' : 'iniprac';
        fetch('/restapi/v1/ts/' + props.loggedUser.idunico + '/'+ id +'/' + relevant_ts);
    }

    selectNextTest = (testResponses) => {
        const { task, id, taskAnswer, type, actualTest } = this.state;
        let currentTestIndex = this.state.currentTestIndex + 1;
        let newTaskResponse = taskAnswer + testResponses;
        if( currentTestIndex < task.length){
            const currentTest = task[currentTestIndex];
            this.setState({
                currentTestIndex: currentTestIndex,
                currentTest: currentTest,
                taskAnswer: newTaskResponse
            });
        } else {
            const {idunico} = this.props.loggedUser;
            const fullResponse = "|" + newTaskResponse;
            if(type === 0){
                this.props.setTestStatus(id, 1, idunico, '');
                alert("HAS TERMINADO LA PRACTICA DE LA PRUEBA No. " + id + "\n\nPresiona [Aceptar] para regresar a tu menú de pruebas, \ndesde donde podrás iniciar la prueba real o bien volver a practicar.");
                fetch('/restapi/v1/ts/' + idunico + '/'+ id +'/finprac');
            } else {
                this.props.setTestStatus(id, 9, idunico, fullResponse);
                this.props.setTimeStamp(id, idunico, 'tsfinpru');
                fetch('/restapi/v1/ts/' + idunico + '/'+ id +'/finpru');
            }
            this.setState({actualTest: 0});
        }
    };

    onGeneralInstructionsReaded = () => {
        this.setState({
            generalInstructionsReaded: true
        })
    }

    onTaskInstructionsReaded = () => {
        this.setState({
            taskInstructionsReaded: true
        })
    }

    onLogout = () => {
        const { type, id, taskId, actualTest} = this.state;
        if(type===0){
            this.props.logoutUser();
        } else {
            const { idunico } = this.props.loggedUser;
            if (actualTest != 0) {
                this.props.setTestStatus(actualTest, 3, idunico, '');
                this.props.setTimeStamp(id, idunico, 'tsfinpru');
                this.props.logoutUser();
            }
        }
    }

    componentWillMount() {
        const { id, actualTest } = this.state;
        if (!id)
            history.push('/pruebas');
    }

    setupBeforeUnloadListener = () => {
        const { actualTest, taskInstructionsReaded } = this.state;
        window.addEventListener("beforeunload", (ev) => {
            ev.preventDefault();
            return this.onLogout();
        });
    };

    componentDidMount() {
        this.setupBeforeUnloadListener();
    }

    render() {

        const {
            currentTest,
            type,
            id,
            generalInstructionsReaded,
            taskInstructionsReaded,
            taskInstructions,
            taskInstructionsTitle,
            taskShowNextButton,
            actualTest
        } = this.state;

        return (
            <>
                {
                    !generalInstructionsReaded ?
                    <GeneralInstructionsForm
                        type={type}
                        generalInstructionsReaded={generalInstructionsReaded}
                        actualTest={actualTest}
                        onInstructionsReaded={this.onGeneralInstructionsReaded}
                    /> :
                    taskInstructionsReaded ?
                    <TimedTestForm
                        type={type}
                        title={currentTest['title']}
                        id={parseInt(currentTest['testType'])}
                        instructions={currentTest['instructions']}
                        questions={currentTest['questions']}
                        questionsPerPage={currentTest['questionsPerPage']}
                        testType={parseInt(currentTest['testType'])}
                        timeout={currentTest['time']}
                        onCompleteTest={this.selectNextTest}
                        taskId={id}
                        showNextButton={taskShowNextButton}
                        actualTest={actualTest}
                    /> :
                    <TaskInstructionsForm
                        title={taskInstructionsTitle}
                        instructions={taskInstructions}
                        taskInstructionsReaded={taskInstructionsReaded}
                        actualTest={actualTest}
                        onInstructionsReaded={this.onTaskInstructionsReaded}
                    />
                }
            </>

        )

    }

}

const mapStateToProps = ({ authUser }) => {
    const { loggedUser } = authUser;
	return { loggedUser };
};

export default connect(
	mapStateToProps,
    { setTestStatus, logoutUser, setTimeStamp }
)(TaskForm);

