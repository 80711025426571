import React, { Component } from 'react';
import { CardHeader, Row, Col } from 'reactstrap';
import { connect } from "react-redux";
import Logo from '../../assets/img/default.gif';
import Exit from '../../assets/img/salir.gif';
import './app-header.css'

class AppHeader extends Component {

    render() {

        return (
            <CardHeader className='app-card-header'>
                <Row className="app-card-row">
                    <Col className="app-card-logo-section">
                        <img src={Logo} alt='logo' className='app-card-logo'/>                
                    </Col>
                    <Col className="app-card-right-section">
                        <img src={Exit} alt='exit' onClick={this.props.onExit}/>
                    </Col>
                </Row>
                <Row className="app-card-company-title">
                    <Col>
                        {this.props.loggedUser.titulo}                        
                    </Col>                    
                </Row>                
            </CardHeader>      
        );

    }

}

const mapStateToProps = ({ authUser }) => {
    const { loggedUser } = authUser;
	return { loggedUser };
};

export default connect(
	mapStateToProps,
	null
)(AppHeader);
