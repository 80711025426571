import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from 'history'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { routerMiddleware } from 'react-router-redux';
import reducers from './reducers/reducers';
import sagas from "./sagas/sagas";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
const persistConfig = {
    key: 'root',
    storage,
    blacklist: [ 'tests', 'questions', 'notifications' ],
};

export const history = createBrowserHistory();
const rootReducer = reducers(history);
const persistedReducer = persistReducer(persistConfig, rootReducer);

export function configureStore() {

    const store = createStore(
        persistedReducer,       
        compose(
            applyMiddleware(
                routerMiddleware(history),
                ...middlewares
            )
        )
    );

    const persistor = persistStore(store);
    
    sagaMiddleware.run(sagas);

    if(module.hot) {
        module.hot.accept('./reducers/reducers', () => {
            const nextRootReducer = require('./reducers/reducers');
            store.replaceReducer(persistConfig, nextRootReducer);
        });
    }

    return { store, persistor };
}

