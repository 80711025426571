import React, { Component, Fragment } from "react";
import { Col, Form, FormGroup, Label, Input } from "reactstrap";
import './multi-choice-question.css';
 
class MultiChoiceQuestion extends Component {

    constructor(props) {
		super(props);
		this.state = { 
            selectedOption: 0
		};		
    }
    
    componentDidUpdate(prevProps, prevState){
		if(prevProps.answers !== this.props.answers){		
			this.setState({ selectedOption: 0 });
		}		
	}
    
    onAnswerClick = (e, questionId, answerId, value) => {          
        if(this.props.isTask){
            e.preventDefault();        
        }
        this.setState(
            { selectedOption: answerId },                
            this.props.onAnswerSelect(questionId, answerId, value)
        );
    }
      
    render(){
    
        const answers = (
            <Fragment>
                { this.props.answers.map( (answer, index) => 
                    <FormGroup check
                        key={`${this.props.questionId}-${answer['id_prr']}`}
                        id={answer['id_prr']} 
                        className='multi-choice-question-answer' 
                        onClick={ 
                            (e) => {this.onAnswerClick(e, this.props.questionId, answer['id_prr'], answer['valor']) } 
                        }
                    >
                        <Label check>
                        <Input 
                            type="radio" 
                            name="radio1" 
                            checked={this.state.selectedOption === answer['id_prr']} 
                        />{' '}
                            {answer['texto']}
                        </Label>
                    </FormGroup>
                )}
            </Fragment>
        ); 

        return(
            <Col xs={12} md={12} lg={12} 
                className='choice-question-box'
            >         
                <Form>
                    <FormGroup tag="fieldset" className='multi-choice-fieldset'>
                        <legend></legend>
                            {answers}
                    </FormGroup>                        
                </Form>                                                        
            </Col>
        );

    }
}

export default MultiChoiceQuestion;