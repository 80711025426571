export const terman_instructions = "<p>Este cuestionario es contra reloj, está dividido en 10 tareas independientes, cada una tiene sus propias instrucciones y tiempo límite.  Al terminar una tarea tendrá el tiempo necesario para leer las instrucciones de la siguiente, asegúrese de haber entendido todas las indicaciones antes iniciar.</p><p>Cuando termine de contestar las preguntas de la tarea o que su tiempo se agote, se le dará paso a la siguiente tarea y así sucesivamente hasta terminar con todas.</p><p>Si no esta seguro de la respuesta correcta o prefiere dejarla pasar por el momento, haga click sobre el texto  <b>No se la respuesta</b>.   Al terminar las preguntas de la tarea si aún tiene tiempo disponible, se mostrarán de nuevo las preguntas que haya dejado pendientes.</p><p>Es recomendable tener papel y lápiz a la mano porque en algunas tareas podrá necesitar hacer operaciones aritméticas.</p>"
export const terman_practice_instructions = "<p>En este cuestionario se mostrarán varias tareas, las cuales se contestarán de manera diferente cada una de ellas.</p><p>A continuación se podrá practicar las diferentes formas o tipos de respuestas que se requieren, son cuatro tipos diferentes.</p>";
export const terman_instructions_title = "Instrucciones Generales";
export const terman_instructions_practice_title = "Instrucciones Generales (Práctica)";

export const terman_practice = [{
        title: "Práctica tipo No. 1: Preguntas de opción múltiple",
        testType: 6,
        time: 0,
        questionsPerPage: 1,
        instructions: '<p>En las preguntas del tipo <b>Opción Múltiple</b> se debe de seleccionar solamente una de las alternativas que se muestran.</p><p>Al dar click sobre cualquiera de las opciones, se tomará la pregunta como contestada y se pasará a la siguiente.  Una vez contestada una pregunta ya no se le preguntará de nuevo.</p><p>Siempre se mostrará una opción adicional la cual es <b>"No sé la respuesta"</b>, la puede utilizar cuando no esté seguro de la respuesta, al terminar las preguntas se le volverá a preguntar lo que haya dejado pendiente con esta opción.</p>',
        questions: [{
                "id_prp": "01",
                "id_pruebas": 3,
                "tipo": 0,
                "orden": "1",
                "texto": "1 de 10: El descubridor de América fue:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "01",
                        "orden": "01",
                        "valor": "A",
                        "texto": 'Américo Vespucio'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "01",
                        "orden": "02",
                        "valor": "B",
                        "texto": 'Los Reyes Católicos de España"'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "01",
                        "orden": "03",
                        "valor": "C",
                        "texto": 'Cristobal Colón'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "01",
                        "orden": "04",
                        "valor": "D",
                        "texto": 'Hernán Cortéz'
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "01",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "02",
                "id_pruebas": 3,
                "tipo": 0,
                "orden": "1",
                "texto": "2 de 10: las palabras NORTE y SUR tienen significado:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "02",
                        "orden": "01",
                        "valor": "A",
                        "texto": 'Igual'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "02",
                        "orden": "02",
                        "valor": "B",
                        "texto": 'Opuesto'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "02",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "03",
                "id_pruebas": 3,
                "tipo": 0,
                "orden": "3",
                "texto": '3 de 10: Al ordenar la frase "comer para pólvora la buena es" su significado es:',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "03",
                        "orden": "01",
                        "valor": "A",
                        "texto": 'Verdadero'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "03",
                        "orden": "02",
                        "valor": "B",
                        "texto": 'Falso'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "03",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "04",
                "id_pruebas": 3,
                "tipo": 0,
                "orden": "4",
                "texto": '4 de 10: ¿El carbón se hace de madera?',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "04",
                        "orden": "01",
                        "valor": "A",
                        "texto": 'Si'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "04",
                        "orden": "02",
                        "valor": "B",
                        "texto": 'No'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "04",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "05",
                "id_pruebas": 3,
                "tipo": 0,
                "orden": "5",
                "texto": '5 de 10: ¿Por qué compramos relojes?, porque:',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "05",
                        "orden": "01",
                        "valor": "A",
                        "texto": 'Nos gusta oirlos sonar'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "05",
                        "orden": "02",
                        "valor": "B",
                        "texto": 'Tienen manecillas'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "05",
                        "orden": "03",
                       "valor" : "",
                        "texto": 'Nos indican las horas'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "05",
                        "orden": "04",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "06",
                "id_pruebas": 3,
                "tipo": 0,
                "orden": "6",
                "texto": '6 de 10: ¿Tienen todos los hombres la misma estatura?',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "06",
                        "orden": "01",
                        "valor": "A",
                        "texto": 'Si'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "06",
                        "orden": "02",
                        "valor": "B",
                        "texto": 'No'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "06",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "07",
                "id_pruebas": 3,
                "tipo": 0,
                "orden": "7",
                "texto": '7 de 10: El oido es a oir como el ojo es a:',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "07",
                        "orden": "01",
                        "valor": "A",
                        "texto": 'Mesa'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "07",
                        "orden": "02",
                        "valor": "B",
                        "texto": 'Ver'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "07",
                        "orden": "03",
                        "valor": "C",
                        "texto": 'Mano'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "07",
                        "orden": "04",
                        "valor": "D",
                        "texto": 'Jugar'
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "07",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "08",
                "id_pruebas": 3,
                "tipo": 0,
                "orden": "8",
                "texto": '8 de 10: Las palabras TIRAR y ARROJAR tienen significado:',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "08",
                        "orden": "01",
                        "valor": "A",
                        "texto": 'Igual'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "08",
                        "orden": "02",
                        "valor": "B",
                        "texto": 'Opuesto'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "08",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "09",
                "id_pruebas": 3,
                "tipo": 0,
                "orden": "9",
                "texto": '9 de 10: Al ordenar la frase "oir son los para oidos" su significado es:',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "09",
                        "orden": "01",
                        "valor": "A",
                        "texto": 'Verdadero'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "09",
                        "orden": "02",
                        "valor": "B",
                        "texto": 'Falso'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "09",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "10",
                "id_pruebas": 3,
                "tipo": 0,
                "orden": "10",
                "texto": '10 de 10: La palabra que NO corresponde con las demás es:',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "10",
                        "orden": "01",
                        "valor": "A",
                        "texto": 'Bala'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "10",
                        "orden": "02",
                        "valor": "B",
                        "texto": 'Cañon'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "10",
                        "orden": "03",
                        "valor": "C",
                        "texto": 'Pistola'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "10",
                        "orden": "04",
                        "valor": "D",
                        "texto": 'Espada'
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "10",
                        "orden": "05",
                        "valor": "E",
                        "texto": 'Lápiz'
                    },
                    {
                        "id_prr": "06",
                        "id_prp": "10",
                        "orden": "06",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            }
        ]
    },
    {
        title:"Práctica tipo No. 2: DOBLE opción múltiple",
        testType: 20,
        time: 0,
        questionsPerPage: 1,
        instructions: '<p>En el tipo de <b>DOBLE opción múltiple</b>, es necesario marcar DOS opciones.</p><p>Presione click sobre una palabra para marcarla, si desea desmarcarla presione click de nuevo sobre ella.</p><p>Presione click sobre una <b>segunda</b> palabra para seleccionarla, en ese momento se tomará su respuesta como completa.</p><p>Siempre se mostrará una opción adicional la cual es <b>"No sé la respuesta"</b>, la puede utilizar cuando no esté seguro de la respuesta, al terminar las preguntas se le volverá a preguntar lo que haya dejado pendiente con esta opción.</p>',
        questions: [{
                "id_prp": "01",
                "id_pruebas": 20,
                "tipo": 0,
                "orden": "1",
                "texto": '1 de 3: Una persona siempre tiene:',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "01",
                        "orden": "01",
                        "valor": "A",
                        "texto": 'Cuerpo'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "01",
                        "orden": "02",
                        "valor": "B",
                        "texto": 'Gorra'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "01",
                        "orden": "03",
                        "valor": "C",
                        "texto": 'Guantes'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "01",
                        "orden": "04",
                        "valor": "D",
                        "texto": 'Boca'
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "01",
                        "orden": "05",
                        "valor": "E",
                        "texto": 'Dinero'
                    }
                ]
            },
            {
                "id_prp": "02",
                "id_pruebas": 20,
                "tipo": 0,
                "orden": "",
                "texto": '2 de 3: Un automóvil siempre tiene:',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "02",
                        "orden": "01",
                        "valor": "A",
                        "texto": 'Chofer'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "02",
                        "orden": "02",
                        "valor": "B",
                        "texto": 'Motor'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "02",
                        "orden": "03",
                        "valor": "C",
                        "texto": 'Seguro'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "02",
                        "orden": "04",
                        "valor": "D",
                        "texto": 'Llantas'
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "02",
                        "orden": "05",
                        "valor": "E",
                        "texto": 'Pasajeros'
                    }
                ]
            },
            {
                "id_prp": "03",
                "id_pruebas": 20,
                "tipo": 0,
                "orden": "",
                "texto": '3 de 3: Un sobre siempre tiene:',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "03",
                        "orden": "01",
                        "valor": "A",
                        "texto": 'Remitente'
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "03",
                        "orden": "02",
                        "valor": "B",
                        "texto": 'Estampillas'
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "03",
                        "orden": "03",
                        "valor": "C",
                        "texto": 'Letras'
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "03",
                        "orden": "04",
                        "valor": "D",
                        "texto": 'Papel'
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "03",
                        "orden": "05",
                        "valor": "D",
                        "texto": 'Forma'
                    }
                ]
            }
        ]
    },
    {
        title: "Práctica tipo 3: Escribir respuesta",
        testType: 21,
        time: 0,
        questionsPerPage: 1,
        instructions: '<p>En este tipo de preguntas usted debe de escribir su respuesta en el recuadro.</p><p>Todos los resultados son numéricos, no se aceptan letras, puede utilizar decimales o fracciones en caso necesario.</p><p>Para aceptar la respuesta presione la tecla Enter o click con el ratón sobre la flecha que se activa al momento de escribir su respuesta.</p><p>Siempre se mostrará una opción la cual es <b>"No sé la respuesta"</b>, la puede utilizar cuando no esté seguro de la respuesta, al terminar las preguntas se le volverá a preguntar lo que haya dejado pendiente si utilizó esta opción.</p>',
        questions: [{
                "id_prp": "01",
                "id_pruebas": 21,
                "tipo": 0,
                "orden": "1",
                "texto": '1 de 3: La operación (2 x 3) + 5 da un total de?',
                "answers": []
            },
            {
                "id_prp": "02",
                "id_pruebas": 21,
                "tipo": 0,
                "orden": "",
                "texto": '2 de 3: ¿A los cuantos grados centígrados el agua hierve?',
                "answers": []
            },
            {
                "id_prp": "03",
                "id_pruebas": 21,
                "tipo": 0,
                "orden": "",
                "texto": '3 de 3: ¿El 3% de 50 es?',
                "answers": []
            }
        ]
    },
    {
        title: "Práctica tipo No. 4: Escribir DOBLE respuesta",
        testType: 22,
        time: 0,
        questionsPerPage: 1,
        instructions: '<p>En este tipo de preguntas se le mostrarán varias series de números, usted tiene que deducir la secuencia lógica de las series y escribir los dos números que deben continuar.</p><p>Su respuesta será compuesta de dos cifras, cada cifra deberá escribirse en cada uno de los DOS recuadros disponibles.  Escriba la primera cifra y muevase con Enter o click del ratón para escribir la segunda cifra.  Para pasar a la siguiente pregunta presione Enter o click en la flecha contigua para aceptar su respuesta.</p><p>Todas las respuestas son numéricas, puede utilizar decimales o fracciones, para números fraccionados utilice el signo "/".</p><p>Cuando no esté seguro de la respuesta presione <b>"No sé la respuesta"</b>, al terminar se volverá a preguntar lo que haya dejado pendiente.</p>',
        questions: [{
                "id_prp": "01",
                "id_pruebas": 22,
                "tipo": 0,
                "orden": "1",
                "texto": 'Pregunta 1 de 5:',
                "answers": "1, 2, 3, 4, 5, 6"
            },
            {
                "id_prp": "02",
                "id_pruebas": 22,
                "tipo": 0,
                "orden": "",
                "texto": 'Pregunta 2 de 5:',
                "answers": "15.1, 15.2, 15.3, 15.4"
            },
            {
                "id_prp": "03",
                "id_pruebas": 22,
                "tipo": 0,
                "orden": "",
                "texto": 'Pregunta 3 de 5:',
                "answers": "1/4, 1/2, 3/4, 1, 1 1/4"
            },
            {
                "id_prp": "04",
                "id_pruebas": 22,
                "tipo": 0,
                "orden": "",
                "texto": 'Pregunta 4 de 5:',
                "answers": "5, 10, 15, 20, 25"
            },
            {
                "id_prp": "05",
                "id_pruebas": 22,
                "tipo": 0,
                "orden": "",
                "texto": 'Pregunta 5 de 5:',
                "answers": "20, 18, 16, 14, 12"
            }
        ]
    }
]

export const terman = [ {
        title:"Tarea 1",
        testType: 6,
        time: 120,
        questionsPerPage: 1,
        instructions: '<p>Esta tarea consiste en identificar la palabra que complete correctamente la oración, solamente puede seleccionar una alternativa.</p><p>Al dar click sobre alguna de las opciones, se tomará la pregunta como contestada y se le pasará a la siguiente.</p><p>Recomendación: Si no esta seguro de la respuesta correcta no trate de adivinarla, es mejor dar click sobre  <b>No sé la respuesta</b> dado que las preguntas incorrectas le afectarán en su puntuación total.</p>',
        questions: [{
                "id_prp": "01",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "1",
                "texto": "1 de 16: La gasolina se extrae de:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "01",
                        "orden": "01",
                        "valor": "A",
                        "texto": "granos"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "01",
                        "orden": "02",
                        "valor": "B",
                        "texto": "petróleo"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "01",
                        "orden": "03",
                        "valor": "C",
                        "texto": "trementina"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "01",
                        "orden": "04",
                        "valor": "D",
                        "texto": "semillas"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "01",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "02",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "2",
                "texto": "2 de 16: Una tonelada tiene:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "02",
                        "orden": "01",
                        "valor": "A",
                        "texto": "1000 Kg"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "02",
                        "orden": "02",
                        "valor": "B",
                        "texto": "2000 Kg"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "02",
                        "orden": "03",
                        "valor": "C",
                        "texto": "3000 Kg"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "02",
                        "orden": "04",
                        "valor": "D",
                        "texto": "4000 Kg"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "02",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "03",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "3",
                "texto": "3 de 16: El país de mayor extensión territorial es:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "03",
                        "orden": "01",
                        "valor": "A",
                        "texto": "Estados Unidos"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "03",
                        "orden": "02",
                        "valor": "B",
                        "texto": "Australia"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "03",
                        "orden": "03",
                        "valor": "C",
                        "texto": "China"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "03",
                        "orden": "04",
                        "valor": "D",
                        "texto": "Rusia"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "03",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "04",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "4",
                "texto": "4 de 16: El nervio óptico sirve para:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "04",
                        "orden": "01",
                        "valor": "A",
                        "texto": "ver"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "04",
                        "orden": "02",
                        "valor": "B",
                        "texto": "oír"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "04",
                        "orden": "03",
                        "valor": "C",
                        "texto": "probar"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "04",
                        "orden": "04",
                        "valor": "D",
                        "texto": "sentir"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "04",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "05",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "5",
                "texto": "5 de 16: El café es una especie de:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "05",
                        "orden": "01",
                        "valor": "A",
                        "texto": "corteza"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "05",
                        "orden": "02",
                        "valor": "B",
                        "texto": "fruto"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "05",
                        "orden": "03",
                        "valor": "C",
                        "texto": "hoja"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "05",
                        "orden": "04",
                        "valor": "D",
                        "texto": "raíz"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "05",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "06",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "6",
                "texto": "6 de 16: El jamón es carne de:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "06",
                        "orden": "01",
                        "valor": "A",
                        "texto": "carnero"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "06",
                        "orden": "02",
                        "valor": "B",
                        "texto": "vaca"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "06",
                        "orden": "03",
                        "valor": "C",
                        "texto": "gallina"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "06",
                        "orden": "04",
                        "valor": "D",
                        "texto": "cerdo"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "06",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "07",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "7",
                "texto": "7 de 16: La laringe está en:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "07",
                        "orden": "01",
                        "valor": "A",
                        "texto": "abdomen"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "07",
                        "orden": "02",
                        "valor": "B",
                        "texto": "cabeza"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "07",
                        "orden": "03",
                        "valor": "C",
                        "texto": "garganta"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "07",
                        "orden": "04",
                        "valor": "D",
                        "texto": "espalda"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "07",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "08",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "8",
                "texto": "8 de 16: La guillotina causa:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "08",
                        "orden": "01",
                        "valor": "A",
                        "texto": "muerte"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "08",
                        "orden": "02",
                        "valor": "B",
                        "texto": "enfermedad"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "08",
                        "orden": "03",
                        "valor": "C",
                        "texto": "fiebre"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "08",
                        "orden": "04",
                        "valor": "D",
                        "texto": "malestar"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "08",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "09",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "9",
                "texto": "9 de 16: La grúa se usa para:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "09",
                        "orden": "01",
                        "valor": "A",
                        "texto": "perforar"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "09",
                        "orden": "02",
                        "valor": "B",
                        "texto": "cortar"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "09",
                        "orden": "03",
                        "valor": "C",
                        "texto": "levantar"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "09",
                        "orden": "04",
                        "valor": "D",
                        "texto": "exprimir"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "09",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "10",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "10",
                "texto": "10 de 16: Una figura de seis lados se llama:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "10",
                        "orden": "01",
                        "valor": "A",
                        "texto": "pentágono"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "10",
                        "orden": "02",
                        "valor": "B",
                        "texto": "paralelogramo"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "10",
                        "orden": "03",
                        "valor": "C",
                        "texto": "hexágono"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "10",
                        "orden": "04",
                        "valor": "D",
                        "texto": "trapecio"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "10",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "11",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "11",
                "texto": "11 de 16: El kilowatt mide:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "11",
                        "orden": "01",
                        "valor": "A",
                        "texto": "lluvia"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "11",
                        "orden": "02",
                        "valor": "B",
                        "texto": "viento"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "11",
                        "orden": "03",
                        "valor": "C",
                        "texto": "electricidad"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "11",
                        "orden": "04",
                        "valor": "D",
                        "texto": "presión"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "11",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "12",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "12",
                "texto": "12 de 16: La pauta se usa en:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "12",
                        "orden": "01",
                        "valor": "A",
                        "texto": "agricultura"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "12",
                        "orden": "02",
                        "valor": "B",
                        "texto": "música"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "12",
                        "orden": "03",
                        "valor": "C",
                        "texto": "fotografía"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "12",
                        "orden": "04",
                        "valor": "D",
                        "texto": "estenografía"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "12",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "13",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "13",
                "texto": "13 de 16: Las esmeraldas son:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "13",
                        "orden": "01",
                        "valor": "A",
                        "texto": "azules"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "13",
                        "orden": "02",
                        "valor": "B",
                        "texto": "verdes"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "13",
                        "orden": "03",
                        "valor": "C",
                        "texto": "rojas"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "13",
                        "orden": "04",
                        "valor": "D",
                        "texto": "amarillas"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "13",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "14",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "14",
                "texto": "14 de 16: El metro es aproximadamente igual a:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "14",
                        "orden": "01",
                        "valor": "A",
                        "texto": "pie"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "14",
                        "orden": "02",
                        "valor": "B",
                        "texto": "pulgada"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "14",
                        "orden": "03",
                        "valor": "C",
                        "texto": "yarda"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "14",
                        "orden": "04",
                        "valor": "D",
                        "texto": "milla"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "14",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "15",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "15",
                "texto": "15 de 16: Las esponjas se obtienen de:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "15",
                        "orden": "01",
                        "valor": "A",
                        "texto": "animales"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "15",
                        "orden": "02",
                        "valor": "B",
                        "texto": "yerbas"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "15",
                        "orden": "03",
                        "valor": "C",
                        "texto": "bosques"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "15",
                        "orden": "04",
                        "valor": "D",
                        "texto": "minas"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "15",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "16",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "16",
                "texto": "16 de 16: Fraude es un termino usado en:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "16",
                        "orden": "01",
                        "valor": "A",
                        "texto": "medicina"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "16",
                        "orden": "02",
                        "valor": "B",
                        "texto": "teología"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "16",
                        "orden": "03",
                        "valor": "C",
                        "texto": "leyes"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "16",
                        "orden": "04",
                        "valor": "D",
                        "texto": "pedagogía"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "16",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            }
        ]
    },
    {
        title:"Tarea 2",
        testType: 6,
        time: 120,
        questionsPerPage: 1,
        instructions: '<p>Esta tarea consiste en identificar la frase que mejor responda a la pregunta, solamente puede seleccionar una alternativa.</p><p>Al dar click sobre alguna de las opciones, se tomará la pregunta como contestada y se le pasará a la siguiente.</p><p>Recuerde que es mejor seleccionar  <b>No sé la respuesta</b>  que tratar de adivinarla.</p>',
        questions: [{
                "id_prp": "01",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "1",
                "texto": "1 de 11: Si la tierra estuviera más cerca del sol:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "01",
                        "orden": "01",
                        "valor": "A",
                        "texto": "las estrellas desaparecerían"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "01",
                        "orden": "02",
                        "valor": "B",
                        "texto": "los meses serían más largos"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "01",
                        "orden": "03",
                        "valor": "C",
                        "texto": "la tierra estaría más caliente"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "01",
                        "orden": "04",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "02",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "2",
                "texto": "2 de 11: Los rayos de una rueda están frecuentemente hechos de nogal porque:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "02",
                        "orden": "01",
                        "valor": "A",
                        "texto": "el nogal es fuerte"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "02",
                        "orden": "02",
                        "valor": "B",
                        "texto": "se corta fácilmente"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "02",
                        "orden": "03",
                        "valor": "C",
                        "texto": "coge bien la pintura"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "02",
                        "orden": "04",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "03",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "3",
                "texto": "3 de 11: Un tren se detiene con más dificultad que un automóvil porque:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "03",
                        "orden": "01",
                        "valor": "A",
                        "texto": "tiene mas ruedas"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "03",
                        "orden": "02",
                        "valor": "B",
                        "texto": "es más pesado"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "03",
                        "orden": "03",
                        "valor": "C",
                        "texto": "sus frenos no son buenos"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "03",
                        "orden": "04",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "04",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "4",
                "texto": "4 de 11: El dicho \"A golpecitos se derriba un roble\" quiere decir:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "04",
                        "orden": "01",
                        "valor": "A",
                        "texto": "que los robles son débiles"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "04",
                        "orden": "02",
                        "valor": "B",
                        "texto": "que son mejores los golpes pequeños"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "04",
                        "orden": "03",
                        "valor": "C",
                        "texto": "que el esfuerzo constante logra resultados sorprendentes"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "04",
                        "orden": "04",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "05",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "5",
                "texto": '5 de 11: El dicho "Una olla vigilada nunca hierve" quiere decir:',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "05",
                        "orden": "01",
                        "valor": "A",
                        "texto": "que no debemos vigilar cuando está en el fuego"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "05",
                        "orden": "02",
                        "valor": "B",
                        "texto": "que tarda en hervir"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "05",
                        "orden": "03",
                        "valor": "C",
                        "texto": "que el tiempo se alarga cuando esperamos algo"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "05",
                        "orden": "04",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "06",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "6",
                "texto": '6 de 11: El dicho "Siembra pasto  mientras haya sol " Quiere decir:',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "06",
                        "orden": "01",
                        "valor": "A",
                        "texto": "que el pasto se siembra en el verano"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "06",
                        "orden": "02",
                        "valor": "B",
                        "texto": "que debemos aprovechar nuestras oportunidades"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "06",
                        "orden": "03",
                        "valor": "C",
                        "texto": "que el pasto no debe cortarse en la noche"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "06",
                        "orden": "04",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "07",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "7",
                "texto": '7 de 11: El dicho "Zapatero a tus zapatos"   quiere decir:',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "07",
                        "orden": "01",
                        "valor": "A",
                        "texto": "que un zapatero no debe abandonar sus zapatos"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "07",
                        "orden": "02",
                        "valor": "B",
                        "texto": "que los zapateros no deben estar ociosos"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "07",
                        "orden": "03",
                        "valor": "C",
                        "texto": "que debemos trabajar en lo que podemos hacer mejor"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "07",
                        "orden": "04",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "08",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "8",
                "texto": '8 de 11: El dicho "La cuña para que  apriete tiene que ser del mismo palo"  quiere decir:',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "08",
                        "orden": "01",
                        "valor": "A",
                        "texto": "que el palo sirve para apretar"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "08",
                        "orden": "02",
                        "valor": "B",
                        "texto": "que las cuñas siempre son de madera"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "08",
                        "orden": "03",
                        "valor": "C",
                        "texto": "nos exigen más las personas que nos conocen"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "08",
                        "orden": "04",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "9",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "9",
                "texto": "9 de 11: Un acorazado de acero flota porque:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "09",
                        "orden": "01",
                        "valor": "A",
                        "texto": "la máquina lo hace flotar"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "09",
                        "orden": "02",
                        "valor": "B",
                        "texto": "tiene grandes espacios huecos"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "09",
                        "orden": "03",
                        "valor": "C",
                        "texto": "contiene algo de madera"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "09",
                        "orden": "04",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "10",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "10",
                "texto": "10 de 11: Las plumas de las alas ayudan al pájaro a volar porque:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "10",
                        "orden": "01",
                        "valor": "A",
                        "texto": "las alas ofrecen una amplia superficie ligera"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "10",
                        "orden": "02",
                        "valor": "B",
                        "texto": "mantienen el aire fuera del cuerpo"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "10",
                        "orden": "03",
                        "valor": "C",
                        "texto": "disminuye su peso"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "10",
                        "orden": "04",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "11",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "11",
                "texto": '11 de 11: El  dicho "Una golondrina no hace verano" quiere decir:',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "11",
                        "orden": "01",
                        "valor": "A",
                        "texto": "que las golondrinas regresan en verano"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "11",
                        "orden": "02",
                        "valor": "B",
                        "texto": "que un simple dato no es suficiente"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "11",
                        "orden": "03",
                        "valor": "C",
                        "texto": "que los pájaros se agregan a nuestros placeres de verano"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "11",
                        "orden": "04",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            }
        ]
    },
    {
        title:"Tarea 3",
        testType: 6,
        time: 120,
        questionsPerPage: 1,
        instructions: '<p>En esta tarea se mostrarán una serie de pares de palabras, para cada par usted debe de identificar si ellas significan lo MISMO o son de significado OPUESTO.</p><p>Seleccione <b>Igual</b> cuando signifiquen lo MISMO, cuando signifiquen lo CONTRARIO seleccione <b>Opuesto</b>.</p>',
        questions: [{
                "id_prp": "01",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "01",
                "texto": '01 de 30: Salado - Dulce',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "01",
                        "orden": "01",
                        "valor": "I",
                        "texto": "Igual"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "01",
                        "orden": "02",
                        "valor": "O",
                        "texto": "Opuesto"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "01",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "02",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "02",
                "texto": '02 de 30: Alegrarse - Regocijarse',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "02",
                        "orden": "01",
                        "valor": "I",
                        "texto": "Igual"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "02",
                        "orden": "02",
                        "valor": "O",
                        "texto": "Opuesto"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "02",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "03",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "03",
                "texto": '03 de 30: Mayor - Menor',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "03",
                        "orden": "01",
                        "valor": "I",
                        "texto": "Igual"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "03",
                        "orden": "02",
                        "valor": "O",
                        "texto": "Opuesto"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "03",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "04",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "04",
                "texto": '04 de 30: Sentarse - Pararse',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "04",
                        "orden": "01",
                        "valor": "I",
                        "texto": "Igual"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "04",
                        "orden": "02",
                        "valor": "O",
                        "texto": "Opuesto"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "04",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "05",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "05",
                "texto": '05 de 30: Desperdiciar - Aprovechar',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "05",
                        "orden": "01",
                        "valor": "I",
                        "texto": "Igual"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "05",
                        "orden": "02",
                        "valor": "O",
                        "texto": "Opuesto"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "05",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "06",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "06",
                "texto": '06 de 30: Conceder - Negar',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "06",
                        "orden": "01",
                        "valor": "I",
                        "texto": "Igual"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "06",
                        "orden": "02",
                        "valor": "O",
                        "texto": "Opuesto"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "06",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "07",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "07",
                "texto": '07 de 30: Tónico - Estimular',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "07",
                        "orden": "01",
                        "valor": "I",
                        "texto": "Igual"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "07",
                        "orden": "02",
                        "valor": "O",
                        "texto": "Opuesto"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "07",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "08",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "08",
                "texto": '08 de 30: Rebajar - Denigrar ',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "08",
                        "orden": "01",
                        "valor": "I",
                        "texto": "Igual"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "08",
                        "orden": "02",
                        "valor": "O",
                        "texto": "Opuesto"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "08",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "09",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "09",
                "texto": '09 de 30: Prohibir - Permitir',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "01",
                        "orden": "01",
                        "valor": "I",
                        "texto": "Igual"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "01",
                        "orden": "02",
                        "valor": "O",
                        "texto": "Opuesto"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "01",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "10",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "10",
                "texto": '10 de 30: Osado - Audaz ',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "10",
                        "orden": "01",
                        "valor": "I",
                        "texto": "Igual"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "10",
                        "orden": "02",
                        "valor": "O",
                        "texto": "Opuesto"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "10",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "11",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "11",
                "texto": '11 de 30: Arrebatado - Prudente ',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "11",
                        "orden": "01",
                        "valor": "I",
                        "texto": "Igual"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "11",
                        "orden": "02",
                        "valor": "O",
                        "texto": "Opuesto"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "11",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "12",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "12",
                "texto": '12 de 30: Obtuso - Agudo ',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "12",
                        "orden": "01",
                        "valor": "I",
                        "texto": "Igual"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "12",
                        "orden": "02",
                        "valor": "O",
                        "texto": "Opuesto"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "12",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "13",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "13",
                "texto": '13 de 30: Inpeto - Experto ',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "13",
                        "orden": "01",
                        "valor": "I",
                        "texto": "Igual"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "13",
                        "orden": "02",
                        "valor": "O",
                        "texto": "Opuesto"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "13",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "14",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "14",
                "texto": '14 de 30: Esquivar - Rehuir',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "14",
                        "orden": "01",
                        "valor": "I",
                        "texto": "Igual"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "14",
                        "orden": "02",
                        "valor": "O",
                        "texto": "Opuesto"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "14",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "15",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "15",
                "texto": '15 de 30: Rebelarse - Someterse',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "15",
                        "orden": "01",
                        "valor": "I",
                        "texto": "Igual"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "15",
                        "orden": "02",
                        "valor": "O",
                        "texto": "Opuesto"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "15",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "16",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "16",
                "texto": '16 de 30: Monotonía - Variedad',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "16",
                        "orden": "01",
                        "valor": "I",
                        "texto": "Igual"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "16",
                        "orden": "02",
                        "valor": "O",
                        "texto": "Opuesto"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "16",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "17",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "17",
                "texto": '17 de 30: Cofort - Consolar',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "17",
                        "orden": "01",
                        "valor": "I",
                        "texto": "Igual"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "17",
                        "orden": "02",
                        "valor": "O",
                        "texto": "Opuesto"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "17",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "18",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "18",
                "texto": '18 de 30: Expeler - Retener',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "18",
                        "orden": "01",
                        "valor": "I",
                        "texto": "Igual"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "18",
                        "orden": "02",
                        "valor": "O",
                        "texto": "Opuesto"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "18",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "19",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "19",
                "texto": '19 de 30: Dócil - Sumiso',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "19",
                        "orden": "01",
                        "valor": "I",
                        "texto": "Igual"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "19",
                        "orden": "02",
                        "valor": "O",
                        "texto": "Opuesto"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "19",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "20",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "20",
                "texto": '20 de 30: Transitorio - Permanente',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "20",
                        "orden": "01",
                        "valor": "I",
                        "texto": "Igual"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "20",
                        "orden": "02",
                        "valor": "O",
                        "texto": "Opuesto"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "20",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "21",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "21",
                "texto": '21 de 30: Seguridad - Riesgo',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "21",
                        "orden": "01",
                        "valor": "I",
                        "texto": "Igual"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "21",
                        "orden": "02",
                        "valor": "O",
                        "texto": "Opuesto"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "21",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "22",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "22",
                "texto": '22 de 30: Aprobar - Objetar',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "22",
                        "orden": "01",
                        "valor": "I",
                        "texto": "Igual"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "22",
                        "orden": "02",
                        "valor": "O",
                        "texto": "Opuesto"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "22",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "23",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "23",
                "texto": '23 de 30: Expeler - Arrojar',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "23",
                        "orden": "01",
                        "valor": "I",
                        "texto": "Igual"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "23",
                        "orden": "02",
                        "valor": "O",
                        "texto": "Opuesto"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "23",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "24",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "24",
                "texto": '24 de 30: Engaño - Impostura',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "24",
                        "orden": "01",
                        "valor": "I",
                        "texto": "Igual"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "24",
                        "orden": "02",
                        "valor": "O",
                        "texto": "Opuesto"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "24",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "25",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "25",
                "texto": '25 de 30: Mitigar - Apaciguar',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "25",
                        "orden": "01",
                        "valor": "I",
                        "texto": "Igual"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "25",
                        "orden": "02",
                        "valor": "O",
                        "texto": "Opuesto"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "25",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "26",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "26",
                "texto": '26 de 30: Iniciar - Aplacar',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "26",
                        "orden": "01",
                        "valor": "I",
                        "texto": "Igual"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "26",
                        "orden": "02",
                        "valor": "O",
                        "texto": "Opuesto"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "26",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "27",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "27",
                "texto": '27 de 30: Reverencia - Veneración',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "27",
                        "orden": "01",
                        "valor": "I",
                        "texto": "Igual"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "27",
                        "orden": "02",
                        "valor": "O",
                        "texto": "Opuesto"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "27",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "28",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "28",
                "texto": '28 de 30: Sobriedad - Frugalidad',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "28",
                        "orden": "01",
                        "valor": "I",
                        "texto": "Igual"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "28",
                        "orden": "02",
                        "valor": "O",
                        "texto": "Opuesto"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "28",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "29",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "29",
                "texto": '29 de 30: Aumentar - Menguar',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "29",
                        "orden": "01",
                        "valor": "I",
                        "texto": "Igual"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "29",
                        "orden": "02",
                        "valor": "O",
                        "texto": "Opuesto"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "29",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "30",
                "id_pruebas": 3,
                "tipo": 1,
                "orden": "30",
                "texto": '30 de 30: Incitar - Instigar',
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "30",
                        "orden": "01",
                        "valor": "I",
                        "texto": "Igual"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "30",
                        "orden": "02",
                        "valor": "O",
                        "texto": "Opuesto"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "30",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            }
        ]
    },    
    {
        title:"Tarea 4",
        testType: 20,
        time: 300,
        questionsPerPage: 1,
        instructions: '<p>En esta tarea usted debe marcar DOS palabras que siempre tiene el sujeto.</p><p>Presione click sobre una palabra para marcarla, para desmarcarla presione click de nuevo sobre ella.</p><p>Al dar click para marcar la <b>segunda</b> palabra se tomará su respuesta como completa.</p><p>Muy importante: En esta tarea usted debe de seleccionar <b>dos</b> palabras.</p>',
        questions: [{
                "id_prp": "01",
                "id_pruebas": 21,
                "tipo": 1,
                "orden": "1",
                "texto": "1 de 18: UN CIRCULO tiene siempre:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "01",
                        "orden": "01",
                        "valor": "A",
                        "texto": "altura"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "01",
                        "orden": "02",
                        "valor": "B",
                        "texto": "cincunferencia"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "01",
                        "orden": "03",
                        "valor": "C",
                        "texto": "latitud"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "01",
                        "orden": "04",
                        "valor": "D",
                        "texto": "longitud"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "01",
                        "orden": "05",
                        "valor": "E",
                        "texto": "radio"
                    }                   
                ]
            },
            {
                "id_prp": "02",
                "id_pruebas": 21,
                "tipo": 1,
                "orden": "2",
                "texto": "2 de 18: UN PAJARO tiene siempre:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "02",
                        "orden": "01",
                        "valor": "A",
                        "texto": "huesos"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "02",
                        "orden": "02",
                        "valor": "B",
                        "texto": "huevos"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "02",
                        "orden": "03",
                        "valor": "C",
                        "texto": "pico"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "02",
                        "orden": "04",
                        "valor": "D",
                        "texto": "nido"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "02",
                        "orden": "05",
                        "valor": "E",
                        "texto": "canto"
                    }
                ]
            },
            {
                "id_prp": "03",
                "id_pruebas": 21,
                "tipo": 1,
                "orden": "3",
                "texto": "3 de 18: LA MÚSICA tiene siempre:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "03",
                        "orden": "01",
                        "valor": "A",
                        "texto": "oyente"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "03",
                        "orden": "02",
                        "valor": "B",
                        "texto": "piano"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "03",
                        "orden": "03",
                        "valor": "C",
                        "texto": "ritmo"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "03",
                        "orden": "04",
                        "valor": "D",
                        "texto": "sonido"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "03",
                        "orden": "05",
                        "valor": "E",
                        "texto": "violín"
                    }
                ]
            },
            {
                "id_prp": "04",
                "id_pruebas": 21,
                "tipo": 1,
                "orden": "4",
                "texto": "4 de 18: UN BANQUETE siempre tiene:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "04",
                        "orden": "01",
                        "valor": "A",
                        "texto": "alimentos"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "04",
                        "orden": "02",
                        "valor": "B",
                        "texto": "música"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "04",
                        "orden": "03",
                        "valor": "C",
                        "texto": "personas"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "04",
                        "orden": "04",
                        "valor": "D",
                        "texto": "discursos"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "04",
                        "orden": "05",
                        "valor": "E",
                        "texto": "anfitrión"
                    }
                ]
            },
            {
                "id_prp": "05",
                "id_pruebas": 21,
                "tipo": 1,
                "orden": "5",
                "texto": "5 de 18: UN CABALLO siempre tiene:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "05",
                        "orden": "01",
                        "valor": "A",
                        "texto": "arnés"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "05",
                        "orden": "02",
                        "valor": "B",
                        "texto": "cascos"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "05",
                        "orden": "03",
                        "valor": "C",
                        "texto": "herraduras"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "05",
                        "orden": "04",
                        "valor": "D",
                        "texto": "establo"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "05",
                        "orden": "05",
                        "valor": "E",
                        "texto": "cola"
                    }
                ]
            },
            {
                "id_prp": "06",
                "id_pruebas": 21,
                "tipo": 1,
                "orden": "6",
                "texto": "6 de 18: UN JUEGO tiene siempre:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "06",
                        "orden": "01",
                        "valor": "A",
                        "texto": "cartas"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "06",
                        "orden": "02",
                        "valor": "B",
                        "texto": "multas"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "06",
                        "orden": "03",
                        "valor": "C",
                        "texto": "jugadores"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "06",
                        "orden": "04",
                        "valor": "D",
                        "texto": "castigos"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "06",
                        "orden": "05",
                        "valor": "E",
                        "texto": "reglas"
                    }
                ]
            },
            {
                "id_prp": "07",
                "id_pruebas": 21,
                "tipo": 1,
                "orden": "7",
                "texto": "7 de 18: UN OBJETO tiene siempre:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "07",
                        "orden": "01",
                        "valor": "A",
                        "texto": "calor"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "07",
                        "orden": "02",
                        "valor": "B",
                        "texto": "tamaño"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "07",
                        "orden": "03",
                        "valor": "C",
                        "texto": "sabor"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "07",
                        "orden": "04",
                        "valor": "D",
                        "texto": "valor"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "07",
                        "orden": "05",
                        "valor": "E",
                        "texto": "peso"
                    }
                ]
            },
            {
                "id_prp": "08",
                "id_pruebas": 21,
                "tipo": 1,
                "orden": "8",
                "texto": "8 de 18: UNA CONVERSACIÓN tiene siempre:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "08",
                        "orden": "01",
                        "valor": "A",
                        "texto": "acuerdos"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "08",
                        "orden": "02",
                        "valor": "B",
                        "texto": "personas"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "08",
                        "orden": "03",
                        "valor": "C",
                        "texto": "preguntas"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "08",
                        "orden": "04",
                        "valor": "D",
                        "texto": "ingenio"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "08",
                        "orden": "05",
                        "valor": "E",
                        "texto": "palabras"
                    }
                ]
            },
            {
                "id_prp": "09",
                "id_pruebas": 21,
                "tipo": 1,
                "orden": "9",
                "texto": "9 de 18: UNA DEUDA tiene siempre:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "09",
                        "orden": "01",
                        "valor": "A",
                        "texto": "acreedor"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "09",
                        "orden": "02",
                        "valor": "B",
                        "texto": "deudor"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "09",
                        "orden": "03",
                        "valor": "C",
                        "texto": "interés"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "09",
                        "orden": "04",
                        "valor": "D",
                        "texto": "hipoteca"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "09",
                        "orden": "05",
                        "valor": "E",
                        "texto": "pago"
                    }
                ]
            },
            {
                "id_prp": "10",
                "id_pruebas": 21,
                "tipo": 1,
                "orden": "10",
                "texto": "10 de 18: UN CIUDADANO tiene siempre:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "10",
                        "orden": "01",
                        "valor": "A",
                        "texto": "país"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "10",
                        "orden": "02",
                        "valor": "B",
                        "texto": "ocupación"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "10",
                        "orden": "03",
                        "valor": "C",
                        "texto": "derechos"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "10",
                        "orden": "04",
                        "valor": "D",
                        "texto": "propiedad"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "10",
                        "orden": "05",
                        "valor": "E",
                        "texto": "voto"
                    }
                ]
            },
            {
                "id_prp": "11",
                "id_pruebas": 21,
                "tipo": 1,
                "orden": "11",
                "texto": "11 de 18: UN MUSEO tiene siempre:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "11",
                        "orden": "01",
                        "valor": "A",
                        "texto": "animales"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "11",
                        "orden": "02",
                        "valor": "B",
                        "texto": "orden"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "11",
                        "orden": "03",
                        "valor": "C",
                        "texto": "colecciones"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "11",
                        "orden": "04",
                        "valor": "D",
                        "texto": "minerales"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "11",
                        "orden": "05",
                        "valor": "E",
                        "texto": "visitantes"
                    }
                ]
            },
            {
                "id_prp": "12",
                "id_pruebas": 21,
                "tipo": 1,
                "orden": "12",
                "texto": "12 de 18: UN COMPROMISO tiene siempre:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "12",
                        "orden": "01",
                        "valor": "A",
                        "texto": "obligación"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "12",
                        "orden": "02",
                        "valor": "B",
                        "texto": "acuerdos"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "12",
                        "orden": "03",
                        "valor": "C",
                        "texto": "amistad"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "12",
                        "orden": "04",
                        "valor": "D",
                        "texto": "respeto"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "12",
                        "orden": "05",
                        "valor": "E",
                        "texto": "satisfacción"
                    }
                ]
            },
            {
                "id_prp": "13",
                "id_pruebas": 21,
                "tipo": 1,
                "orden": "13",
                "texto": "13 de 18: UN BOSQUE tiene siempre:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "13",
                        "orden": "01",
                        "valor": "A",
                        "texto": "animales"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "13",
                        "orden": "02",
                        "valor": "B",
                        "texto": "flores"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "13",
                        "orden": "03",
                        "valor": "C",
                        "texto": "sombras"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "13",
                        "orden": "04",
                        "valor": "D",
                        "texto": "maleza"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "13",
                        "orden": "05",
                        "valor": "E",
                        "texto": "árboles"
                    }
                ]
            },
            {
                "id_prp": "14",
                "id_pruebas": 21,
                "tipo": 1,
                "orden": "14",
                "texto": "14 de 18: LOS OBSTÁCULOS tienen siempre:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "14",
                        "orden": "01",
                        "valor": "A",
                        "texto": "dificultad"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "14",
                        "orden": "02",
                        "valor": "B",
                        "texto": "desaliento"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "14",
                        "orden": "03",
                        "valor": "C",
                        "texto": "fracaso"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "14",
                        "orden": "04",
                        "valor": "D",
                        "texto": "impedimento"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "14",
                        "orden": "05",
                        "valor": "E",
                        "texto": "estímulo"
                    }
                ]
            },
            {
                "id_prp": "15",
                "id_pruebas": 21,
                "tipo": 1,
                "orden": "15",
                "texto": "15 de 18: EL ABORRECIMIENTO tiene siempre:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "15",
                        "orden": "01",
                        "valor": "A",
                        "texto": "aversión"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "15",
                        "orden": "02",
                        "valor": "B",
                        "texto": "desagrado"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "15",
                        "orden": "03",
                        "valor": "C",
                        "texto": "temor"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "15",
                        "orden": "04",
                        "valor": "D",
                        "texto": "ira"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "15",
                        "orden": "05",
                        "valor": "E",
                        "texto": "timidez"
                    }
                ]
            },
            {
                "id_prp": "16",
                "id_pruebas": 21,
                "tipo": 1,
                "orden": "16",
                "texto": "16 de 18: UNA REVISTA tiene siempre",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "16",
                        "orden": "01",
                        "valor": "A",
                        "texto": "anuncios"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "16",
                        "orden": "02",
                        "valor": "B",
                        "texto": "papel"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "16",
                        "orden": "03",
                        "valor": "C",
                        "texto": "fotografía"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "16",
                        "orden": "04",
                        "valor": "D",
                        "texto": "grabados"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "16",
                        "orden": "05",
                        "valor": "E",
                        "texto": "impresión"
                    }
                ]
            },
            {
                "id_prp": "17",
                "id_pruebas": 21,
                "tipo": 1,
                "orden": "17",
                "texto": "17 de 18: LA CONTROVERSIA tiene siempre:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "17",
                        "orden": "01",
                        "valor": "A",
                        "texto": "argumentos"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "17",
                        "orden": "02",
                        "valor": "B",
                        "texto": "desacuerdos"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "17",
                        "orden": "03",
                        "valor": "C",
                        "texto": "aversión"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "17",
                        "orden": "04",
                        "valor": "D",
                        "texto": "gritos"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "17",
                        "orden": "05",
                        "valor": "E",
                        "texto": "derrota"
                    }
                ]
            },
            {
                "id_prp": "18",
                "id_pruebas": 21,
                "tipo": 1,
                "orden": "18",
                "texto": "18 de 18: UN BARCO tiene siempre:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "18",
                        "orden": "01",
                        "valor": "A",
                        "texto": "maquinaria"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "18",
                        "orden": "02",
                        "valor": "B",
                        "texto": "cañones"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "18",
                        "orden": "03",
                        "valor": "C",
                        "texto": "quilla"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "18",
                        "orden": "04",
                        "valor": "D",
                        "texto": "timón"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "18",
                        "orden": "05",
                        "valor": "E",
                        "texto": "velas"
                    }
                ]
            }
        ]
    },
    {
        title:"Tarea 5",
        testType: 21,
        time: 300,
        questionsPerPage: 1,
        instructions: '<p>En esta tarea usted tendrá que calcular las respuestas lo más pronto posible.</p><p>Puede utilizar hoja y lápiz para realizar sus operaciones, no es permitido el uso de calculadoras.</p><p>Escriba el resultado en el recuadro.  Todos los resultados son numéricos, no se aceptan letras, puede utilizar decimales en caso necesario.</p><p>Para aceptar la respuesta presione la tecla Enter o click con el ratón sobre la flecha que se activa al momento de escribir su respuesta.</p><p>Si en alguna pregunta en particular no puede obtener la respuesta de manera rápida se recomienda seleccionar  <b>No sé la respuesta</b>  para continuar con las siguientes.</p>',
        questions: [{
                "id_prp": "01",
                "id_pruebas": 22,
                "tipo": 1,
                "orden": "1",
                "texto": "1 de 12: A 2 por 5 centavos.  ¿Cuántos lápices pueden comprarse con 50 centavos?",
                answers: []
            },
            {
                "id_prp": "02",
                "id_pruebas": 22,
                "tipo": 1,
                "orden": "2",
                "texto": "2 de 12: ¿Cuantas horas tardará un automóvil en recorrer 660 kilómetros a la velocidad de 60 kilómetros por hora?",
                answers: []

            },
            {
                "id_prp": "03",
                "id_pruebas": 22,
                "tipo": 1,
                "orden": "3",
                "texto": "3 de 12: Si un hombre gana $20.00 diarios y gasta $14.00, ¿Cuántos días tardará en ahorrar $300.00?",
                answers: []

            },
            {
                "id_prp": "04",
                "id_pruebas": 22,
                "tipo": 1,
                "orden": "4",
                "texto": "4 de 12: Si dos pasteles cuestan $0.60. ¿Cuántos centavos cuestan la sexta parte de un  pastel?",
                answers: []

            },
            {
                "id_prp": "05",
                "id_pruebas": 22,
                "tipo": 1,
                "orden": "5",
                "texto": "5 de 12: ¿Cuántas veces es más 2 x 3 x 4 x 6,    que   3 x 4?",
                answers: []

            },
            {
                "id_prp": "06",
                "id_pruebas": 22,
                "tipo": 1,
                "orden": "6",
                "texto": "6 de 12: ¿Cuánto es el 16 por ciento de $120.00?",
                answers: []

            },
            {
                "id_prp": "07",
                "id_pruebas": 22,
                "tipo": 1,
                "orden": "7",
                "texto": "7 de 12: El 4 por ciento de $1,000.00 es igual al 8 por ciento de, ¿que cantidad?",
                answers: []

            },
            {
                "id_prp": "08",
                "id_pruebas": 22,
                "tipo": 1,
                "orden": "8",
                "texto": "8 de 12: La capacidad de un refrigerador rectangular es de 48 metros cúbicos. Si tienen 6 metros de largo por 4 de acho, ¿Cuál es  su altura?",
                answers: []

            },
            {
                "id_prp": "09",
                "id_pruebas": 22,
                "tipo": 1,
                "orden": "9",
                "texto": "9 de 12: Si 7 hombres hacen un pozo de 40 metros en 2 días, ¿Cuántos hombres se necesitan para hacerlo en medio día?",
                answers: []

            },
            {
                "id_prp": "10",
                "id_pruebas": 22,
                "tipo": 1,
                "orden": "10",
                "texto": "10 de 12: A tiene $180.00,      B tiene 2/3 de lo que tiene A;   y    C es 1/2 de lo que tiene B. ¿Cuánto tienen todos juntos?",
                answers: []

            },
            {
                "id_prp": "11",
                "id_pruebas": 22,
                "tipo": 1,
                "orden": "11",
                "texto": "11 de 12: Si un hombre corre 100 metros en 10 segundos. ¿Cuántos metros recorrerá como promedio en 1/5 de segundo?",
                answers: []

            },
            {
                "id_prp": "12",
                "id_pruebas": 22,
                "tipo": 1,
                "orden": "12",
                "texto": "12 de 12: Un hombre gasta 1/4 de su sueldo en casa y alimentos y 4/8 en otros gastos, ¿Qué tanto por ciento  de  su sueldo ahorra?",
                answers: []

            }
        ]
    },
    {
        title:"Tarea 6",
        testType: 6,
        time: 120,
        questionsPerPage: 1,
        instructions: '<p>En esta tarea solamente debe de contestar <b>Si</b> o <b>No</b> según sea la respuesta adecuada.</p>',
        questions: [{
                "id_prp": "01",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "1",
                "texto": "1 de 20: ¿La higiene es esencial para la salud?",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "01",
                        "orden": "01",
                        "valor": "S",
                        "texto": "Si"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "01",
                        "orden": "02",
                        "valor": "N",
                        "texto": "No"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "01",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "02",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "2",
                "texto": "2 de 20: ¿Los taquígrafos usan el microscopio?",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "02",
                        "orden": "01",
                        "valor": "S",
                        "texto": "Si"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "02",
                        "orden": "02",
                        "valor": "N",
                        "texto": "No"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "02",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "03",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "3",
                "texto": "3 de 20: ¿Los tiranos son justos?",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "03",
                        "orden": "01",
                        "valor": "S",
                        "texto": "Si"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "03",
                        "orden": "02",
                        "valor": "N",
                        "texto": "No"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "03",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "04",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "4",
                "texto": "4 de 20: ¿Las personas desamparadas están sujetas con frecuencia a la caridad?",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "04",
                        "orden": "01",
                        "valor": "S",
                        "texto": "Si"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "04",
                        "orden": "02",
                        "valor": "N",
                        "texto": "No"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "04",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "05",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "5",
                "texto": "5 de 20: ¿Las personas venerables son por lo común respetables?",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "05",
                        "orden": "01",
                        "valor": "S",
                        "texto": "Si"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "05",
                        "orden": "02",
                        "valor": "N",
                        "texto": "No"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "05",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "06",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "6",
                "texto": "6 de 20: ¿Es el escorbuto un medicamento?",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "06",
                        "orden": "01",
                        "valor": "S",
                        "texto": "Si"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "06",
                        "orden": "02",
                        "valor": "N",
                        "texto": "No"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "06",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "07",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "7",
                "texto": "7 de 20: ¿Es la amonestación una clase de instrumento musical?",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "07",
                        "orden": "01",
                        "valor": "S",
                        "texto": "Si"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "07",
                        "orden": "02",
                        "valor": "N",
                        "texto": "No"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "07",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "08",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "8",
                "texto": "8 de 20: ¿Son los colores opacos preferidos para las banderas nacionales?",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "08",
                        "orden": "01",
                        "valor": "S",
                        "texto": "Si"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "08",
                        "orden": "02",
                        "valor": "N",
                        "texto": "No"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "08",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "09",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "9",
                "texto": "9 de 20: ¿Las cosas misteriosas son a veces pavorosas?",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "09",
                        "orden": "01",
                        "valor": "S",
                        "texto": "Si"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "09",
                        "orden": "02",
                        "valor": "N",
                        "texto": "No"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "09",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "10",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "10",
                "texto": "10 de 20: ¿Las personas conscientes cometen alguna vez errores?",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "10",
                        "orden": "01",
                        "valor": "S",
                        "texto": "Si"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "10",
                        "orden": "02",
                        "valor": "N",
                        "texto": "No"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "10",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "11",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "11",
                "texto": "11 de 20: ¿Son carnívoros los carneros?",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "11",
                        "orden": "01",
                        "valor": "S",
                        "texto": "Si"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "11",
                        "orden": "02",
                        "valor": "N",
                        "texto": "No"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "11",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "12",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "12",
                "texto": "12 de 20: ¿Se dan asignaturas a los caballos?",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "12",
                        "orden": "01",
                        "valor": "S",
                        "texto": "Si"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "12",
                        "orden": "02",
                        "valor": "N",
                        "texto": "No"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "12",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "13",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "13",
                "texto": "13 de 20: ¿Las cartas anónimas llevan alguna vez firma de quien las escribe?",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "13",
                        "orden": "01",
                        "valor": "S",
                        "texto": "Si"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "13",
                        "orden": "02",
                        "valor": "N",
                        "texto": "No"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "13",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "14",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "14",
                "texto": "14 de 20: ¿Son discontinuos los sonidos intermitentes?",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "14",
                        "orden": "01",
                        "valor": "S",
                        "texto": "Si"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "14",
                        "orden": "02",
                        "valor": "N",
                        "texto": "No"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "14",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "15",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "15",
                "texto": "15 de 20: ¿Las enfermedades estimulan el buen juicio?",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "15",
                        "orden": "01",
                        "valor": "S",
                        "texto": "Si"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "15",
                        "orden": "02",
                        "valor": "N",
                        "texto": "No"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "15",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "16",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "16",
                "texto": "16 de 20: ¿Son siempre perversos los hechos premeditados?",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "16",
                        "orden": "01",
                        "valor": "S",
                        "texto": "Si"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "16",
                        "orden": "02",
                        "valor": "N",
                        "texto": "No"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "16",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "17",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "17",
                "texto": "17 de 20: ¿El contacto social tiende a reducir la timidez?",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "17",
                        "orden": "01",
                        "valor": "S",
                        "texto": "Si"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "17",
                        "orden": "02",
                        "valor": "N",
                        "texto": "No"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "17",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "18",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "18",
                "texto": "18 de 20: ¿Son enfermas las personas que tienen mal carácter?",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "18",
                        "orden": "01",
                        "valor": "S",
                        "texto": "Si"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "18",
                        "orden": "02",
                        "valor": "N",
                        "texto": "No"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "18",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "19",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "19",
                "texto": "19 de 20: ¿Se caracteriza generalmente el rencor por la persistencia?",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "19",
                        "orden": "01",
                        "valor": "S",
                        "texto": "Si"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "19",
                        "orden": "02",
                        "valor": "N",
                        "texto": "No"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "19",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "20",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "20",
                "texto": "20 de 20: ¿Meticuloso quiere decir lo mismo que cuidadoso?",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "20",
                        "orden": "01",
                        "valor": "S",
                        "texto": "Si"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "20",
                        "orden": "02",
                        "valor": "N",
                        "texto": "No"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "20",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            }
        ]
    },
    {
        title:"Tarea 7",
        testType: 6,
        time: 120,
        questionsPerPage: 1,
        instructions: '<p>En esta tarea usted debe seleccionar la respuesta correcta haciendo click en la opción correspondiente.</p>',
        questions: [{
                "id_prp": "01",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "1",
                "texto": "1 de 20: ABRIGO es a USAR como PAN es a",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "01",
                        "orden": "01",
                        "valor": "A",
                        "texto": "comer"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "01",
                        "orden": "02",
                        "valor": "B",
                        "texto": "hambre"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "01",
                        "orden": "03",
                        "valor": "C",
                        "texto": "agua"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "01",
                        "orden": "04",
                        "valor": "D",
                        "texto": "cocinar"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "01",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "02",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "2",
                "texto": "2 de 20: SEMANA es a MES como MES es a:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "02",
                        "orden": "01",
                        "valor": "A",
                        "texto": "año"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "02",
                        "orden": "02",
                        "valor": "B",
                        "texto": "hora"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "02",
                        "orden": "03",
                        "valor": "C",
                        "texto": "minuto"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "02",
                        "orden": "04",
                        "valor": "D",
                        "texto": "siglo"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "02",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "03",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "3",
                "texto": "3 de 20: LEON es a ANIMAL como ROSA es a:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "03",
                        "orden": "01",
                        "valor": "A",
                        "texto": "olor"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "03",
                        "orden": "02",
                        "valor": "B",
                        "texto": "oja"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "03",
                        "orden": "03",
                        "valor": "C",
                        "texto": "planta"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "03",
                        "orden": "04",
                        "valor": "D",
                        "texto": "espina"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "03",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "04",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "4",
                "texto": "4 de 20: LIBERTAD es a INDEPENDENCIA como CAUTIVERIO es a:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "04",
                        "orden": "01",
                        "valor": "A",
                        "texto": "negro"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "04",
                        "orden": "02",
                        "valor": "B",
                        "texto": "esclavitud"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "04",
                        "orden": "03",
                        "valor": "C",
                        "texto": "libre"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "04",
                        "orden": "04",
                        "valor": "D",
                        "texto": "sufrir"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "04",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "05",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "5",
                "texto": "5 de 20: DECIR es a DIJO como ESTAR es a:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "05",
                        "orden": "01",
                        "valor": "A",
                        "texto": "cantar"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "05",
                        "orden": "02",
                        "valor": "B",
                        "texto": "estuvo"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "05",
                        "orden": "03",
                        "valor": "C",
                        "texto": "hablando"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "05",
                        "orden": "04",
                        "valor": "D",
                        "texto": "cantó"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "05",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "06",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "6",
                "texto": "6 de 20: LUNES es a MARTES como VIERNES es a:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "06",
                        "orden": "01",
                        "valor": "A",
                        "texto": "semana"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "06",
                        "orden": "02",
                        "valor": "B",
                        "texto": "jueves"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "06",
                        "orden": "03",
                        "valor": "C",
                        "texto": "día"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "06",
                        "orden": "04",
                        "valor": "D",
                        "texto": "sábado"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "06",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "07",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "7",
                "texto": "7 de 20: PLOMO es a PESADO como CORCHO es a:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "07",
                        "orden": "01",
                        "valor": "A",
                        "texto": "botella"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "07",
                        "orden": "02",
                        "valor": "B",
                        "texto": "peso"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "07",
                        "orden": "03",
                        "valor": "C",
                        "texto": "ligero"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "07",
                        "orden": "04",
                        "valor": "D",
                        "texto": "flotar"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "07",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "08",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "8",
                "texto": "8 de 20: ÉXITO es a ALEGRÍA como FRACASO es a:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "08",
                        "orden": "01",
                        "valor": "A",
                        "texto": "tristeza"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "08",
                        "orden": "02",
                        "valor": "B",
                        "texto": "suerte"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "08",
                        "orden": "03",
                        "valor": "C",
                        "texto": "fracasar"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "08",
                        "orden": "04",
                        "valor": "D",
                        "texto": "trabajo"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "08",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "09",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "9",
                "texto": "9 de 20: GATO es a TIGRE como PERRO es a :",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "09",
                        "orden": "01",
                        "valor": "A",
                        "texto": "lobo"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "09",
                        "orden": "02",
                        "valor": "B",
                        "texto": "ladrido"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "09",
                        "orden": "03",
                        "valor": "C",
                        "texto": "mordida"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "09",
                        "orden": "04",
                        "valor": "D",
                        "texto": "agarrar"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "09",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "10",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "10",
                "texto": "10 de 20: 4 es a 16 como 5 es a:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "10",
                        "orden": "01",
                        "valor": "A",
                        "texto": "7"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "10",
                        "orden": "02",
                        "valor": "B",
                        "texto": "45"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "10",
                        "orden": "03",
                        "valor": "C",
                        "texto": "35"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "10",
                        "orden": "04",
                        "valor": "D",
                        "texto": "25"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "10",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "11",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "11",
                "texto": "11 de 20: LLORAR es a REIR como TRISTEZA es a:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "11",
                        "orden": "01",
                        "valor": "A",
                        "texto": "muerte"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "11",
                        "orden": "02",
                        "valor": "B",
                        "texto": "alegría"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "11",
                        "orden": "03",
                        "valor": "C",
                        "texto": "mortaja"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "11",
                        "orden": "04",
                        "valor": "D",
                        "texto": "doctor"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "11",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "12",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "12",
                "texto": "12 de 20: VENENO es a MUERTE como ALIMENTO es a:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "12",
                        "orden": "01",
                        "valor": "A",
                        "texto": "comer"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "12",
                        "orden": "02",
                        "valor": "B",
                        "texto": "pájaro"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "12",
                        "orden": "03",
                        "valor": "C",
                        "texto": "vida"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "12",
                        "orden": "04",
                        "valor": "D",
                        "texto": "malo"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "12",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "13",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "13",
                "texto": "13 de 20: 1 es a 3 como 9 es a:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "13",
                        "orden": "01",
                        "valor": "A",
                        "texto": "18"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "13",
                        "orden": "02",
                        "valor": "B",
                        "texto": "27"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "13",
                        "orden": "03",
                        "valor": "C",
                        "texto": "36"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "13",
                        "orden": "04",
                        "valor": "D",
                        "texto": "45"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "13",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "14",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "14",
                "texto": "14 de 20: ALIMENTO es a HAMBRE como AGUA es a:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "14",
                        "orden": "01",
                        "valor": "A",
                        "texto": "beber"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "14",
                        "orden": "02",
                        "valor": "B",
                        "texto": "claro"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "14",
                        "orden": "03",
                        "valor": "C",
                        "texto": "sed"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "14",
                        "orden": "04",
                        "valor": "D",
                        "texto": "puro"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "14",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "15",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "15",
                "texto": "15 de 20: AQUÍ es ALLÍ como ESTE es a:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "15",
                        "orden": "01",
                        "valor": "A",
                        "texto": "éstos"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "15",
                        "orden": "02",
                        "valor": "B",
                        "texto": "aquellos"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "15",
                        "orden": "03",
                        "valor": "C",
                        "texto": "ése"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "15",
                        "orden": "04",
                        "valor": "D",
                        "texto": "entonces"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "15",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "16",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "16",
                "texto": "16 de 20: TIGRE es a PELO como TRUCHA es a:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "16",
                        "orden": "01",
                        "valor": "A",
                        "texto": "agua"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "16",
                        "orden": "02",
                        "valor": "B",
                        "texto": "pez"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "16",
                        "orden": "03",
                        "valor": "C",
                        "texto": "escama"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "16",
                        "orden": "04",
                        "valor": "D",
                        "texto": "nadar"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "16",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "17",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "17",
                "texto": "17 de 20: PERVERTIDO es a DEPRAVADO como INCORRUPTO es a:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "17",
                        "orden": "01",
                        "valor": "A",
                        "texto": "patria"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "17",
                        "orden": "02",
                        "valor": "B",
                        "texto": "honrado"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "17",
                        "orden": "03",
                        "valor": "C",
                        "texto": "sanción"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "17",
                        "orden": "04",
                        "valor": "D",
                        "texto": "estudio"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "17",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "18",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "18",
                "texto": "18 de 20: B es a D como SEGUNDO es a:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "18",
                        "orden": "01",
                        "valor": "A",
                        "texto": "tercero"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "18",
                        "orden": "02",
                        "valor": "B",
                        "texto": "último"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "18",
                        "orden": "03",
                        "valor": "C",
                        "texto": "cuarto"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "18",
                        "orden": "04",
                        "valor": "D",
                        "texto": "posterior"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "18",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "19",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "19",
                "texto": "19 de 20: ESTADO es a GOBERNADOR como EJERCITO es a:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "19",
                        "orden": "01",
                        "valor": "A",
                        "texto": "marina"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "19",
                        "orden": "02",
                        "valor": "B",
                        "texto": "soldado"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "19",
                        "orden": "03",
                        "valor": "C",
                        "texto": "general"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "19",
                        "orden": "04",
                        "valor": "D",
                        "texto": "posterior"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "19",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "20",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "20",
                "texto": "20 de 20: SUJETO es a PREDICADO como NOMBRE es a:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "20",
                        "orden": "01",
                        "valor": "A",
                        "texto": "pronombre"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "20",
                        "orden": "02",
                        "valor": "B",
                        "texto": "advervio"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "20",
                        "orden": "03",
                        "valor": "C",
                        "texto": "verbo"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "20",
                        "orden": "04",
                        "valor": "D",
                        "texto": "adjetivo"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "20",
                        "orden": "05",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            }
        ]
    },
    {
        title:"Tarea 8",
        testType: 6,
        time: 180,
        questionsPerPage: 1,
        instructions: '<p>En esta tarea se mostrarán una serie de oraciones en las cuales las palabras aparecen mezcladas, debe ordenarlas en su mente y seleccionar si el significado de lo planteado es VERDADERO o FALSO.</p><p>Recuerde que es mejor seleccionar  <b>No sé la respuesta</b>  que tratar de adivinarla.</p>',
        questions: [{
                "id_prp": "01",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "1",
                "texto": "1 de 17: con crecen los niños edad la",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "01",
                        "orden": "01",
                        "valor": "V",
                        "texto": "Verdadero"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "01",
                        "orden": "02",
                        "valor": "F",
                        "texto": "Falso"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "01",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "02",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "2",
                "texto": "2 de 17: buena mar beber el para agua es",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "02",
                        "orden": "01",
                        "valor": "V",
                        "texto": "Verdadero"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "02",
                        "orden": "02",
                        "valor": "F",
                        "texto": "Falso"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "02",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "03",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "3",
                "texto": "3 de 17: lo es paz la guerra opuesto la a",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "03",
                        "orden": "01",
                        "valor": "V",
                        "texto": "Verdadero"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "03",
                        "orden": "02",
                        "valor": "F",
                        "texto": "Falso"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "03",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "04",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "4",
                "texto": "4 de 17: caballos automóvil un que caminan los despacio más",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "04",
                        "orden": "01",
                        "valor": "V",
                        "texto": "Verdadero"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "04",
                        "orden": "02",
                        "valor": "F",
                        "texto": "Falso"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "04",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "05",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "5",
                "texto": "5 de 17: consejo a veces es buen seguir un difícil",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "05",
                        "orden": "01",
                        "valor": "V",
                        "texto": "Verdadero"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "05",
                        "orden": "02",
                        "valor": "F",
                        "texto": "Falso"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "05",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "06",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "6",
                "texto": "6 de 17: cuatrocientas todos páginas contienen libros los",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "06",
                        "orden": "01",
                        "valor": "V",
                        "texto": "Verdadero"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "06",
                        "orden": "02",
                        "valor": "F",
                        "texto": "Falso"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "06",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "07",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "7",
                "texto": "7 de 17: crecen las que fresas el más roble",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "07",
                        "orden": "01",
                        "valor": "V",
                        "texto": "Verdadero"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "07",
                        "orden": "02",
                        "valor": "F",
                        "texto": "Falso"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "07",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "08",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "8",
                "texto": "8 de 17: verdadera comprada no puede amistad ser la",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "08",
                        "orden": "01",
                        "valor": "V",
                        "texto": "Verdadero"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "08",
                        "orden": "02",
                        "valor": "F",
                        "texto": "Falso"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "08",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "09",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "9",
                "texto": "9 de 17: envidia la perjudiciales gula son y la",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "09",
                        "orden": "01",
                        "valor": "V",
                        "texto": "Verdadero"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "09",
                        "orden": "02",
                        "valor": "F",
                        "texto": "Falso"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "09",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "10",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "10",
                "texto": "10 de 17: nunca acciones premiadas las deben buenas ser",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "10",
                        "orden": "01",
                        "valor": "V",
                        "texto": "Verdadero"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "10",
                        "orden": "02",
                        "valor": "F",
                        "texto": "Falso"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "10",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "11",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "11",
                "texto": "11 de 17: exteriores engañan nunca apariencias las nos",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "11",
                        "orden": "01",
                        "valor": "V",
                        "texto": "Verdadero"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "11",
                        "orden": "02",
                        "valor": "F",
                        "texto": "Falso"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "11",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "12",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "12",
                "texto": "12 de 17: nunca es hombre las que acciones demuestran un lo",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "12",
                        "orden": "01",
                        "valor": "V",
                        "texto": "Verdadero"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "12",
                        "orden": "02",
                        "valor": "F",
                        "texto": "Falso"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "12",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "13",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "13",
                "texto": "13 de 17: ciertas siempre la muerte de causan clases enfermedades",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "13",
                        "orden": "01",
                        "valor": "V",
                        "texto": "Verdadero"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "13",
                        "orden": "02",
                        "valor": "F",
                        "texto": "Falso"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "13",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "14",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "14",
                "texto": "14 de 17: odio indeseables aversión sentimientos el son y la",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "14",
                        "orden": "01",
                        "valor": "V",
                        "texto": "Verdadero"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "14",
                        "orden": "02",
                        "valor": "F",
                        "texto": "Falso"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "14",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "15",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "15",
                "texto": "15 de 17: frecuentemente por juzgar podemos acciones hombres nosotros sus a los",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "15",
                        "orden": "01",
                        "valor": "V",
                        "texto": "Verdadero"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "15",
                        "orden": "02",
                        "valor": "F",
                        "texto": "Falso"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "15",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "16",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "16",
                "texto": "16 de 17: una es sábana zarapes tan nunca los calientes como",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "16",
                        "orden": "01",
                        "valor": "V",
                        "texto": "Verdadero"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "16",
                        "orden": "02",
                        "valor": "F",
                        "texto": "Falso"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "16",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "17",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "17",
                "texto": "17 de 17: nunca que descuidados los tropiezan son",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "17",
                        "orden": "01",
                        "valor": "V",
                        "texto": "Verdadero"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "17",
                        "orden": "02",
                        "valor": "F",
                        "texto": "Falso"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "17",
                        "orden": "03",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            }
        ]
    },
    {
        title:"Tarea 9",
        testType: 6,
        time: 120,
        questionsPerPage: 1,
        instructions: '<p>En esta tarea usted debe seleccionar la palabra que <b>NO</b> corresponda con las demás.</p><p>Solamente debe seleccionar una opción.</p>',
        questions: [{
                "id_prp": "01",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "1",
                "texto": "1 de 18: La palabra que NO corresponde con las demás es:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "01",
                        "orden": "01",
                        "valor": "A",
                        "texto": "saltar"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "01",
                        "orden": "02",
                        "valor": "B",
                        "texto": "correr"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "01",
                        "orden": "03",
                        "valor": "C",
                        "texto": "brincar"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "01",
                        "orden": "04",
                        "valor": "D",
                        "texto": "pararse"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "01",
                        "orden": "05",
                        "valor": "E",
                        "texto": "caminar"
                    },
                    {
                        "id_prr": "06",
                        "id_prp": "01",
                        "orden": "06",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "02",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "2",
                "texto": "2 de 18: La palabra que NO corresponde con las demás es:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "02",
                        "orden": "01",
                        "valor": "A",
                        "texto": "monarquía"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "02",
                        "orden": "02",
                        "valor": "B",
                        "texto": "comunista"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "02",
                        "orden": "03",
                        "valor": "C",
                        "texto": "demócrata"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "02",
                        "orden": "04",
                        "valor": "D",
                        "texto": "anarquista"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "02",
                        "orden": "05",
                        "valor": "E",
                        "texto": "católico"
                    },
                    {
                        "id_prr": "06",
                        "id_prp": "02",
                        "orden": "06",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "03",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "3",
                "texto": "3 de 18: La palabra que NO corresponde con las demás es:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "03",
                        "orden": "01",
                        "valor": "A",
                        "texto": "muerte"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "03",
                        "orden": "02",
                        "valor": "B",
                        "texto": "duelo"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "03",
                        "orden": "03",
                        "valor": "C",
                        "texto": "paseo"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "03",
                        "orden": "04",
                        "valor": "D",
                        "texto": "llanto"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "03",
                        "orden": "05",
                        "valor": "E",
                        "texto": "tristza"
                    },
                    {
                        "id_prr": "06",
                        "id_prp": "03",
                        "orden": "06",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "04",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "4",
                "texto": "4 de 18: La palabra que NO corresponde con las demás es:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "04",
                        "orden": "01",
                        "valor": "A",
                        "texto": "carpintero"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "04",
                        "orden": "02",
                        "valor": "B",
                        "texto": "doctor"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "04",
                        "orden": "03",
                        "valor": "C",
                        "texto": "abogado"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "04",
                        "orden": "04",
                        "valor": "D",
                        "texto": "ingeniero"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "04",
                        "orden": "05",
                        "valor": "E",
                        "texto": "profesor"
                    },
                    {
                        "id_prr": "06",
                        "id_prp": "04",
                        "orden": "06",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "05",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "5",
                "texto": "5 de 18: La palabra que NO corresponde con las demás es:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "05",
                        "orden": "01",
                        "valor": "A",
                        "texto": "cama"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "05",
                        "orden": "02",
                        "valor": "B",
                        "texto": "silla"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "05",
                        "orden": "03",
                        "valor": "C",
                        "texto": "plata"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "05",
                        "orden": "04",
                        "valor": "D",
                        "texto": "sofá"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "05",
                        "orden": "05",
                        "valor": "E",
                        "texto": "mesa"
                    },
                    {
                        "id_prr": "06",
                        "id_prp": "05",
                        "orden": "06",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "06",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "6",
                "texto": "6 de 18: La palabra que NO corresponde con las demás es:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "06",
                        "orden": "01",
                        "valor": "A",
                        "texto": "Francisco"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "06",
                        "orden": "02",
                        "valor": "B",
                        "texto": "Santiago"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "06",
                        "orden": "03",
                        "valor": "C",
                        "texto": "Juan"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "06",
                        "orden": "04",
                        "valor": "D",
                        "texto": "Sara"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "06",
                        "orden": "05",
                        "valor": "E",
                        "texto": "Guillermo"
                    },
                    {
                        "id_prr": "06",
                        "id_prp": "06",
                        "orden": "06",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "07",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "7",
                "texto": "7 de 18: La palabra que NO corresponde con las demás es:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "07",
                        "orden": "01",
                        "valor": "A",
                        "texto": "duro"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "07",
                        "orden": "02",
                        "valor": "B",
                        "texto": "áspero"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "07",
                        "orden": "03",
                        "valor": "C",
                        "texto": "liso"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "07",
                        "orden": "04",
                        "valor": "D",
                        "texto": "suave"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "07",
                        "orden": "05",
                        "valor": "E",
                        "texto": "dulce"
                    },
                    {
                        "id_prr": "06",
                        "id_prp": "07",
                        "orden": "06",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "08",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "8",
                "texto": "8 de 18: La palabra que NO corresponde con las demás es:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "08",
                        "orden": "01",
                        "valor": "A",
                        "texto": "digestión"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "08",
                        "orden": "02",
                        "valor": "B",
                        "texto": "oído"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "08",
                        "orden": "03",
                        "valor": "C",
                        "texto": "vista"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "08",
                        "orden": "04",
                        "valor": "D",
                        "texto": "olfato"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "08",
                        "orden": "05",
                        "valor": "E",
                        "texto": "tacto"
                    },
                    {
                        "id_prr": "06",
                        "id_prp": "08",
                        "orden": "06",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "09",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "9",
                "texto": "9 de 18: La palabra que NO corresponde con las demás es:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "09",
                        "orden": "01",
                        "valor": "A",
                        "texto": "automóvil"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "09",
                        "orden": "02",
                        "valor": "B",
                        "texto": "bicicleta"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "09",
                        "orden": "03",
                        "valor": "C",
                        "texto": "guayín"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "09",
                        "orden": "04",
                        "valor": "D",
                        "texto": "teléfono"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "09",
                        "orden": "05",
                        "valor": "E",
                        "texto": "tren"
                    },
                    {
                        "id_prr": "06",
                        "id_prp": "09",
                        "orden": "06",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "10",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "10",
                "texto": "10 de 18: La palabra que NO corresponde con las demás es:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "10",
                        "orden": "01",
                        "valor": "A",
                        "texto": "abajo"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "10",
                        "orden": "02",
                        "valor": "B",
                        "texto": "acá"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "10",
                        "orden": "03",
                        "valor": "C",
                        "texto": "reciente"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "10",
                        "orden": "04",
                        "valor": "D",
                        "texto": "arriba"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "10",
                        "orden": "05",
                        "valor": "E",
                        "texto": "allá"
                    },
                    {
                        "id_prr": "06",
                        "id_prp": "10",
                        "orden": "06",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "11",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "11",
                "texto": "11 de 18: La palabra que NO corresponde con las demás es:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "11",
                        "orden": "01",
                        "valor": "A",
                        "texto": "California"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "11",
                        "orden": "02",
                        "valor": "B",
                        "texto": "Texas"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "11",
                        "orden": "03",
                        "valor": "C",
                        "texto": "Florida"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "11",
                        "orden": "04",
                        "valor": "D",
                        "texto": "Alabama"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "11",
                        "orden": "05",
                        "valor": "E",
                        "texto": "Manhattan"
                    },
                    {
                        "id_prr": "06",
                        "id_prp": "11",
                        "orden": "06",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "12",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "12",
                "texto": "12 de 18: La palabra que NO corresponde con las demás es:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "12",
                        "orden": "01",
                        "valor": "A",
                        "texto": "danés"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "12",
                        "orden": "02",
                        "valor": "B",
                        "texto": "galgo"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "12",
                        "orden": "03",
                        "valor": "C",
                        "texto": "bulldog"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "12",
                        "orden": "04",
                        "valor": "D",
                        "texto": "pekinés"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "12",
                        "orden": "05",
                        "valor": "E",
                        "texto": "siamés"
                    },
                    {
                        "id_prr": "06",
                        "id_prp": "12",
                        "orden": "06",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "13",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "13",
                "texto": "13 de 18: La palabra que NO corresponde con las demás es:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "13",
                        "orden": "01",
                        "valor": "A",
                        "texto": "tela"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "13",
                        "orden": "02",
                        "valor": "B",
                        "texto": "algodón"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "13",
                        "orden": "03",
                        "valor": "C",
                        "texto": "lino"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "13",
                        "orden": "04",
                        "valor": "D",
                        "texto": "seda"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "13",
                        "orden": "05",
                        "valor": "E",
                        "texto": "lana"
                    },
                    {
                        "id_prr": "06",
                        "id_prp": "13",
                        "orden": "06",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "14",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "14",
                "texto": "14 de 18: La palabra que NO corresponde con las demás es:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "14",
                        "orden": "01",
                        "valor": "A",
                        "texto": "ira"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "14",
                        "orden": "02",
                        "valor": "B",
                        "texto": "odio"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "14",
                        "orden": "03",
                        "valor": "C",
                        "texto": "alegría"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "14",
                        "orden": "04",
                        "valor": "D",
                        "texto": "piedad"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "14",
                        "orden": "05",
                        "valor": "E",
                        "texto": "razonamiento"
                    },
                    {
                        "id_prr": "06",
                        "id_prp": "14",
                        "orden": "06",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "15",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "15",
                "texto": "15 de 18: La palabra que NO corresponde con las demás es:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "15",
                        "orden": "01",
                        "valor": "A",
                        "texto": "Edison"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "15",
                        "orden": "02",
                        "valor": "B",
                        "texto": "Franklin"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "15",
                        "orden": "03",
                        "valor": "C",
                        "texto": "Marconi"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "15",
                        "orden": "04",
                        "valor": "D",
                        "texto": "Fulton"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "15",
                        "orden": "05",
                        "valor": "E",
                        "texto": "Shakespeare"
                    },
                    {
                        "id_prr": "06",
                        "id_prp": "15",
                        "orden": "06",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "16",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "16",
                "texto": "16 de 18: La palabra que NO corresponde con las demás es:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "16",
                        "orden": "01",
                        "valor": "A",
                        "texto": "mariposa"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "16",
                        "orden": "02",
                        "valor": "B",
                        "texto": "halcón"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "16",
                        "orden": "03",
                        "valor": "C",
                        "texto": "avestruz"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "16",
                        "orden": "04",
                        "valor": "D",
                        "texto": "petirrojo"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "16",
                        "orden": "05",
                        "valor": "E",
                        "texto": "golondrina"
                    },
                    {
                        "id_prr": "06",
                        "id_prp": "16",
                        "orden": "06",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "17",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "17",
                "texto": "17 de 18: La palabra que NO corresponde con las demás es:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "17",
                        "orden": "01",
                        "valor": "A",
                        "texto": "dar"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "17",
                        "orden": "02",
                        "valor": "B",
                        "texto": "prestar"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "17",
                        "orden": "03",
                        "valor": "C",
                        "texto": "perder"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "17",
                        "orden": "04",
                        "valor": "D",
                        "texto": "ahorrar"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "17",
                        "orden": "05",
                        "valor": "E",
                        "texto": "derrochar"
                    },
                    {
                        "id_prr": "06",
                        "id_prp": "17",
                        "orden": "06",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            },
            {
                "id_prp": "18",
                "id_pruebas": 6,
                "tipo": 1,
                "orden": "18",
                "texto": "18 de 18: La palabra que NO corresponde con las demás es:",
                "answers": [{
                        "id_prr": "01",
                        "id_prp": "18",
                        "orden": "01",
                        "valor": "A",
                        "texto": "Australia"
                    },
                    {
                        "id_prr": "02",
                        "id_prp": "18",
                        "orden": "02",
                        "valor": "B",
                        "texto": "Cuba"
                    },
                    {
                        "id_prr": "03",
                        "id_prp": "18",
                        "orden": "03",
                        "valor": "C",
                        "texto": "Córcega"
                    },
                    {
                        "id_prr": "04",
                        "id_prp": "18",
                        "orden": "04",
                        "valor": "D",
                        "texto": "Irlanda"
                    },
                    {
                        "id_prr": "05",
                        "id_prp": "18",
                        "orden": "05",
                        "valor": "E",
                        "texto": "España"
                    },
                    {
                        "id_prr": "06",
                        "id_prp": "18",
                        "orden": "06",
                       "valor" : "",
                        "texto": "No sé la respuesta"
                    }
                ]
            }
        ]
    },
    {
        title:"Tarea 10",
        testType: 22,
        time: 240,
        questionsPerPage: 1,
        instructions: '<p>En esta tarea se le mostrarán series de números, usted tiene que deducir la secuencia lógica de las series y escribir en los recuadros los números que deben seguir cada serie.</p><p>Su respuesta será compuesta de dos cifras, cada cifra deberá escribirse en uno de los DOS recuadros disponibles.</p><p>Escriba la primera cifra y muevase con Enter o click del ratón al segundo recuadro para escribir la otra cifra.</p><p>Para completar su respuesta y pasar a la siguiente pregunta presione Enter o click en la flecha contigua para aceptar su respuesta.</p><p>Nota: Algunas respuestas pueden contener decimales o numeros fraccionados.</p>',
        questions: [{
                "id_prp": "01",
                "id_pruebas": 22,
                "tipo": 0,
                "orden": "1",
                "texto": 'Pregunta 1 de 11:',
                "answers": "8, 7, 6, 5, 4, 3"
            },
            {
                "id_prp": "02",
                "id_pruebas": 22,
                "tipo": 0,
                "orden": "1",
                "texto": 'Pregunta 2 de 11:',
                "answers": "3, 8, 13, 18, 23, 28"
            },
            {
                "id_prp": "03",
                "id_pruebas": 22,
                "tipo": 0,
                "orden": "1",
                "texto": 'Pregunta 3 de 11:',
                "answers": "1, 2, 4, 8, 16, 32"
            },
            {
                "id_prp": "04",
                "id_pruebas": 22,
                "tipo": 0,
                "orden": "1",
                "texto": 'Pregunta 4 de 11:',
                "answers": "8, 8, 6, 6, 4, 4"
            },
            {
                "id_prp": "05",
                "id_pruebas": 22,
                "tipo": 0,
                "orden": "1",
                "texto": 'Pregunta 5 de 11:',
                "answers": "11 3/4, 12, 12 1/4, 12 3/4"
            },
            {
                "id_prp": "06",
                "id_pruebas": 22,
                "tipo": 0,
                "orden": "1",
                "texto": 'Pregunta 6 de 11:',
                "answers": "8, 9, 12, 13, 16, 17"
            },
            {
                "id_prp": "07",
                "id_pruebas": 22,
                "tipo": 0,
                "orden": "1",
                "texto": 'Pregunta 7 de 11:',
                "answers": "16, 8, 4, 2, 1, 1/2"
            },
            {
                "id_prp": "08",
                "id_pruebas": 22,
                "tipo": 0,
                "orden": "1",
                "texto": 'Pregunta 8 de 11:',
                "answers": "31.3, 40.3, 49.3, 58.3, 67.3, 76.3"
            },
            {
                "id_prp": "09",
                "id_pruebas": 22,
                "tipo": 0,
                "orden": "1",
                "texto": 'Pregunta 9 de 11:',
                "answers": "3, 5, 4, 6, 5, 7"
            },
            {
                "id_prp": "10",
                "id_pruebas": 22,
                "tipo": 0,
                "orden": "1",
                "texto": 'Pregunta 10 de 11:',
                "answers": "7, 11, 15, 16, 220, 24, 25, 29"
            },
            {
                "id_prp": "11",
                "id_pruebas": 22,
                "tipo": 0,
                "orden": "11",
                "texto": 'Pregunta 11 de 11:',
                "answers": "1/25, 1/5, 1, 5"
            }
        ]
    }
]