import React, { Component, Fragment } from "react";
import { Col, ListGroup, ListGroupItem, Form, FormGroup, Label, Input, Button } from "reactstrap";
import './twochoice-question.css';
 
class TwoChoiceQuestion extends Component {

    constructor(props) {
        super(props);        
		this.state = { 
            answersChecked: 0,
            selectedAnswers: "",
            checked: [ false, false, false, false, false, false, false, false ]
		};		
	}
    
    componentDidUpdate(prevProps, prevState){
		if(prevProps.answers !== this.props.answers){            
			this.setState({ 
                answersChecked: 0,
                selectedAnswers: "",
                checked: [ false, false, false, false, false, false, false, false ]
            });
		}		
    }

    onDontKnowButtonClick = () => {
        this.props.onAnswerSelect(this.props.questionId, 1, "");            
    }
    
    onChangeAnswer = (e) => { 
        const { checked, answersChecked, selectedAnswers } = this.state;      
        let totalChecked = answersChecked;
        let newChecked = checked;                
        let selected = selectedAnswers;
        const value = e.target.id.substring(0,1);
        const id = e.target.id.substring(1);        
        newChecked[id] = !checked[id];
        if(newChecked[id]){
            totalChecked += 1;
            selected = selected + value;
        } else {
            totalChecked -= 1;
            selected = selected.length === 2 ? selected.substring(0,1) : "";
        }                
        if(totalChecked === 2){                 
            this.props.onAnswerSelect(this.props.questionId, 1, selected);            
        } else {
            this.setState({
                answersChecked: totalChecked,
                selectedAnswers: selected,
                checked: newChecked
            })
        }        
    }
    
    render(){
    
        const answers = (
            <Fragment>
                { this.props.answers.map( (answer, index) =>
                    <ListGroupItem
                        key={`${this.props.questionId}-${answer['id_prr']}`}
                        id={index} 
                        className='choice-question-answer'                         
                    >          
                        <FormGroup check>
                            <Label check>
                                <Input 
                                    type="checkbox" 
                                    id={`${answer['valor']}${index}`}                                    
                                    checked={this.state.checked[index]}
                                    onChange={this.onChangeAnswer} 
                                />{' '}
                                {answer['texto']}
                            </Label>
                        </FormGroup>
                    </ListGroupItem>         
				)}
            </Fragment>
        ); 

        return(
            <Col xs={12} md={12} lg={12} 
                className='two-choice-question-box'
            >         
                <Form>
                    <ListGroup>
                        {answers}
                    </ListGroup>
                </Form> 
                <div className="dont-know-button">
                    <Button 
                        outline
                        className="two-choice-dontknow-button"
                        xs={12}                    
                        color='primary' 
                        onClick={ this.onDontKnowButtonClick }                                  
                    >
                        No sé la respuesta
                    </Button>
                </div>                                                       
            </Col>
        );

    }
}

export default TwoChoiceQuestion;