import React, { Component } from "react";
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { configureStore, history } from './store/store';
import Layout from './containers/Layout';
import './assets/css/App.css';

const { store, persistor } = configureStore();

class App extends Component {

	render() {

		return (
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<ConnectedRouter history={history}>
						<Layout />						
					</ConnectedRouter>
				</PersistGate>
			</Provider>			
    );
    
  }
  
}

export default App;