import React, { Component, Fragment } from "react";
import { Col, Row } from "reactstrap";
import { isMobile } from "react-device-detect";
import './raven-question.css';
 
class RavenQuestion extends Component {

    constructor(props) {
		super(props);		
	}
    
    onAnswerClick = (questionId, answerId, value) => {
        const values = value.split("_");        
        this.props.onAnswerSelect(questionId, answerId, values[1]);
    }
      
    render(){
        const columnLenght = this.props.answers.length == 8 ? 3 : 4;
        const columnStyle = this.props.answers.length == 8 ? "answer-column-no-padding" : "answer-column";
        const mobileStyle = this.props.answers.length == 8 ? "answer-image-mobile8" : "answer-image-mobile6";
        const answers = (
            <Fragment>
                { this.props.answers.map( (answer, index) =>

                    <Col xs={columnLenght} md={columnLenght} lg={columnLenght}
                        key={index}
                        className={columnStyle}
                    >
                        <img 
                            src={require('../../assets/img/raven/'+ answer['valor'] +'.gif')} 
                            className={`${isMobile ? mobileStyle : 'answer-image'}`}
                            onClick={ 
                                () => { this.onAnswerClick(this.props.questionId, answer['id_prr'], answer['valor']) } 
                            }
                        />
                    </Col>                    
				)}
            </Fragment>
        ); 

        return(
            <Row className="raven-answers-row">
                <Col xs={12} md={12} lg={12} 
                    className='choice-question-box'
                >         
                    {answers}
                </Col>
            </Row>
        );

    }
}

export default RavenQuestion;