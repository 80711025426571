import { actionTypes } from "../actions/auth.actions";

const INIT_STATE = {
	loggedUser: null,
	loading: false, 
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case actionTypes.LOGIN_USER:
			return {
				...state, loading: true
			};
		case actionTypes.LOGIN_USER_SUCCESS:
			return {
				...state, loading: false, loggedUser: action.payload
			};		
		case actionTypes.LOGOUT_USER:
			return {
				...state, loggedUser: null
			};
		default:
			return state || INIT_STATE;
	}
};