import React from 'react';

export const testInstructions = 
    <ul>
        <li>Aquí se muestra la lista con tus pruebas asignadas, estas pruebas estarán disponibles en línea solamente por un corto período de tiempo, por lo que debes contestarlas tan pronto como te sea posible.</li>
        <li>Procura contestar estas pruebas en un ambiente propicio (con un mínimo de distractores) y contar con el tiempo suficiente para poder terminar cada prueba sin prisa.</li>
        <li>Antes de contestar la prueba “Real” debes realizar una práctica.  Las prácticas las puedes efectuar cuantas veces consideres conveniente.</li>
        <li>Al terminar la práctica de una prueba el sistema te liberará la opción “Contestar Prueba” para que puedas responder la prueba definitiva.</li>
        <li>Si tienes que contestar más de una prueba, puedes realizarlas en diferentes sesiones, sin embargo si inicias a contestar una prueba definitiva deberás de concluirla en la misma sesión.</li>
        <li>Las pruebas las puedes contestar en el orden que tu prefieras.</li>
    </ul>