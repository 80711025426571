import React, { Component } from "react";
import { Col, Form, FormGroup, Label, Input, Button, Row } from "reactstrap";
import './two-input-question.css';

const mixedPattern = /^(([0-9]+){1}[\.\s]?)?(([0-9]+)|([1-9]+\/[1-9]+))?$/;
const isFractionPattern = /\//;

class InputQuestion extends Component {

    constructor(props) {
        super(props);
        this.textInputOne = React.createRef();
        this.textInputTwo = React.createRef();
		this.state = {
            answerValue1: "",
            answerValue2: ""
		};
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.questionId !== this.props.questionId){
            this.setState({
                answerValue1: '',
                answerValue2: ''
            });
            this.textInputOne.current.focus();
        }
    }

    verifyPattern = (value) => {
        if (value.match(mixedPattern)) {
            return true;
        }
        else {
            alert("Debe ingresar un valor adecuado");
            return false;
        }
    }


    fractionConvert = (val) => {
        let parts = val.split(" ");
        let baseNum = 0;
        let fractionParts;
        if (parts.length > 1) {
            baseNum = parseInt(parts[0], 10);
            fractionParts = parts[1].split("/");
        }
        else {
            fractionParts = parts[0].split("/");
        }
        let fractionValue =
            Math.round(
                (parseInt(fractionParts[0], 10) / parseInt(fractionParts[1], 10) +
                    Number.EPSILON) *
                1000
            ) / 1000;
        return baseNum + fractionValue;
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        const { questionId } = this.props;
        this.setState({ [name]: value });
    };

    normalizeValue = (value) => {
        if (value.match(isFractionPattern)) {
            return this.fractionConvert(value);
        }
        return value;
    }

    selectNextQuestion = () => {
        if(this.state.answerValue1.length > 0 && this.state.answerValue2.length > 0){
            if (this.verifyPattern(this.state.answerValue1) && this.verifyPattern(this.state.answerValue2)){
                const response = this.normalizeValue(this.state.answerValue1) + "|" + this.normalizeValue(this.state.answerValue2);
                this.props.onAnswerSelect(this.props.questionId, 1, response);
            }
        } else {
            alert("Debe responder la pregunta o seleccionar No sé la respuesta");
        }
    }

    onDontKnowButtonClick = () => {
        this.props.onAnswerSelect(this.props.questionId, 1, "");
    }

    onKeyPressedAction = (e) => {
        if (e.key === 'Enter') {
            if(e.target.name === "answerValue1"){
                this.textInputTwo.current.focus();
                return true;
            } else if(e.target.name === "answerValue2"){
                this.selectNextQuestion();
                return true;
            }
        }
    }

    render(){

        return(
            <Col xs={12} md={12} lg={12}
                className='choice-question-box'
            >
                <Form onSubmit={e => { e.preventDefault(); }}>
                    <Row>
                        <Col xs={6} className="two-choice-question-col">
                            {this.props.answers}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <FormGroup tag="fieldset">
                                <Label for="answer"></Label>
                                <Input
                                    type="text"
                                    name="answerValue1"
                                    autoComplete="off"
                                    innerRef={this.textInputOne}
                                    autoFocus
                                    value={this.state.answerValue1}
                                    onChange={this.handleChange}
                                    onKeyUp={this.onKeyPressedAction}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup tag="fieldset">
                                <Label for="answer"></Label>
                                <Input
                                    type="text"
                                    name="answerValue2"
                                    autoComplete="off"
                                    innerRef={this.textInputTwo}
                                    value={this.state.answerValue2}
                                    onChange={this.handleChange}
                                    onKeyUp={this.onKeyPressedAction}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup tag="fieldset" className='multi-choice-fieldset button-area'>
                        <Button
                            color='primary'
                            onClick={this.selectNextQuestion}
                            className="two-input-question-button"
                        >
                            Siguiente pregunta
                        </Button>
                    </FormGroup>
                    <FormGroup tag="fieldset" className='multi-choice-fieldset button-area'>
                        <Button
                            outline
                            color='primary'
                            onClick={this.onDontKnowButtonClick}
                            className="two-input-question-button"
                        >
                            No sé la respuesta
                        </Button>
                    </FormGroup>
                </Form>
            </Col>
        );

    }
}

export default InputQuestion;
