export const zavic_practice = [
    {
        "id_prp": "01",
        "id_pruebas": 17,
        "tipo": 0,        
        "orden": "1",
        "texto": "¿Al estar usted limpiando una lámpara maravillosa se le aparece un genio y le ofrece unos regalos. ¿En qué orden los seleccionaría?",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "01",
                "orden": "01",
                "valor": "A",
                "texto": "Fama"
            },
            {
                "id_prr": "02",
                "id_prp": "01",
                "orden": "02",
                "valor": "B",
                "texto": "Dinero"
            },
            {
                "id_prr": "03",
                "id_prp": "01",
                "orden": "03",
                "valor": "C",
                "texto": "Amor"
            },
            {
                "id_prr": "04",
                "id_prp": "01",
                "orden": "04",
                "valor": "D",
                "texto": "Salud"
            }
        ]
    },
    {
        
        "id_prp": "02",
        "id_pruebas": 5,
        "tipo": 0,        
        "orden": "2",
        "texto": "Usted se encuentra un bolso con dinero equivalente a un mes de su sueldo, pero también contiene papelería importante en la que se indica el nombre, domicilio y teléfono del propietario del bolso, ¿Que haría con el bolso?",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "02",
                "orden": "01",
                "valor": "A",
                "texto": "Regreso el bolso sin dinero"
            },
            {
                "id_prr": "02",
                "id_prp": "02",
                "orden": "02",
                "valor": "B",
                "texto": "Me quedo con el dinero y descarto el bolso"
            },
            {
                "id_prr": "03",
                "id_prp": "02",
                "orden": "03",
                "valor": "C",
                "texto": "Regreso el bolso con una parte del dinero y me quedo con otra parte como recompenza"
            },
            {
                "id_prr": "04",
                "id_prp": "02",
                "orden": "04",
                "valor": "D",
                "texto": "Regreso el bolso tal cual como me lo encontre"
            }
        ]
    },
    {
        "id_prp": "03",
        "id_pruebas": 5,
        "tipo": 0,        
        "orden": "3",
        "texto": "Un amigo le da la opción de regalarle 2 boletos para usted y su pareja, ¿Que tipo de espectáculo seleccionaría?",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "03",
                "orden": "01",
                "valor": "A",
                "texto": "Concierto de rock pesado"
            },
            {
                "id_prr": "02",
                "id_prp": "03",
                "orden": "02",
                "valor": "B",
                "texto": "Opera clásica"
            },
            {
                "id_prr": "03",
                "id_prp": "03",
                "orden": "03",
                "valor": "C",
                "texto": "Un festival de baile folklórico"
            },
            {
                "id_prr": "04",
                "id_prp": "03",
                "orden": "04",
                "valor": "D",
                "texto": "Danza contemporánea"
            }
        ]
    }
]
export const zavic = [
    {                   
        "id_prp": "01",
        "id_pruebas": 17,
        "tipo": 1,      
        "orden": "1",
        "texto": "Si Ud. tuviera la habilidad y condiciones adecuadas, ¿A qué se dedicaría?:",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "01",
                "orden": "01",
                "valor": "A",
                "texto": "A modificar todos los jardines de la ciudad."
            },
            {
                "id_prr": "02",
                "id_prp": "01",
                "orden": "02",
                "valor": "B",
                "texto": "A obtener logros por medio de la política."
            },
            {
                "id_prr": "03",
                "id_prp": "01",
                "orden": "03",
                "valor": "C",
                "texto": "A prestar dinero a altos intereses."
            },
            {
                "id_prr": "04",
                "id_prp": "01",
                "orden": "04",
                "valor": "D",
                "texto": "A cumplir con las obligaciones que su religión le impone."
            }
        ]
    },
    {
        "id_prp": "02",
        "id_pruebas": 17,
        "tipo": 1,   
        "orden": "2",
        "texto": "Cuando ve un accidente, Usted:",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "02",
                "orden": "01",
                "valor": "A",
                "texto": "Se pone a orar con la persona accidentada."
            },
            {
                "id_prr": "02",
                "id_prp": "02",
                "orden": "02",
                "valor": "B",
                "texto": "Pide una ambulancia."
            },
            {
                "id_prr": "03",
                "id_prp": "02",
                "orden": "03",
                "valor": "C",
                "texto": "Cuida las pertenencias del accidentado."
            },
            {
                "id_prr": "04",
                "id_prp": "02",
                "orden": "04",
                "valor": "D",
                "texto": "Trata de detener al culpable."
            }
        ]
    },
    {        
        "id_prp": "03",
        "id_pruebas": 17,
        "tipo": 1,   
        "orden": "3",
        "texto": "Es Ud. un maestro de primaria y uno de sus alumnos le ofrece un costoso obsequio con el fin de obtener una mayor calificación, usted:",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "03",
                "orden": "01",
                "valor": "A",
                "texto": "Le dedicaría tiempo extra para nivelarlo."
            },
            {
                "id_prr": "02",
                "id_prp": "03",
                "orden": "02",
                "valor": "B",
                "texto": "Lo rechazaría amablemente y lo invitaría a estudiar."
            },
            {
                "id_prr": "03",
                "id_prp": "03",
                "orden": "03",
                "valor": "C",
                "texto": "Aceptaría el presente y le daría la calificación deseada por su alumno, porque Ud. sabe que es inteligente."
            },
            {
                "id_prr": "04",
                "id_prp": "03",
                "orden": "04",
                "valor": "D",
                "texto": "Llamaría a sus padres para que paguen clases particulares."
            }
        ]
    },
    {
        "id_prp": "04",
        "id_pruebas": 17,
        "tipo": 1,   
        "orden": "4",
        "texto": "Al conducir su automóvil por descuido Ud. pasa una señal de alto, el agente de tránsito lo detiene y para permitirle circular nuevamente, le solicita cierta suma de dinero, usted:",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "04",
                "orden": "01",
                "valor": "A",
                "texto": "Arranca su automóvil y deja al agente de tránsito."
            },
            {
                "id_prr": "02",
                "id_prp": "04",
                "orden": "02",
                "valor": "B",
                "texto": "Trata de llegar a un acuerdo encaminado a disminuir la cantidad de dinero."
            },
            {
                "id_prr": "03",
                "id_prp": "04",
                "orden": "03",
                "valor": "C",
                "texto": "Pide le sea levantada la infracción pertinente."
            },
            {
                "id_prr": "04",
                "id_prp": "04",
                "orden": "04",
                "valor": "D",
                "texto": "Amenaza al agente con reportarlo con sus superiores."
            }
        ]
    },
    {
        "id_prp": "05",
        "id_pruebas": 17,
        "tipo": 1,   
        "orden": "5",
        "texto": "Prefiere una amistad que:",                       
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "05",
                "orden": "01",
                "valor": "A",
                "texto": "Sea activa y le guste reparar desperfectos en su hogar."
            },
            {
                "id_prr": "02",
                "id_prp": "05",
                "orden": "02",
                "valor": "B",
                "texto": "Se interese por ser líder en el sindicato del cual forma parte."
            },
            {
                "id_prr": "03",
                "id_prp": "05",
                "orden": "03",
                "valor": "C",
                "texto": "Asista con frecuencia a eventos religiosos."
            },
            {
                "id_prr": "04",
                "id_prp": "05",
                "orden": "04",
                "valor": "D",
                "texto": "Le interese emprender negocios."
            }
        ]
    },
    {
        "id_prp": "06",
        "id_pruebas": 17,
        "tipo": 1,   
        "orden": "6",
        "texto": "Al llegar a su trabajo encuentra en el baño un reloj en el lavabo, Usted:",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "06",
                "orden": "01",
                "valor": "A",
                "texto": "Trata de encontrar a su dueño."
            },
            {
                "id_prr": "02",
                "id_prp": "06",
                "orden": "02",
                "valor": "B",
                "texto": "Lo reporta a sus superiores y lo entrega."
            },
            {
                "id_prr": "03",
                "id_prp": "06",
                "orden": "03",
                "valor": "C",
                "texto": "No hace ningún comentario y espera a que lo busquen."
            },
            {
                "id_prr": "04",
                "id_prp": "06",
                "orden": "04",
                "valor": "D",
                "texto": "Lo deja en donde lo encontró."
            }
        ]
    },
    {
        "id_prp": "07",
        "id_pruebas": 17,
        "tipo": 1,   
        "orden": "7",
        "texto": "Un buen gobierno debería:",                       
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "07",
                "orden": "01",
                "valor": "A",
                "texto": "Ayudar a las clases necesitadas."
            },
            {
                "id_prr": "02",
                "id_prp": "07",
                "orden": "02",
                "valor": "B",
                "texto": "Ampliar las zonas turísticas de cinco estrellas."
            },
            {
                "id_prr": "03",
                "id_prp": "07",
                "orden": "03",
                "valor": "C",
                "texto": "Buscar los mejores líderes de su partido."
            },
            {
                "id_prr": "04",
                "id_prp": "07",
                "orden": "04",
                "valor": "D",
                "texto": "Permitir que la religión sea oficial."
            }
        ]
    },
    {
        "id_prp": "08",
        "id_pruebas": 17,
        "tipo": 1,   
        "orden": "8",
        "texto": "Un amigo suyo desea obtener un acenso dentro de su trabajo, Ud. le aconseja:",                       
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "08",
                "orden": "01",
                "valor": "A",
                "texto": "Que sea cumplido y eficiente."
            },
            {
                "id_prr": "02",
                "id_prp": "08",
                "orden": "02",
                "valor": "B",
                "texto": "Que busque cuales son los errores del jefe para que demuestre que él no es perfecto."
            },
            {
                "id_prr": "03",
                "id_prp": "08",
                "orden": "03",
                "valor": "C",
                "texto": "Que prometa una manda a su santo de preferencia."
            },
            {
                "id_prr": "04",
                "id_prp": "08",
                "orden": "04",
                "valor": "D",
                "texto": "Que ofrezca una excelente comida a los dirigentes de la empresa."
            }
        ]
    },
    {
        "id_prp": "09",
        "id_pruebas": 17,
        "tipo": 1,   
        "orden": "9",
        "texto": "Si Luís al llegar a su casa observa que están robando al vecino las llantas de su automóvil, él:",         
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "09",
                "orden": "01",
                "valor": "A",
                "texto": "Llamaría a la policía."
            },
            {
                "id_prr": "02",
                "id_prp": "09",
                "orden": "02",
                "valor": "B",
                "texto": "Llamaría a su vecino."
            },
            {
                "id_prr": "03",
                "id_prp": "09",
                "orden": "03",
                "valor": "C",
                "texto": "Pediría a los asaltantes parte del beneficio que obtendrán del robo, por guardar silencio."
            },
            {
                "id_prr": "04",
                "id_prp": "09",
                "orden": "04",
                "valor": "D",
                "texto": "Mejor no haría nada y se metería a su casa."
            }
        ]
    },
    {
        "id_prp": "10",
        "id_pruebas": 17,
        "tipo": 1,   
        "orden": "10",
        "texto": "Un empleado de 60 años que ha sido leal a la empresa durante 28 años, se queja del exceso de trabajo, lo mejor sería:",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "10",
                "orden": "01",
                "valor": "A",
                "texto": "Pedir un aumento de sueldo."
            },
            {
                "id_prr": "02",
                "id_prp": "10",
                "orden": "02",
                "valor": "B",
                "texto": "Recurrir al sindicato para que éste le ayude."
            },
            {
                "id_prr": "03",
                "id_prp": "10",
                "orden": "03",
                "valor": "C",
                "texto": "Que recurra a su guía espiritual para que le diga como se debe ayudar a si mismo y que su trabajo no se le haga pesado."
            },
            {
                "id_prr": "04",
                "id_prp": "10",
                "orden": "04",
                "valor": "D",
                "texto": "Que solicite una persona para que le ayude."
            }
        ]
    },
    {
        "id_prp": "11",
        "id_pruebas": 17,
        "tipo": 1,   
        "orden": "11",
        "texto": "Usted visita a un amigo enfermo y lo mejor sería:",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "11",
                "orden": "01",
                "valor": "A",
                "texto": "Que lo convenza de acudir al seguro social para que su atención médica no le sea costosa."
            },
            {
                "id_prr": "02",
                "id_prp": "11",
                "orden": "02",
                "valor": "B",
                "texto": "Proponerle su ayuda cuando él tenga que acudir a citas médicas."
            },
            {
                "id_prr": "03",
                "id_prp": "11",
                "orden": "03",
                "valor": "C",
                "texto": "Que como todo le ha salido mal últimamente, que vea a una persona para que le realice una limpia."
            },
            {
                "id_prr": "04",
                "id_prp": "11",
                "orden": "04",
                "valor": "D",
                "texto": "Decirle lo importante que fueron las juntas de vecinos ahora que él estuvo hospitalizado ."
            }
        ]
    },
    {
        "id_prp": "12",
        "id_pruebas": 17,
        "tipo": 1,   
        "orden": "12",
        "texto": "Al salir de viaje, sus vecinos le piden cuidar su casa durante su ausencia, usted:",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "12",
                "orden": "01",
                "valor": "A",
                "texto": "Les dice que no, debido a que se encuentra muy ocupado en esos días."
            },
            {
                "id_prr": "02",
                "id_prp": "12",
                "orden": "02",
                "valor": "B",
                "texto": "Atiende con gusto la petición de sus vecinos."
            },
            {
                "id_prr": "03",
                "id_prp": "12",
                "orden": "03",
                "valor": "C",
                "texto": "A cambio de sus servicios les pide prestado el automóvil que no utilizarán en su viaje."
            },
            {
                "id_prr": "04",
                "id_prp": "12",
                "orden": "04",
                "valor": "D",
                "texto": "Asiste al módulo de vigilancia local, para que le brinden mayor seguridad."
            }
        ]
    },
    {
        "id_prp": "13",
        "id_pruebas": 17,
        "tipo": 1,   
        "orden": "13",
        "texto": "Encuentra a un niño llorando solo en una tienda comercial, usted:",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "13",
                "orden": "01",
                "valor": "A",
                "texto": "Lo ayudaría a buscar a su familia en la tienda."
            },
            {
                "id_prr": "02",
                "id_prp": "13",
                "orden": "02",
                "valor": "B",
                "texto": "Lo llevaría al área de servicios generales para que ahí espere a que lo ayuden."
            },
            {
                "id_prr": "03",
                "id_prp": "13",
                "orden": "03",
                "valor": "C",
                "texto": "Pasaría de largo porque Ud. tiene prisa."
            },
            {
                "id_prr": "04",
                "id_prp": "13",
                "orden": "04",
                "valor": "D",
                "texto": "Lo llevaría a su casa pero le cobra a la mamá por el tiempo que Ud. perdió por andarla buscando."
            }
        ]
    },
    {
        "id_prp": "14",
        "id_pruebas": 17,
        "tipo": 1,   
        "orden": "14",
        "texto": "Si Ud. viviera en provincia y tuviera más entradas que las que necesita, que preferiría hacer con el dinero:",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "14",
                "orden": "01",
                "valor": "A",
                "texto": "Hacerlo producir para ayudar al desarrollo industrial."
            },
            {
                "id_prr": "02",
                "id_prp": "14",
                "orden": "02",
                "valor": "B",
                "texto": "Donar dinero para la construcción de una iglesia."
            },
            {
                "id_prr": "03",
                "id_prp": "14",
                "orden": "03",
                "valor": "C",
                "texto": "Darlo a una sociedad para el beneficio de las familias humildes del poblado."
            },
            {
                "id_prr": "04",
                "id_prp": "14",
                "orden": "04",
                "valor": "D",
                "texto": "Aportar ayuda al partido político con el cual Ud. simpatiza."
            }
        ]
    },
    {
        "id_prp": "15",
        "id_pruebas": 17,
        "tipo": 1,   
        "orden": "15",
        "texto": "Su hijo ha dejado los estudios por un tiempo, Usted:",         
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "15",
                "orden": "01",
                "valor": "A",
                "texto": "Le sugiere que vea a los maestros para que les proponga que lo ayuden."
            },
            {
                "id_prr": "02",
                "id_prp": "15",
                "orden": "02",
                "valor": "B",
                "texto": "Que haga lo que quiera pues ya está grande."
            },
            {
                "id_prr": "03",
                "id_prp": "15",
                "orden": "03",
                "valor": "C",
                "texto": "Que curse nuevamente el año para que pase las materias reprobadas."
            },
            {
                "id_prr": "04",
                "id_prp": "15",
                "orden": "04",
                "valor": "D",
                "texto": "Darle apoyo económico y moral par que supere este tropiezo en sus estudios."
            }
        ]
    },
    {
        "id_prp": "16",
        "id_pruebas": 17,
        "tipo": 1,   
        "orden": "16",
        "texto": "¿A qué actividad prefiere dedicarse durante sus vacaciones?:",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "16",
                "orden": "01",
                "valor": "A",
                "texto": "Obtener experiencia en otro negocio que no sea el propio."
            },
            {
                "id_prr": "02",
                "id_prp": "16",
                "orden": "02",
                "valor": "B",
                "texto": "Participar en una campaña política de diputados, que se va a efectuar en su localidad."
            },
            {
                "id_prr": "03",
                "id_prp": "16",
                "orden": "03",
                "valor": "C",
                "texto": "Asistir a un retiro organizado por la iglesia."
            },
            {
                "id_prr": "04",
                "id_prp": "16",
                "orden": "04",
                "valor": "D",
                "texto": "Visitar un lugar que no conoce, en compañía de su familia."
            }
        ]
    },
    {
        "id_prp": "17",
        "id_pruebas": 17,
        "tipo": 1,   
        "orden": "17",
        "texto": "La familia de Pedro tiene un hijo drogadicto y es amigo de su hijo desde pequeño; al saberlo usted:",                       
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "17",
                "orden": "01",
                "valor": "A",
                "texto": "Le pide a su hijo que no lo vuelva a ver."
            },
            {
                "id_prr": "02",
                "id_prp": "17",
                "orden": "02",
                "valor": "B",
                "texto": "Le sugiere a su familia que lo lleven a centros de integración juvenil para que lo ayuden."
            },
            {
                "id_prr": "03",
                "id_prp": "17",
                "orden": "03",
                "valor": "C",
                "texto": "Si el muchacho ya es drogadicto le pide le obsequie un reloj que tanto le gusta a su hijo para no decírselo a sus padres."
            },
            {
                "id_prr": "04",
                "id_prp": "17",
                "orden": "04",
                "valor": "D",
                "texto": "Lo lleva a un centro de rehabilitación junto con su hijo para que sienta que lo apoya y no ser rechazado."
            }
        ]
    },
    {
        "id_prp": "18",
        "id_pruebas": 17,
        "tipo": 1,   
        "orden": "18",
        "texto": "Su esposa le comenta que al terminar de realizar sus actividades cotidianas le queda mucho tiempo libre, usted le sugiere:",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "18",
                "orden": "01",
                "valor": "A",
                "texto": "Que emplee su tiempo como catequista de la iglesia."
            },
            {
                "id_prr": "02",
                "id_prp": "18",
                "orden": "02",
                "valor": "B",
                "texto": "Que venda artículos femeninos."
            },
            {
                "id_prr": "03",
                "id_prp": "18",
                "orden": "03",
                "valor": "C",
                "texto": "Que promueva juntas entre los vecinos encaminadas a resolver los problemas de la comunidad."
            },
            {
                "id_prr": "04",
                "id_prp": "18",
                "orden": "04",
                "valor": "D",
                "texto": "Que asista con sus hijos a centros recreativos si fuera posible."
            }
        ]
    },
    {
        "id_prp": "19",
        "id_pruebas": 17,
        "tipo": 1,   
        "orden": "19",
        "texto": "Quiere pedir un favor a un conocido, el cual beneficiará a la empresa para la cual trabaja:",
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "19",
                "orden": "01",
                "valor": "A",
                "texto": "Le explica los motivos y necesidades por los cuales requiere ese favor."
            },
            {
                "id_prr": "02",
                "id_prp": "19",
                "orden": "02",
                "valor": "B",
                "texto": "Le pide el favor sin mayor explicación."
            },
            {
                "id_prr": "03",
                "id_prp": "19",
                "orden": "03",
                "valor": "C",
                "texto": "Le hace creer que él será el más beneficiado al ofrecerle una retribución."
            },
            {
                "id_prr": "04",
                "id_prp": "19",
                "orden": "04",
                "valor": "D",
                "texto": "Le sugiere a su jefe que sea él quien pida el favor y explique la necesidad."
            }
        ]
    },
    {
        "id_prp": "20",
        "id_pruebas": 17,
        "tipo": 1,   
        "orden": "20",
        "texto": "¿Cuál de las siguientes ocupaciones escogería?:",         
        "answers": [
            {
                "id_prr": "01",
                "id_prp": "20",
                "orden": "01",
                "valor": "A",
                "texto": "Trabajar de forma independiente."
            },
            {
                "id_prr": "02",
                "id_prp": "20",
                "orden": "02",
                "valor": "B",
                "texto": "Como encargado del departamento en el cual a usted le gusta."
            },
            {
                "id_prr": "03",
                "id_prp": "20",
                "orden": "03",
                "valor": "C",
                "texto": "Dedicarse a estudios de la iglesia."
            },
            {
                "id_prr": "04",
                "id_prp": "20",
                "orden": "04",
                "valor": "D",
                "texto": "Trabajador social."
            }
        ]
    }
]