export const actionTypes = {
    SHOW_SUCCESS_NOTIFICATION : 'SHOW_SUCCESS_NOTIFICATION',
    SHOW_ERROR_NOTIFICATION : 'SHOW_ERROR_NOTIFICATION'    
}

export const showSuccessNotification = (title, message) => ({
    type: actionTypes.SHOW_SUCCESS_NOTIFICATION,
    payload: { title, message }
});

export const showErrorNotification = (title, message) => ({
    type: actionTypes.SHOW_ERROR_NOTIFICATION,
    payload: { title, message }
});