import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Row, Col, Card, CardBody, Form, FormGroup, Input, Label, Button } from "reactstrap";
import { showErrorNotification } from '../../store/actions/notification.actions';
import {logoutUser, updateUser} from "../../store/actions/auth.actions";
import AppHeader from '../../components/AppHeader/AppHeader';
import { isMobile } from "react-device-detect";

import './user-form.css'

class UserForm extends Component {

    constructor(props) {
        super(props);
        const { nombreusuario, amaterno, apaterno, email, fechanac, sexo  } = props.loggedUser;
		this.state = {
			nombreusuario: nombreusuario,
            apaterno: apaterno,
            amaterno: amaterno,
            email: email,
            fechanac: fechanac,
            sexo: sexo,
            edited: true,
		};
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        const currentValue = this.state[name];
        let edited = false;
        if(currentValue !== value){
            edited = true;
        }
        this.setState({
            [name]: value.toUpperCase(),
            edited: edited
        });
    };

    sexChange = (event) => {
        const { value } = event.target;
        this.setState( {
            sexo: value,
            edited: true
        });
    };

    onUserUpdate = event => {
        event.preventDefault();
        const { nombreusuario, apaterno, amaterno, email, sexo, fechanac, edited } = this.state;
        const { idunico } = this.props.loggedUser;
        const datostemp = 1;
        const datosperm = edited ? 2 : 0;
        let data = { idunico, nombreusuario, apaterno, amaterno, email, sexo, fechanac, datostemp, datosperm }
        this.props.updateUser(data);
    }

    onLogout = () => {
        this.props.logoutUser();
    }

    setupBeforeUnloadListener = () => {
        window.addEventListener("beforeunload", (ev) => {
            ev.preventDefault();
            return this.onLogout();
        });
    };

    componentDidMount() {
        this.setupBeforeUnloadListener();
    }

    render() {

        const { nombreusuario, apaterno, amaterno, email, sexo, fechanac } = this.state;

		return (
            <Fragment>
                <Row>
                    <Col
                        xs={{ size:12}}
                        sm={{size:8, offset:2}}
                        md={{size:6, offset:2}}
                        lg={{size:4, offset:2}}
                    >
                        <Card className={`${isMobile ? 'user-card-mobile' : 'user-card'}`}>
                            <AppHeader
                                onExit={this.onLogout}
                            />
                            <CardBody
                                className={`${isMobile ? 'user-card-body-mobile' : 'user-card-body'}`}
                            >
                                <div className="user-form-title">Confirma tus datos</div>
                                <div className="user-form-message">Antes de continuar es necesario que verifiques tus datos personales. Por favor confirma, corrige o completa la información que se muestra en el siguiente formulario.</div>
                                <Form
                                    className='user-form-inputs'
                                    autoComplete="off"
                                    onSubmit={this.onUserUpdate}
                                >
                                    <FormGroup row>
                                        <Label for="nombreusuario" sm={3}>Nombre</Label>
                                        <Col sm={9}>
                                            <Input
                                                type="nombreusuario"
                                                name="nombreusuario"
                                                id="nombreusuario"
                                                value={nombreusuario}
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="apaterno" sm={3}>Apellido Paterno</Label>
                                        <Col sm={9}>
                                            <Input
                                                type="apaterno"
                                                name="apaterno"
                                                id="apaterno"
                                                value={apaterno}
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="amaterno" sm={3}>Apellido Materno</Label>
                                        <Col sm={9}>
                                            <Input
                                                type="amaterno"
                                                name="amaterno"
                                                id="amaterno"
                                                value={amaterno}
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="email" sm={3}>Correo Electrónico</Label>
                                        <Col sm={9}>
                                            <Input
                                                type="email"
                                                name="email"
                                                id="email"
                                                value={email}
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="sexo" sm={3}>Sexo</Label>
                                        <Col sm={9}>
                                            <Input
                                                type="select"
                                                name="sexo"
                                                id="sexo"
                                                value={sexo}
                                                onChange={this.sexChange}
                                            >
                                                <option value="Masculino">Masculino</option>
                                                <option value="Femenino">Femenino</option>
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="fechanac" sm={3}>Fecha de Nacimiento</Label>
                                        <Col sm={9}>
                                            <Input
                                                type="date"
                                                name="fechanac"
                                                value={fechanac}
                                                id="fechanac"
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col sm={12} className='button-right'>
                                            <Button
                                                color='primary'
                                            >
                                                Confirmar
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        );

    }

}

const mapStateToProps = ({ authUser }) => {
	const { loggedUser } = authUser;
	return { loggedUser };
};

export default connect(
	mapStateToProps,
	{ showErrorNotification, updateUser, logoutUser }
)(UserForm);
