import { all } from 'redux-saga/effects';
import notificationSaga from './notification.sagas';
import authSaga from './auth.saga';
import testSaga from './test.saga';
import questionSaga from './question.saga';

export default function* rootSaga(getState) {
	yield all([
		authSaga(),
		testSaga(),
		questionSaga(),
		notificationSaga()
	]);
}