import React, { useEffect, useState, useRef} from "react";
import { Col, Form, FormGroup, Label, Input, Button } from "reactstrap";
import './input-question.css';

const InputQuestion = (props) => {
    const [state, setState] = useState({answerValue: ''})
    const inputRef = useRef(null)
    useEffect(()=>{
        setState({answerValue: ''})
        inputRef.current && inputRef.current.focus()
    }, [props.questionId])

    const handleChange = (event) => {
        const { name, value } = event.target;
        const { questionId } = state;
        setState({ answerValue: value });
    };

    const selectNextQuestion = () => {
        if(state.answerValue.length > 0){
            props.onAnswerSelect(props.questionId, 1, state.answerValue);
        } else {
            alert("Debe responder la pregunta o seleccionar No sé la respuesta");
        }
    }

    const onDontKnowButtonClick = () => {
        props.onAnswerSelect(props.questionId, 1, "");
    }

    const onKeyPressedAction = (e) => {
        if (e.key === 'Enter') {
            if(state.answerValue.length > 0){
                props.onAnswerSelect(props.questionId, 1, state.answerValue);
            } else {
                alert("Debe responder la pregunta o seleccionar No sé la respuesta");
            }
        }
    }


        return(
            <Col xs={12} md={12} lg={12}
                className='choice-question-box'
            >
                <Form onSubmit={e => { e.preventDefault(); }}>
                    <FormGroup tag="fieldset" className='multi-choice-fieldset'>
                        <Label for="answer"/>
                        <Input
                            innerRef={inputRef}
                            autofocus="true"
                            type="number"
                            name="answer"
                            value={state.answerValue}
                            autoComplete="off"
                            onChange={handleChange}
                            onKeyPress={e => onKeyPressedAction(e)}
                        />
                    </FormGroup>
                    <FormGroup tag="fieldset" className='multi-choice-fieldset button-area'>
                        <Button
                            onClick={ selectNextQuestion }
                            color='primary'
                            className="input-question-button"
                        >
                            Siguiente pregunta
                        </Button>
                    </FormGroup>
                    <FormGroup tag="fieldset" className='multi-choice-fieldset button-area'>
                        <Button
                            onClick={ onDontKnowButtonClick }
                            outline
                            color='primary'
                            className="input-question-button"
                        >
                            No sé la respuesta
                        </Button>
                    </FormGroup>
                </Form>
            </Col>
        );
}

export default InputQuestion;
