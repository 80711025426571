import React, { Component } from 'react';
import { Row, Col, Card, CardBody, CardFooter, Button } from 'reactstrap';
import { isMobile } from "react-device-detect";
import AppHeader from '../../components/AppHeader/AppHeader';
import './task-instructions.css';

class TaskInstructionsForm extends Component {

    onInstructionsButtonClicked = () => {
        this.props.onInstructionsReaded();
    }

    render(){

        const { instructions, title, taskInstructionsReaded, actualTest } = this.props;
        
        return (
            <Row>
                <Col 
                    xs={{ size:12}} 
                    sm={{size:8}} 
                    md={{size:8}} 
                    lg={{size:8}} 
                >
                    <Card className={`${isMobile ? 'test-form-card-mobile' : 'test-form-card'}`}>
                        <AppHeader
                            onExit={this.onLogout} 
                        />
                        <CardBody 
                            className={`${isMobile ? 'test-list-card-body-mobile' : 'test-list-card-body'}`}
                        >
                            <div className="task-instructions-title">
                                {title}
                            </div>
                            <div 
                                dangerouslySetInnerHTML={{ __html: instructions }} 
                            />
                            {
                                !taskInstructionsReaded && actualTest != 0 &&
                                <div className="alert-section">
                                    <div className="alert-section-icon">
                                        <img
                                            src={require('../../assets/img/information.png')}
                                        />
                                    </div>
                                    <div className="alert-section-instructions">
                                        <div className="instructions-title">MUY IMPORTANTE</div>
                                        <ul>
                                            <li>
                                                Una vez que presiones el botón Comenzar, debes seguir hasta terminar por completo esta prueba, de otra forma se considerará como abandonada y no podrás volver a iniciarla.
                                            </li>
                                            <li>
                                                Durante la prueba no utilices los botones de comandos de tu navegador ni escribas nada en la barra de direcciones, cualquiera de estas acciones suspenderá la aplicación de la prueba.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            }
                        </CardBody>
                        <CardFooter>                            
                            <Button 
                                color="primary" 
                                className="float-right"
                                onClick={this.onInstructionsButtonClicked}
                            >
                                Comenzar
                            </Button>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        );
    }

}

export default TaskInstructionsForm;