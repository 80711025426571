import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import UserForm from '../components/UserForm/UserForm';
import LoginForm from '../routes/LoginForm/LoginForm';
import WelcomeForm from '../routes/WelcomeForm/WelcomeForm';
import TestListForm from '../routes/TestListForm/TestListForm';
import TestListInstructions from '../routes/TestListForm/TestListInstructions';
import TestForm from '../routes/TestForm/TestForm';
import TaskForm from '../routes/Task/Task';

class MainRoutes extends Component {    	

	render() {         

        if(this.props.loggedUser === null) {            
            return <LoginForm />
        }
                
        return (
            <Switch>                        
                <Route exact path='/' component={WelcomeForm} />
                <Route exact path='/instrucciones-pruebas' component={TestListInstructions} />
                <Route exact path='/pruebas' component={TestListForm} />
                <Route exact path='/test' component={TestForm} />
                <Route exact path='/tarea' component={TaskForm} />   
                <Route exact path='/usuario' component={UserForm} />
            </Switch>            
        );

    }
}

const mapStateToProps = ({ authUser }) => {
	const { loggedUser } = authUser;
	return { loggedUser };
};

export default connect(mapStateToProps,{})(MainRoutes);